import { RootState } from "../../index";

const getWathclists = (state: RootState) => state.watchlist.data;
const getIsLoading = (state: RootState) => state.watchlist.isLoading;
const getError = (state: RootState) => state.watchlist.error;

// eslint-disable-next-line
export default {
  getWathclists,
  getIsLoading,
  getError,
};
