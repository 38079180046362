import { Router, Route, RootRoute, redirect } from "@tanstack/react-router";

import { ProtectedRoute, Root, UnprotectedRoute } from "./router";

import Dashboard from "@/pages/dashboard/dashboard/index";
import Portfolio from "@/pages/dashboard/portfolio/index";
import PortfolioAssetAllocation from "@/pages/dashboard/portfolio/asset_allocation";
import PortfolioAccountSummary from "@/pages/dashboard/portfolio/account_summary";
import PortfolioWatchlist from "@/pages/dashboard/portfolio/watchlist";

import PurchaseContract from "@/pages/dashboard/statement/purchase_contract";
import SellContract from "@/pages/dashboard/statement/sell_contract";
import CashContract from "@/pages/dashboard/statement/cash_contract";

import Order from "@/pages/dashboard/order/index";
import OrderSubmitOrder from "@/pages/dashboard/order/submit_order";

import Deposit from "@/pages/dashboard/fund-management/deposit";
import Withdrawal from "@/pages/dashboard/fund-management/withdrawal";

import Referral from "@/pages/dashboard/referral/index";

import Research from "@/pages/dashboard/research/index";
import ResearchStockUpdate from "@/pages/dashboard/research/stock-update";
import ResearchCompareStock from "@/pages/dashboard/research/compare-stock";
import ResearchNews from "@/pages/dashboard/research/news";

import Setting from "@/pages/dashboard/setting/index";
import SettingProfileDetails from "@/pages/dashboard/setting/profile";
import SettingChangePassword from "@/pages/dashboard/setting/change-password";
import SettingConfiguration from "@/pages/dashboard/setting/configuration";
import SettingNextOfKin from "@/pages/dashboard/setting/next-of-kin";

import MarketData from "@/pages/dashboard/research/market-data";

import Register from "@/pages/auth/Register";

import Login from "@/pages/auth/Login";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import Service from "@/lib/services/request";
import { User } from "@/lib/services/class/user";
import DataCache from "@/lib/DataCache";
import Constant from "@/lib/constant";

const rootRoute = new RootRoute({
  component: Root,
});

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: Login,
});

const unProtectedRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "auth",
  component: UnprotectedRoute,
});

const loginRoute = new Route({
  getParentRoute: () => unProtectedRoute,
  path: "login",
  component: Login,
});

const registerRoute = new Route({
  getParentRoute: () => unProtectedRoute,
  path: "register",
  component: Register,
});

const forgotPasswordRoute = new Route({
  getParentRoute: () => unProtectedRoute,
  path: "forgot-password",
  component: ForgotPassword,
});

const protectedRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "dashboard",
  component: ProtectedRoute,
  beforeLoad: () => {
    const routerLink = router.state.location.href;
    
    const token = DataCache.getItem(Service.StoreToken);
    if(token){
      DataCache.setItem(Service.StoreToken, token, Constant.websiteTimeout);
    }

    const user =
      (JSON.parse(localStorage.getItem(Service.StoreUser) || "{}") as User) ||
      null;
    if (
      user &&
      user.isForcePassword != null &&
      user.isForcePassword &&
      !routerLink.includes("/dashboard/setting/change-password")
    ) {
      throw redirect({
        to: "/dashboard/setting/change-password",
        search: { isRequired: "YES" },
      });
    }
  },
});

const dashboardRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "/",
  component: Dashboard,
});

const portfolioRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "portfolio",
  component: Portfolio,
});

const assetAllocationRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "portfolio/assets",
  component: PortfolioAssetAllocation,
});

const accountSummaryRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "portfolio/account-summary",
  component: PortfolioAccountSummary,
});
const watchlistRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "portfolio/watchlist",
  component: PortfolioWatchlist,
});

// account-summary
const orderRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "orders",
  component: Order,
});

const orderCreateRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "orders/create",
  component: OrderSubmitOrder,
});

const purchaseContractRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "statement-purchase",
  component: PurchaseContract,
});

const sellContractRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "statement-sell",
  component: SellContract,
});
const cashContractRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "statement-cash",
  component: CashContract,
});

const depositRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "deposit",
  component: Deposit,
});
const withdrawalRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "withdrawal",
  component: Withdrawal,
});

const referralRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "referral",
  component: Referral,
});

//
const researchRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "research",
  component: Research,
});

const researchHomeRoute = new Route({
  getParentRoute: () => researchRoute,
  path: "/",
  component: ResearchStockUpdate,
});
const researchCompareRoute = new Route({
  getParentRoute: () => researchRoute,
  path: "/compare-stocks",
  component: ResearchCompareStock,
});
const researchNewsRoute = new Route({
  getParentRoute: () => researchRoute,
  path: "/news",
  component: ResearchNews,
});

const marketDataRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "market-data",
  component: MarketData,
});

const settingRoute = new Route({
  getParentRoute: () => protectedRoute,
  path: "setting",
  component: Setting,
});

const settingHomeRoute = new Route({
  getParentRoute: () => settingRoute,
  path: "/",
  component: SettingProfileDetails,
});
const settingChangePasswordRoute = new Route({
  getParentRoute: () => settingRoute,
  path: "/change-password",
  component: SettingChangePassword,
});
const settingConfigurationRoute = new Route({
  getParentRoute: () => settingRoute,
  path: "/configuration",
  component: SettingConfiguration,
});

const settingNextOfKinRoute = new Route({
  getParentRoute: () => settingRoute,
  path: "/next-of-kin",
  component: SettingNextOfKin,
});

//

const routeTree = rootRoute.addChildren([
  indexRoute,
  unProtectedRoute.addChildren([
    loginRoute,
    registerRoute,
    forgotPasswordRoute,
  ]),
  protectedRoute.addChildren([
    dashboardRoute,
    portfolioRoute,
    accountSummaryRoute,
    assetAllocationRoute,
    orderRoute,
    orderCreateRoute,
    watchlistRoute,
    purchaseContractRoute,
    sellContractRoute,
    cashContractRoute,

    depositRoute,
    withdrawalRoute,

    referralRoute,

    marketDataRoute,

    researchRoute.addChildren([
      researchHomeRoute,
      researchCompareRoute,
      researchNewsRoute,
    ]),
    settingRoute.addChildren([
      settingHomeRoute,
      settingChangePasswordRoute,
      settingConfigurationRoute,
      settingNextOfKinRoute,
    ]),
  ]),
]);

// Create the router using your route tree
export const router = new Router({
  routeTree,
  defaultPreload: "intent",
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
