import Spinner from "@/lib/common/icons/spinner";
import { useState } from "react";

function Research() {
  const [loading, setLoading] = useState(true);
  return (

    <div className="relative w-full h-screen">
      {loading ? (
        <div className="absolute top-1/2 left-1/2 right-1/2 bottom-1/2">
          <Spinner color="#316143" size={50} />{" "}
        </div>
      ) : (
        <></>
      )}
      <iframe
        onLoad={() => setLoading(false)}
        className="w-full h-screen"
        src="https://mds-kedari-ng.zanibal.com/mds?orderUrl=https://mds-meri-sec.zanibal.com/client/NewOrder?"
        frameBorder="0"></iframe>
    </div>
  );
}

export default Research;
