import Service from "@/lib/services/request";
import useSWR from "swr";
import PageNavBar from "../layout/PageNavBar";
import AccountBalance from "./components/AccountBalance";
import { Portfolio } from "@/lib/services/class/portfolio";
import Button from "@/lib/common/components/button";
import EquityOrderCal from "./components/EquityOrderCal";
import SecurityChart from "./components/SecurityChart";
import CardTemplate from "../dashboard/components/card";
import BidOfferTable from "./components/BidOfferTable";
import MarketInfoBox from "./components/MarketInfoBox";
import SearchSelect from "@/lib/common/components/select/SearchSelect";
import {
  SelectGroupProps,
  StatusProps,
  TradeOrderSummaryProps,
} from "@/lib/types/global";
import { useSearch } from "@tanstack/react-router";
import { newOrderSchema } from "@/lib/services/validators/auth-validator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import Input from "@/lib/common/components/input";
import { formatNum } from "@/lib/library";
import TradeOrderModal from "./components/OrderModal";
import { ErrorProps } from "@/lib/types/error";
import { toast } from "react-toastify";
import NativeSelect from "@/lib/common/components/select";
import { RootState } from "@/lib/store";
import securitySelector from "@/lib/store/features/security/securitySelector";
import { Security } from "@/lib/services/class/security";
import { useDispatch, useSelector } from "react-redux";
import securityOperations from "@/lib/store/features/security/securityOperations";
import portfolioOperations from "@/lib/store/features/portfolio/portfolioOperations";
import portfolioSelector from "@/lib/store/features/portfolio/portfolioSelector";
import { ThunkDispatch } from "@reduxjs/toolkit";

type SearchField = {
  orderType: string;
  symbol: string;
  limitPrice: string;
};

interface FormProps {
  portfolio: string;
  security: string;
  priceType: string;
  limitPrice?: number | undefined;
  orderType: string;
  quantity: number;
  orderTerm: string;
  instrumentType: string;
}

function SubmitOrder() {
  const [orderModal, setOrderModal] = useState<TradeOrderSummaryProps>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { orderType, symbol, limitPrice }: SearchField = useSearch({
    strict: false,
  });

  const [loading, setLoading] = useState(false);
  const [marginPowerBalance, setMarginPowerBalance] = useState(0);
  const [typedLimitPrice, setTypedLimitPrice] = useState(0);
  const [selectedPriceType, setSelectedPriceType] = useState("");
  const [typedQuantity, setTypedQuantity] = useState(0);
  const [selectedOrderType, setSelectedOrderType] = useState("");
  const [selectedSecurity, setSelectedSecurity] = useState("");

  const [consideration, setConsideration] = useState(0);
  const [commission, setCommission] = useState(0);
  const [fees, setFees] = useState(0);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const balance = useSWR(Service.CustomerBalanceSummary);

  const orderTermsData = useSWR(Service.TradeOrderTerms);


  const securityData = useSelector<RootState, Security[]>(
    securitySelector.getSecurities
  );

  const securityDetails = useSelector<RootState, Security>(
    securitySelector.getDetails
  );

  const portfolioData = useSelector<RootState, Portfolio[]>(
    portfolioSelector.getPortfolio
  );

  const isLoading = useSelector<RootState, boolean>(
    securitySelector.getIsLoading
  );

  useEffect(() => {
      dispatch(securityOperations.fetchSecurity());
      dispatch(portfolioOperations.fetchPortfolio());
  }, [dispatch]);

  const onChangeSymbol = (symbol: string) => {
    dispatch(securityOperations.fetchSecurityDetails({symbol, useCache: false}));
  };

  useEffect(() => {
    if (symbol) {
      setValue("security", symbol);
      onChangeSymbol(symbol);
      // handleChangeSymbol(symbol);
      setSelectedSecurity(symbol);
    }

    if (orderType) {
      setValue("orderType", orderType);
    }

    if (limitPrice) {
      setValue("priceType", "LIMIT");
      setValue("limitPrice", parseFloat(limitPrice));
    }
  }, [limitPrice, symbol, orderType]);

  useEffect(() => {
    equityCal();
  }, [
    selectedSecurity,
    typedLimitPrice,
    selectedPriceType,
    typedQuantity,
    selectedOrderType,
  ]);

  const equityCal = () => {
    let stockPrice = 0;
    let consd = 0;
    let total = 0;
    let commission = 0;
    let fees = 0;

    if (selectedPriceType == "LIMIT") {
      stockPrice = !isNaN(typedLimitPrice) ? typedLimitPrice : 0;
    } else {
      for (let i = 0; i < securityData.length; i++) {
        const item = securityData[i];
        if (item.name == selectedSecurity) {
          stockPrice = item.price;
          break;
        }
      }
    }

    if (typedQuantity > 0 && stockPrice > 0) {
      consd = typedQuantity * stockPrice;

      if (selectedOrderType == "BUY") {
        commission = 0.0135 * consd;
        fees = 0.00375 * consd + 4.2;
        total = consd + commission + fees;
      } else if (selectedOrderType == "SELL") {
        commission = 0.0135 * consd;
        fees = 0.00675 * consd + 4.2;
        total = consd - commission - fees;
      }
    }
    setConsideration(consd);
    setCommission(commission);
    setFees(fees);
    setTotal(total);
  };

  const getGroupedData = () => {
    const res: SelectGroupProps[] = [];
    for (let i = 0; i < securityData.length; i++) {
      const item = securityData[i];
      res.push({
        type: "group",
        name: item.type === "GO" ? "BOND" : "EXCHANGE",
        items: [{ name: item.label, value: item.name }],
      });
    }
    res.sort((a, b) => b.name.localeCompare(a.name));
    return res;
  };

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(newOrderSchema),
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {
      const res = await Service.post(Service.ValidateTradeOrder, da);

      if (res) {
        if (res.data.error) {
          toast(res.data.error, {
            type: "error",
          });
        } else {
          setOrderModal({
            portfolio: da.portfolio,
            security: da.security,
            priceType: da.priceType,
            limitPrice: da.limitPrice,
            orderType: da.orderType,
            quantity: da.quantity,
            orderTerm: da.orderTerm,
            instrumentType: da.instrumentType,
            amount: res.data.amount,
          });
          setIsOpen(true);
          // reset();
        }
      }
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
      {isOpen && (
        <TradeOrderModal setIsOpen={setIsOpen} tradeOrder={orderModal} />
      )}

      <PageNavBar
        title="Submit new order"
        primary="Orders"
        primaryLink="/dashboard/orders"
        secondary="New Trade Oder"
      />

      <div className="px-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-12 gap-0">
        <div className="col-span-1 md:sticky md:top-28 md:h-max">
          <AccountBalance
            availableBalance={
              balance != undefined &&
              balance.data != undefined &&
              balance.data.data
                ? balance.data.data.availableBalance
                : 0
            }
            totalBalance={
              balance != undefined &&
              balance.data != undefined &&
              balance.data.data
                ? balance.data.data.totalCashBalance
                : 0
            }
            marginPowerBalance={marginPowerBalance}
          />
          <div className="mb-6 p-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <NativeSelect
                  {...register("portfolio")}
                  errors={errors.portfolio}
                  label="Portfolio"
                  onChange={(e) => {
                    const portfolio = portfolioData.find(
                      (element: Portfolio) =>
                        element.id == Number(e.target.value)
                    );
                    if (portfolio && portfolio.marginTradingPower) {
                      setMarginPowerBalance(
                        portfolio.marginTradingPower.amount
                      );
                    }
                  }}>
                  {portfolioData != null &&  portfolioData.length > 0 && portfolioData.map((item: Portfolio) => {
                    return (
                      <option key={item.name} value={item.name ?? ""}>
                        {item.label}
                      </option>
                    );
                  })}
                </NativeSelect>

              </div>

              <div className="mb-4 flex justify-between items-center gap-3">
                <div className="w-full">
                  <NativeSelect
                    {...register("instrumentType")}
                    errors={errors.instrumentType}
                    label="Instrument Type">
                    {[
                      { name: "EQUITY", value: "EQUITY" },
                      { name: "BOND", value: "BOND" },
                    ].map((item: StatusProps) => {
                      return symbol != null && item.name === "EQUITY" ? (
                        <option key={item.value} value={item.value} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </NativeSelect>
           
                </div>
                <div className="w-full">
                  <NativeSelect
                    {...register("orderType")}
                    errors={errors.orderType}
                    label="Order Type"
                    onChange={(e) => {
                      setSelectedOrderType(e.target.value);
                    }}>
                    {[
                      { name: "BUY", value: "BUY" },
                      { name: "SELL", value: "SELL" },
                    ].map((item: StatusProps) => {
                      return item.name === orderType ? (
                        <option key={item.value} value={item.value} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </NativeSelect>

                </div>
              </div>

              <div className="mb-4">
                <SearchSelect
                  {...register("security")}
                  errors={errors.security}
                  label="Symbol"
                  defaultValue={symbol}
                  options={getGroupedData()}
                  onChanging={(r) => {
                    setValue("security", r.toString());
                    onChangeSymbol(r.toString());
                    setSelectedSecurity(r.toString());
                  }}
                />
              </div>

              <div className="mb-4">
                <Input
                  {...register("quantity")}
                  required
                  label="Quantity"
                  errors={errors.quantity}
                  onChange={(e) => {
                    setTypedQuantity(parseFloat(e.target.value));
                  }}
                />
              </div>

              <div className="mb-4 flex justify-between items-center gap-3">
                <div className="w-full">
                  <NativeSelect
                    {...register("priceType")}
                    errors={errors.priceType}
                    onChange={(e) => {
                      setSelectedPriceType(e.target.value);
                    }}
                    label="Price Type">
                    {[
                      { name: "MARKET", value: "MARKET" },
                      { name: "LIMIT", value: "LIMIT" },
                    ].map((item: StatusProps) => {
                      return limitPrice != null && item.name === "LIMIT" ? (
                        <option key={item.value} value={item.value} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </NativeSelect>
            
                </div>
                <div className="w-full">
                  <NativeSelect
                    {...register("orderTerm")}
                    errors={errors.orderTerm}
                    label="Order Type">
                    {orderTermsData != undefined &&
                      orderTermsData.data &&
                      orderTermsData.data.data &&
                      orderTermsData.data.data?.result.map(
                        (item: Portfolio) => {
                          return (
                            <option key={item.name} value={item.name ?? ""}>
                              {item.label}
                            </option>
                          );
                        }
                      )}
                  </NativeSelect>
                
                </div>
              </div>

              {selectedPriceType == "LIMIT" ? (
                <div className="mb-4">
                  <Input
                    {...register("limitPrice")}
                    required
                    label="Limit Price"
                    errors={errors.limitPrice}
                    onChange={(e) => {
                      setTypedLimitPrice(parseFloat(e.target.value));
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              <Button disabled={loading} isLoading={loading} type="submit">
                Submit
              </Button>
            </form>
          </div>
          <EquityOrderCal
            consideration={consideration}
            commission={commission}
            fees={fees}
            total={total}
          />
        </div>
        <div className="col-span-2">
          <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-0">
            <CardTemplate title="Bids" showBorder={false}>
              <BidOfferTable
                key={selectedSecurity}
                data={securityDetails ? securityDetails.bids : []}
                isLoading={isLoading}
                symbol={selectedSecurity}
              />
            </CardTemplate>
            <CardTemplate title="Offers" showBorder={false}>
              <BidOfferTable
                key={selectedSecurity}
                data={securityDetails ? securityDetails.offers : []}
                isLoading={isLoading}
                action="BUY"
                symbol={selectedSecurity}
              />
            </CardTemplate>
          </div>

          <CardTemplate title="market information" showBorder={true}>
            <div className="p-5">
              <div className="pb-12 divide-x divide-stone-950 divide-opacity-10 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-4 md:gap-3 gap-2">
                <MarketInfoBox
                  title={"Last Price"}
                  value={formatNum(
                    securityDetails ? securityDetails.lastPx : 0,
                    2
                  )}
                />
                <MarketInfoBox
                  title={"Quantity Traded"}
                  value={formatNum(
                    securityDetails ? securityDetails.lastQty : 0,
                    0
                  )}
                />
                <MarketInfoBox
                  title={"Price Change"}
                  value={formatNum(
                    securityDetails ? securityDetails.netChgPrevDay : 0,
                    2
                  )}
                />
                <MarketInfoBox
                  title={"Perc Change"}
                  value={`${formatNum(
                    securityDetails ? securityDetails.netChgPrevDayPerc : 0
                  )}%`}
                />
              </div>

              <div className="pb-12 divide-x divide-stone-950 divide-opacity-10 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-4 md:gap-3 gap-2">
                <MarketInfoBox
                  title={"Value Traded"}
                  value={formatNum(
                    securityDetails ? securityDetails.valTraded : 0,
                    2
                  )}
                />
                <MarketInfoBox
                  title={"Volume Traded"}
                  value={formatNum(
                    securityDetails ? securityDetails.volTraded : 0,
                    0
                  )}
                />
                <MarketInfoBox
                  title={"Best Bid Price"}
                  value={formatNum(
                    securityDetails ? securityDetails.bestBidPx : 0,
                    2
                  )}
                />
                <MarketInfoBox
                  title={"Best Bid Quantity"}
                  value={formatNum(
                    securityDetails ? securityDetails.bestBidQty : 0,
                    0
                  )}
                />
              </div>

              <div className="pb-12 divide-x divide-stone-950 divide-opacity-10 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-4 md:gap-3 gap-2">
                <MarketInfoBox
                  title={"Best Offer Price"}
                  value={formatNum(
                    securityDetails ? securityDetails.bestOfferPx : 0,
                    2
                  )}
                />
                <MarketInfoBox
                  title={"Best Offer Quanity"}
                  value={formatNum(
                    securityDetails ? securityDetails.bestOfferQty : 0,
                    0
                  )}
                />
                <MarketInfoBox
                  title={"Bid Depth"}
                  value={formatNum(
                    securityDetails ? securityDetails.bidDepth : 0,
                    0
                  )}
                />
                <MarketInfoBox
                  title={"Offer Depth"}
                  value={formatNum(
                    securityDetails ? securityDetails.offerDepth : 0,
                    0
                  )}
                />
              </div>
            </div>
          </CardTemplate>

          <CardTemplate
            title={securityDetails ? securityDetails.secDesc : ""}
            showBorder={false}>
            <div className="p-5">
              <SecurityChart
                symbol={
                  getValues("security") != "" ? getValues("security") : symbol
                }
              />
            </div>
          </CardTemplate>
        </div>
      </div>
    </>
  );
}

export default SubmitOrder;
