
interface Prop {
  title: string;
  value: string | number;
  className?: string;
}

function MarketInfoBox({className, title, value }: Prop) {
  return (
    <div className={`p-2.5 flex-col justify-center items-center gap-2 inline-flex ${className}`}>
        <div className="text-center  text-stone-950 dark:text-[#f3f3f3] text-sm font-normal leading-tight">
          {value}
        </div>
        <div className="text-center text-neutral-500 text-xs font-semibold uppercase">
          {title}
        </div>
      {/* </div> */}
    </div>
  );
}

export default MarketInfoBox;
