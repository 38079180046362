import { Portfolio } from '@/lib/services/class/portfolio';
import Service from '@/lib/services/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchPortfolio = createAsyncThunk<Portfolio[]>('portfolio/list', async () => {
  try {
    const { data } = await Service.get(Service.CustomerPortfolios);
    return data;
  } catch (error) {
    throw  error;
  }
});

export default { fetchPortfolio };