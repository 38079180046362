import cancelButton from "@/assets/images/icons/cancel.svg";
import { useContext, useEffect, useState } from "react";
import SecurityInfo from "@/pages/dashboard/order/components/SecurityInfo";
import { getSymbolImage } from "@/lib/library";
import { Watchlist } from "@/lib/services/class/watchlist";
import Search from "@/lib/common/icons/search";
import Plus from "@/lib/common/icons/plus";
import { ModalContext } from "@/lib/contexts/modal-context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import watchlistSelector from "@/lib/store/features/watchlist/watchlistSelector";
import watchlistOperations from "@/lib/store/features/watchlist/watchlistOperations";
import securitySelector from "@/lib/store/features/security/securitySelector";
import { Security } from "@/lib/services/class/security";
import securityOperations from "@/lib/store/features/security/securityOperations";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SecContent {
  name: string;
  active: boolean;
}
export default function WatchlistModal({ setIsOpen }: Props) {
  const [securities, setSecurities] = useState<SecContent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<SecContent[]>([]);
  const { setWatchlistModal, setSymbol } = useContext(ModalContext);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const watchlistList = useSelector<RootState, Watchlist[]>(
    watchlistSelector.getWathclists
  );
  const securityData = useSelector<RootState, Security[]>(
    securitySelector.getSecurities
  );

  useEffect(() => {
    dispatch(watchlistOperations.fetchWatchlists());
    dispatch(securityOperations.fetchSecurity());
  }, [dispatch]);
  useEffect(() => {
    getGroupedData();
  }, [securityData]);

  const getGroupedData = () => {
    if (!loading) {
      const ddvaaa = securityData != undefined && securityData;
      const res: SecContent[] = [];
      if (ddvaaa) {
        for (let i = 0; i < ddvaaa.length; i++) {
          const item = ddvaaa[i];
          if (item.type !== "GO") {
            res.push({ name: item.name, active: false });
          }
        }
      }
      setFilters(res);
      setSecurities(res);
      setLoading(true);
    }
  };

  return (
    <>
      <div
        className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
        style={{ zIndex: 60 }}>
        <div className="flex h-full items-center justify-start ">
          <div
            className="
             bg-stone-50  flex-col justify-center items-start gap-6 inline-flex
            w-full 
            md:w-[450px] h-screen  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
            <div className="w-full h-full bg-stone-50 rounded-[10px] p-4">
              <div className="justify-between items-center gap-2.5 flex">
                <div className="text-stone-950 dark:text-[#f3f3f3] text-xl font-semibold ">
                  Watchlist
                </div>

                <div
                  className="cursor-pointer w-10 h-10  bg-black bg-opacity-5 rounded"
                  onClick={() => setIsOpen(false)}>
                  <img src={cancelButton} />
                </div>
              </div>
              <div className="my-2  w-fill h-[0px] border border-stone-950 border-opacity-5"></div>

              {watchlistList && watchlistList.length > 0 ? (
                <>
                  <div className="text-neutral-500 text-xs font-medium ">
                    Added Symbols
                  </div>
                  <div className="h-[calc(33.333333%-1.5rem)] overflow-y-auto">
                    <ul className="w-full pt-3">
                      {watchlistList &&
                        watchlistList.map((row: Watchlist, index: number) => {
                          return (
                            <li className="py-1" key={index}>
                              <div className=" justify-start items-center gap-2 inline-flex">
                                <div className="justify-start items-center gap-2 flex ">
                                  <SecurityInfo symbol={row.symbol}>
                                    <div className="justify-start items-center gap-2 flex">
                                      <LazyLoadImage
                                        className="w-[25px] h-[25px] rounded-full overflow-hidden"
                                        src={getSymbolImage(row.symbol)}
                                      />

                                      <div className="text-stone-950 dark:text-[#f3f3f3] text-xs font-semibold ">
                                        {row.companyName}
                                      </div>
                                    </div>
                                  </SecurityInfo>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </>
              ) : (
                <div className="h-1/3 flex justify-center items-center">
                  <div className="text-neutral-400 text-xs font-semibold capitalize">
                    No data available in table
                  </div>
                </div>
              )}

              <div className="h-[calc(66.666666%-3rem)] py-4">
                <div className="text-neutral-500 text-xs font-medium pb-2">
                  Add more Symbols
                </div>

                <div className="bg-white w-full h-12 p-2 rounded-lg border border-stone-950 border-opacity-5 justify-between items-center gap-2 inline-flex">
                  <input
                    placeholder="Search"
                    onChange={(e) => {
                      const ddd = securities.filter((row) => {
                        return row.name
                          .toUpperCase()
                          .includes(e.target.value.toUpperCase());
                      });

                      setFilters(ddd);
                    }}
                    className="
        w-full appearance-none focus:outline-none focus:shadow-outline text-neutral-500 text-sm font-normal leading-normal"
                  />

                  <Search />
                </div>

                <div className="h-[calc(100%-3rem)] overflow-y-auto">
                  <ul>
                    {filters &&
                      filters.map((row: SecContent, index: number) => {
                        return (
                          <li
                            key={index}
                            className="py-2 flex justify-between items-center">
                            <div className="justify-start items-center gap-2 flex">
                              <LazyLoadImage
                                className="w-[25px] h-[25px] rounded-full"
                                src={getSymbolImage(row.name)}
                              />
                              <div className="text-stone-950 dark:text-[#f3f3f3] text-xs font-semibold ">
                                {row.name}
                              </div>
                            </div>

                            <div
                              onClick={() => {
                                setWatchlistModal(true);
                                setSymbol(row.name);
                              }}
                              className="cursor-pointer w-[78px] h-[31px] px-4 py-2 bg-stone-50 rounded-md border border-gray-400 justify-center items-center gap-2 inline-flex">
                              <Plus className="text-green-900" />
                              <div className="text-green-900 text-xs font-semibold  capitalize">
                                Add
                              </div>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
