import { RootState } from "../../index";

const getAccount = (state: RootState) => state.account.data;
const getIsLoading = (state: RootState) => state.account.isLoading;
const getError = (state: RootState) => state.account.error;

export default {
  getAccount,
  getIsLoading,
  getError,
};
