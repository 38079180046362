import PageNavBar from "../layout/PageNavBar";
import DateRange from "@/lib/common/components/date/DateRange";
import { useCallback, useState } from "react";
import { formatDate, formatNum, getSymbolImage } from "@/lib/library";
import React from "react";
import NoContent from "@/lib/common/components/table/NoContent";
import CustomPagination from "@/lib/common/components/table/Pagination";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  Spinner,
  TableRow,
  TableCell,
  SortDescriptor,
} from "@nextui-org/react";
import Constant from "@/lib/constant";
import TableCss from "@/lib/utils/table-css";
import useContractNoteSWR from "@/lib/hook/useContractNoteSWR";
import { contractColumns } from "@/lib/utils/tableColumns";
import Button from "@/lib/common/components/button";
import SearchForm from "../layout/component/SearchForm";
import SecurityInfo from "../order/components/SecurityInfo";
import { Contract } from "@/lib/services/class/contract";
import Service from "@/lib/services/request";
import fileDownload from "js-file-download";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function PurchaseContract() {
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(1);
  const hasSearchFilter = Boolean(filterValue);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "transactionDate",
    direction: "descending",
  });
  const { data, handleChangeDate, dateRange, isLoading } =
    useContractNoteSWR("BUY");

  const renderCell = useCallback((order: Contract, columnKey: React.Key) => {
    const cellValue = order[columnKey as keyof Contract];

    switch (columnKey) {
      case "contractPrice":
        return formatNum(cellValue);
      case "transactionDate":
        return formatDate(cellValue);
      case "contractAmount":
        return formatNum(cellValue);
      case "quantity":
        return formatNum(cellValue);

      case "symbol":
        return (
          <SecurityInfo symbol={order.symbol}>
            <div className="justify-start items-center gap-2 flex">
              {order.symbol && (
                <LazyLoadImage
                  className="w-[25px] h-[25px] rounded-full"
                  src={getSymbolImage(order.symbol)}
                />
              )}

              <div>{cellValue}</div>
            </div>
          </SecurityInfo>
        );

      case "actions":
        return (
          <div className="justify-start items-start gap-2 inline-flex">
            <Button
              onClick={() => {
                Service.get(`${Service.ContractNote + "BUY?id=" + order.id}`, {
                  responseType: "blob",
                })
                .then((response) => {
                  fileDownload(response.data, "file.pdf");
                });
              }}
              variant="primary-outline">
              Download
            </Button>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  const onSearchChange = React.useCallback((value: string | undefined) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const filteredItems = React.useMemo(() => {
    const result =
      data != undefined && data.data.result ? data.data.result : [];
    let filteredUsers = [...result];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter((user) =>
        user.name.toLowerCase().includes(filterValue)
      );
    }

    return filteredUsers;
  }, [data, filterValue, hasSearchFilter]);

  const pages = Math.ceil(filteredItems.length / Constant.RowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * Constant.RowsPerPage;
    const end = start + Constant.RowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof Contract];
      const second = b[sortDescriptor.column as keyof Contract];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const loadingState =
    isLoading 
      ? "loading"
      : "idle";

  const classNames = React.useMemo(() => TableCss, []);

  return (
    <>
      <PageNavBar
        title="Purchase Contract"
        primary="Statement"
        showTradeNowButton={true}
      />

      <div className=" mx-4 mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
        <div className="p-8">
          <div className="mb-8">
            <SearchForm
              onChange={(value) => {
                onSearchChange(value);
              }}
            />
          </div>

          <div className="flex justify-between items-start">
            
              <DateRange
                label="Date Range"
                value={dateRange}
                onChange={(values) => {
                  handleChangeDate(values);
                }}
              />
           
          </div>
        </div>

        <div className="py-5">
          <Table
            isCompact
            classNames={classNames}
            sortDescriptor={sortDescriptor}
            onSortChange={setSortDescriptor}
            bottomContent={
              pages > 0 ? (
                <div className="flex w-full justify-center">
                  <CustomPagination
                    isCompact
                    showControls
                    showShadow
                    page={page}
                    total={pages}
                    onChange={(page: React.SetStateAction<number>) =>
                      setPage(page)
                    }
                  />
                </div>
              ) : null
            }>
            <TableHeader columns={contractColumns}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                  allowsSorting={column.sortable}>
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody<Contract>
              isLoading={isLoading}
              emptyContent={<NoContent />}
              items={sortedItems}
              loadingContent={<Spinner />}
              loadingState={loadingState}>
              {(item: Contract) => {
                return (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                );
              }}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default PurchaseContract;
