import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { Watchlist } from '@/lib/services/class/watchlist';
import watchlistOperations from './watchlistOperations';

interface WatchlistState {
    data: Watchlist[];
  isLoading: boolean;
  isEditing: boolean;
  error: string | null;
}

const initialState: WatchlistState = {
    data: [],
  isLoading: false,
  isEditing: false,
  error: null,
};

const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<WatchlistState>) => {
    builder
      .addCase(watchlistOperations.fetchWatchlists.pending, (state: WatchlistState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        watchlistOperations.fetchWatchlists.fulfilled,
        (state: WatchlistState, action: any) => {
          state.data = action.payload;
          state.isLoading = false;
        },
      )
      .addCase(
        watchlistOperations.fetchWatchlists.rejected,
        (state: WatchlistState, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        },
      )
      .addCase(watchlistOperations.createWathclist.pending, (state: WatchlistState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        watchlistOperations.createWathclist.fulfilled,
        (state: WatchlistState, action: any) => {
          console.log("Ddd");
          state.data.push(action.payload);
          state.isLoading = false;
        },
      )
      .addCase(
        watchlistOperations.createWathclist.rejected,
        (state: WatchlistState, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        },
      )
      .addCase(watchlistOperations.deleteWatchlist.pending, (state: WatchlistState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        watchlistOperations.deleteWatchlist.fulfilled,
        (state: WatchlistState, action: any) => {
          state.data = state.data.filter(
            watch => watch.id !== action.payload.id,
          );
          state.isLoading = false;
        },
      )
      .addCase(
        watchlistOperations.deleteWatchlist.rejected,
        (state: WatchlistState, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        },
      )
      .addCase(watchlistOperations.updateWatchlist.pending, (state: WatchlistState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        watchlistOperations.updateWatchlist.fulfilled,
        (state: WatchlistState, action: any) => {
          state.data = state.data.map(watch => {
            if (watch.id === action.payload.id) {
                watch.trigger_price = action.payload.trigger_price;
                watch.symbol = action.payload.symbol;
            }
            return watch;
          });
          state.isLoading = false;
        },
      )
      .addCase(
        watchlistOperations.updateWatchlist.rejected,
        (state: WatchlistState, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        },
      );
  },
});

export const  watchlistReducer = watchlistSlice.reducer;