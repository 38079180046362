const TableCss = {
      wrapper: [],
      thead: [],
      th: [
        "text-start",
        "p-3",
        "bg-green-900",
        "bg-opacity-5",
        "text-neutral-500",
        "text-xs",
        "font-semibold",
        "uppercase",
        'cursor-pointer'
      ],
      td: [
        "border-b",
        "border-black",
        "border-opacity-5",
        "py-3",
    
        "group-data-[first=true]:first:before:rounded-none",
        "group-data-[first=true]:last:before:rounded-none",
        // middle
        "group-data-[middle=true]:before:rounded-none",
        // last
        "group-data-[last=true]:first:before:rounded-none",
        "group-data-[last=true]:last:before:rounded-none",
      ],
    }

export default TableCss;
