import { useCountdown } from "./hooks/useCountdown";
import cancelButton from "@/assets/images/icons/cancel.svg";

interface Props {
  targetDate: number;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CountdownTimer = ({ targetDate, setIsOpen }: Props) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  const checkNaN = (value: number) => {
    return isNaN(value) ? "00" : value;
  };

  if (
    days + hours + minutes + seconds <= 0 ||
    (isNaN(days) && isNaN(hours) && isNaN(minutes) && isNaN(seconds))
  ) {
    return <div></div>;
  } else {
    return (
      <div className=" inset-x-0  mx-auto w-full md:w-[722px] h-[151px] bg-stone-50 rounded-lg shadow flex justify-center items-center fixed bottom-3  ">
        <div className="flex-col justify-center items-center gap-2 inline-flex">
          <div className="text-neutral-700 text-sm font-medium  leading-normal">
            Market opens in:
          </div>
          <div
            className="cursor-pointer w-10 h-10 right-5 top-5 absolute bg-black bg-opacity-5 rounded"
            onClick={() => setIsOpen(false)}>
            <img src={cancelButton} />
          </div>
          <div className="justify-start items-start gap-4 inline-flex">
            <div className="flex-col justify-center items-center gap-1 inline-flex">
              <div className="p-2 bg-green-900 bg-opacity-10 rounded justify-center items-center gap-2.5 inline-flex">
                <div className="text-green-900 text-4xl font-bold">
                  {checkNaN(days)}
                </div>
              </div>
              <div className="text-neutral-700 text-xs font-normal ">DAYS</div>
            </div>
            <div className="flex-col justify-center items-center gap-1 inline-flex">
              <div className="p-2 bg-green-900 bg-opacity-10 rounded justify-center items-center gap-2.5 inline-flex">
                <div className="text-green-900 text-4xl font-bold ">
                  {checkNaN(hours)}
                </div>
              </div>
              <div className="text-neutral-700 text-xs font-normal ">HOURS</div>
            </div>
            <div className="flex-col justify-center items-center gap-1 inline-flex">
              <div className="p-2 bg-green-900 bg-opacity-10 rounded justify-center items-center gap-2.5 inline-flex">
                <div className="text-green-900 text-4xl font-bold ">
                  {checkNaN(minutes)}
                </div>
              </div>
              <div className="text-neutral-700 text-xs font-normal ">
                MINUTES
              </div>
            </div>
            <div className="flex-col justify-center items-center gap-1 inline-flex">
              <div className="p-2 bg-green-900 bg-opacity-10 rounded justify-center items-center gap-2.5 inline-flex">
                <div className="text-green-900 text-4xl font-bold ">
                  {checkNaN(seconds)}
                </div>
              </div>
              <div className="text-neutral-700 text-xs font-normal ">
                SECONDS
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CountdownTimer;
