import PageNavBar from "../layout/PageNavBar";
import ReferralSVG from "@/assets/images/referral.svg";
import ReferralSendSVG from "@/assets/images/icons/send-referral.svg";
import CopySVG from "@/assets/images/icons/copy.svg";

import FacebookSVG from "@/assets/images/socials/facebook.svg";
import WhatsappSVG from "@/assets/images/socials/whatsapp.svg";
import TwitterSVG from "@/assets/images/socials/twitter.svg";

import Button from "@/lib/common/components/button";
import { useContext, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import clsx from "clsx";
import { toast } from "react-toastify";
import MessageModal from "../layout/component/modal/MessageModal";
import { Link } from "@tanstack/react-router";
import { AppContext } from "@/lib/contexts/App.context";
import Service from "@/lib/services/request";
import { ErrorProps } from "@/lib/types/error";

const KeyCodes = {
  comma: 188,
  enter: 13,
  spacebar: 32,
};

interface Props {
  d: string;
  text: string;
}

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function Referral() {
  const [tags, setTags] = useState<Props[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const handleDelete = (i: number) => {
    setTags(tags.filter((_tag, index) => index !== i));
  };

  const handleAddition = (tag: Props) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag: Props, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = (index: string) => {
    console.log("The tag at index " + index + " was clicked");
  };

  const onSubmit = async () => {
    if (tags.length === 0) {
      toast(
        "Content looks empty. Press the Enter key or comma on your keyboard after entering each email address.",
        {
          type: "error",
        }
      );
      return;
    }
    setLoading(true);
    try {
      await Service.post(Service.Referral, tags);
      setIsOpen(true);
      setTags([]);
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  const referralMessage = (referralUrl: string, type: string) => {
    let socialUrl;
    const title = "Invest your money";
    const hastag = "investment";
    const message = "Start enjoying investment opportunity with us";
    if (type == "f") {
      socialUrl = `https://web.facebook.com/sharer/sharer.php?url=${referralUrl}&title=${title}&description=${message}&hashtag=${hastag}`;
    } else if (type == "t") {
      socialUrl = `http://twitter.com/share?text=${message}&url=${referralUrl}&hashtags=${hastag}`;
    } else if (type == "w") {
      socialUrl = `whatsapp://send?text=${referralUrl}`;
    }

    return socialUrl;
  };

  const getReferralLink = () => {
    return `https://app.meritrade.com/auth/register?referalCode=${user?.referalCode}`;
  };

  return (
    <>
      {isOpen && (
        <MessageModal
          onClick={() => {
            setIsOpen(false);
          }}
          title=" Great!"
          description="Your invitation has been sent successfully"
          actions={
            <Link to="/dashboard/statement-purchase">
              <Button variant="primary-outline">Back to Dashboard</Button>
            </Link>
          }
        />
      )}
      <PageNavBar
        title="Referral"
        primary="Referral"
        showTradeNowButton={true}
      />

      <div className="flex justify-center md:items-end items-center text-center md:text-start" >
        <div className="p-4 md:p-12 w-full md:w-[863px] flex flex-col justify-center items-center mx-4 mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
          <img src={ReferralSVG} />

          <div className="w-full py-8 flex-col md:flex-row flex justify-between items-center">
            <div>
              <h3 className="text-stone-950 dark:text-[#f3f3f3] text-2xl font-bold pb-3">
                Referrals
              </h3>

              <div className="py-3 w-full md:w-[337px] text-stone-950 dark:text-[#f3f3f3] text-sm font-normal leading-tight">
                Invite your friends and family to Trade on Meritrade with your
                referral code.
              </div>
            </div>

            <div
              onClick={() => {
                navigator.clipboard.writeText(getReferralLink());
                toast("Copied", {
                  type: "info",
                });
              }}
              className="cursor-pointer w-full md:w-[325px] px-4 py-6 bg-green-900 bg-opacity-10 rounded-md justify-between items-center inline-flex">
              <div className="text-green-900 text-2xl font-bold font-['Inter']">
                {user?.referalCode}
              </div>

              <div className="cursor-pointer justify-center gap-1 items-center inline-flex text-green-900 text-xs font-medium capitalize">
                <img src={CopySVG} />
                Copy Referral code
              </div>
            </div>
          </div>

           <div className="text-start w-full">
            <div className="pb-1 text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold ">
              Enter Email addresses
            </div>

            <div className="text-neutral-500 text-sm font-medium ">
              Enter your friends email address and send them an invitation to
              join Meritrade
            </div>

            <div className="pt-4 pb-4 md:pb-1 w-full flex-col md:flex-row  flex justify-center items-end gap-2 md:gap-0 ">
              <div
                className={clsx(
                  `w-full min-h-12 rounded-l-lg border border-stone-950 border-opacity-10`,
                  {
                    "p-2": tags.length == 0,
                  }
                )}>
                <ReactTags
                  editable
                  tags={tags}
                  inline
                  placeholder="Enter email address"
                  inputFieldPosition="inline"
                  allowDeleteFromEmptyInput={false}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  autocomplete
                />
              </div>
              <Button
                disabled={loading}
                isLoading={loading}
                variant="referral"
                className="hidden md:flex"
                onClick={() => onSubmit()}>
                <img src={ReferralSendSVG} />
                Send Invite
              </Button>

              <Button
                disabled={loading}
                isLoading={loading}
                className="block md:hidden"
                onClick={() => onSubmit()}>
                <img src={ReferralSendSVG} />
                Send Invite
              </Button>
            </div>

            <div className="text-neutral-400 text-xs font-medium pb-8 ">
              Press the Enter key or comma on your keyboard after entering each
              email address.
            </div>

            <div className="grid lg:grid-cols-12 grid-cols-1 gap-5">
              <div className="col-span-9">
                <div className="pb-1 text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold ">
                  Share Referral Link
                </div>

                <div className="pb-4 text-neutral-500 text-sm font-medium ">
                  Enter your friends email address and send them an invitation
                  to join Meritrade
                </div>
                <div className="w-full p-3 justify-between items-center inline-flex bg-green-900 bg-opacity-10 rounded-md border border-stone-950 border-opacity-10">
                  <div className="text-neutral-500 text-sm font-medium w-full">
                    {getReferralLink()}
                  </div>
                  <div className="justify-start items-center gap-1 flex">
                    <div className="w-6 h-6 relative">
                      <div className="w-6 h-6 left-0 top-0 absolute"></div>
                    </div>
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(getReferralLink());
                        toast("Copied", {
                          type: "info",
                        });
                      }}
                      className="whitespace-nowrap cursor-pointer justify-center gap-1 items-center inline-flex text-green-900 text-sm font-semibold ">
                      <img src={CopySVG} />
                      Copy Link
                    </div>
                  </div>
                </div>
              </div>
              <div className=" w-full col-span-3 flex-col flex justify-end items-start gap-2">
                <div className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold ">
                  Share on Social media
                </div>
                <div className="flex justify-center items-center gap-2">
                  <a
                    target="_blank"
                    href={referralMessage(getReferralLink(), "f")}>
                    <img src={FacebookSVG} />
                  </a>
                  <a
                    target="_blank"
                    href={referralMessage(getReferralLink(), "w")}>
                    <img src={WhatsappSVG} />
                  </a>
                  <a
                    target="_blank"
                    href={referralMessage(getReferralLink(), "t")}>
                    <img src={TwitterSVG} />
                  </a>
                </div>
              </div>
            </div>
          </div> *
        </div>
      </div>
    </>
  );
}
