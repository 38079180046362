import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";

import Notification from "@/assets/images/icons/notification.svg";
import Menu from "@/assets/images/header_menu.svg";
import MobileMenu from "@/assets/images/icons/mobile-menu.svg";

import StockTicker from "./StockTicker";
import { useContext, useEffect, useState } from "react";
import { capitalizeSentence, onLogout } from "@/lib/library";
import { AppContext } from "@/lib/contexts/App.context";
import Autocomplete from "@/lib/common/components/select/Autocomplete";
import { SelectGroupProps } from "@/lib/types/global";
import { useNavigate } from "@tanstack/react-router";
import SmallLogo from "@/assets/images/logo_small.svg";
import { ModalContext } from "@/lib/contexts/modal-context";
import NotificationModal from "./component/modal/NotificationModal";
import Constant from "@/lib/constant";
import {  RootState } from "@/lib/store";
import securitySelector from "@/lib/store/features/security/securitySelector";
import { Security } from "@/lib/services/class/security";
import { useDispatch, useSelector } from "react-redux";
import securityOperations from "@/lib/store/features/security/securityOperations";
import { ThunkDispatch } from "@reduxjs/toolkit";
import nightwind from 'nightwind/helper';


interface Props{
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
}

function HeaderLayout({setShowMobileMenu} : Props) {
  const { user } = useContext(AppContext);
  const { setNotification } = useContext(ModalContext);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [theme, setTheme] = useState(false);
  
  useEffect(() => {
    const storedPref = window.localStorage.getItem('nightwind-mode');
    setTheme(storedPref === 'dark');
    nightwind.enable(storedPref === 'dark');
  }, []);

  const handleChange = () => {
    const isCheck = !theme;
    setTheme(isCheck);
    nightwind.enable(isCheck);
  }
  const navigate = useNavigate();

  const securityData = useSelector<RootState, Security[]>(
    securitySelector.getSecurities
  );

  useEffect(() => {
   dispatch(securityOperations.fetchSecurity());
  }, [dispatch]);

  const getGroupedData = () => {

    const res: SelectGroupProps[] = [];
    for (let i = 0; i < securityData.length; i++) {
      const item = securityData[i];
      res.push({
        type: "group",
        name: item.type === "GO" ? "BOND" : "EXCHANGE",
        items: [{ name: item.label, value: item.name }],
      });
    }
    res.sort((a, b) => b.name.localeCompare(a.name));
    return res;
  };

  const logout = () => {
    onLogout();
  };
  return (
    <div className="md:fixed w-full z-30 flex bg-transparent md:bg-white p-2 items-center justify-between md:justify-center md:px-10 px-5 md:py-3 border-b border-stone-950 border-opacity-10 gap-16">
      <div className="hidden md:block ml-0 md:ml-20 ">
        <div className="w-[309px] ">
          <Autocomplete
            onChange={(value) => {
              navigate({
                to: "/dashboard/orders/create",
                search: {
                  symbol: value,
                },
              });
            }}
            options={getGroupedData()}
          />
        </div>
      </div>

      <div className="hidden md:flex  grow h-full  items-center justify-start overflow-hidden">
        <StockTicker />
      </div>

      <img className="block md:hidden cursor-pointer" onClick={() => setShowMobileMenu(true)} src={MobileMenu} />

      <div className="flex gap-3 items-center">

      <div onClick={handleChange}><svg width="140" height="140" viewBox="0 0 140 140" fill="none" className="h-7 w-auto cursor-pointer group mx-auto z-10"><circle cx="70" cy="70" r="70" fill="currentColor" className="text-[#286348] dark:text-yellow-300 "></circle><path fillRule="evenodd" clipRule="evenodd" d="M82.5612 138.876C55.6458 126.762 37 100.476 37 70C37 39.5244 55.6458 13.2383 82.5613 1.1239C115.227 7.04239 140 35.6281 140 70C140 104.372 115.227 132.958 82.5612 138.876Z" fill="currentColor" className="text-[#97B700] group-hover:text-[#286348] dark:text-blue-500 dark:group-hover:text-yellow-500 "></path></svg></div>

        
        <div
          className="w-10 relative block cursor-pointer"
          onClick={() => setNotification((value: boolean) => (value = !value))}>
          {/* <div className="animate-bounce absolute -left-1 -top-2  bg-red-500 rounded-full">
            <span className="text-sm text-white p-1">1</span>
          </div> */}
          <img src={Notification} />

          <NotificationModal />
        </div>

        <div className="h-[30px] border border-gray-400"></div>

        <Dropdown
          showArrow
          classNames={{
            base: "py-1 px-1 border border-default-200 bg-white border-radius",
            arrow: "bg-default-200",
          }}
          placement="bottom-start">
          <DropdownTrigger>
            <div className="cursor-pointer flex-none h-full text-center flex items-center justify-center">
              <div className="flex space-x-3 items-center px-3">
                <div className="flex-none flex justify-center">
                  <div className="p-1 bg-white rounded-full shadow border border-neutral-700">
                    <div className="w-11 h-11 flex ">
                      <img
                        alt="profile"
                        className="shadow bg-stone-300 rounded-full object-cover"
                        src={
                          user?.picture
                            ? `data:${user?.pictureMimeType};base64,${user?.picture}`
                            : SmallLogo
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="hidden md:block text-stone-950 dark:text-[#f3f3f3] text-sm font-medium leading-normal  w-[150px]">
                  <span className="truncate block">
                    {capitalizeSentence(user?.label)}
                  </span>
                </div>

                <img src={Menu} />
              </div>
            </div>
          </DropdownTrigger>
          <DropdownMenu aria-label="User Actions" variant="flat">
            <DropdownItem
              onClick={() => {
                navigate({
                  to: "/dashboard/setting",
                });
              } } key={"setting"}>
              My Profile
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate({
                  to: "/dashboard/orders/create",
                });
              }} key={"orders-create"}>
              Trade Now
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate({
                  to: "/dashboard/portfolio",
                });
              }} key={"portfolio"}>
              Portfolio
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate({
                  to: "/dashboard/portfolio/watchlist",
                });
              }} key={"watchlist"}>
              Watchlist
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                navigate({
                  to: "/dashboard/deposit",
                });
              }} key={"deposit"}>
              Deposit Funds
            </DropdownItem>

            <DropdownItem
              onClick={() => {
                window.open(Constant.StockRecommendation, '_blank')
              }} key={"StockRecommendation"}>
              Stock Recommendation
            </DropdownItem>

            <DropdownItem onClick={logout} color="danger"  key={"logout"}>
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
}

export default HeaderLayout;
