import CustomButton from "@/lib/common/components/button";
import Constant from "@/lib/constant";
import { Link } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";

interface Props {
  title: string;
  primary?: string;
  primaryLink?: string;
  secondary?: string;
  showTradeNowButton?: boolean;
}
function PageNavBar(props: Props) {
  return (
    <>
      <Helmet>
        <title>{props.title}: Meritrade</title>
      </Helmet>
      <div className="mb-6">
        <div className="p-6 pb-0 md:pb-6 justify-between items-start md:items-center gap-4 md:gap-2.5 flex md:flex-row flex-col-reverse">
          <div>
            <h3 className="text-stone-950 dark:text-[#ffffff] text-lg md:text-xl font-semibold">
              {props.title}
            </h3>
            {props.secondary || props.primary ? (
              <div className="justify-center items-center gap-2 inline-flex">
                <Link
                  to="/dashboard"
                  className="text-neutral-700 text-xs font-medium leading-tight">
                  Home
                </Link>

                {props.primaryLink && props.primary ? (
                  <>
                    <div className="w-1 h-1 bg-neutral-400 rounded-[100px]" />
                    <Link
                      to={props.primaryLink}
                      search={{}}
                      params={{}}
                      className="text-neutral-700 text-xs font-medium leading-tight">
                      {props.primary}
                    </Link>
                  </>
                ) : (
                  <>
                    <div className="w-1 h-1 bg-neutral-400 rounded-[100px]" />
                    <div className="text-neutral-400 text-xs font-medium leading-tight">
                      {props.primary}
                    </div>
                  </>
                )}

                {props.secondary && (
                  <>
                    <div className="w-1 h-1 bg-neutral-400 rounded-[100px]" />
                    <div className="text-neutral-400 text-xs font-medium leading-tight">
                      {props.secondary}
                    </div>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 w-full md:w-auto">
            {props.showTradeNowButton && (
             <CustomButton
             onClick={() => {
               window.open(Constant.StockRecommendation, "_blank");
             }}
             className="w-full lg:w-[198px]"
             variant="primary-outline">
             Stock Recommendation
           </CustomButton> 
            )}

            {props.showTradeNowButton ? (
              <Link to="/dashboard/orders/create">
                <CustomButton className="w-full md:w-[198px]">
                  Trade now
                </CustomButton>
              </Link>

            ) : (
              <CustomButton
                onClick={() => {
                  window.open(Constant.StockRecommendation, "_blank");
                }}
                className="w-full md:w-[198px]">
                Stock Recommendation
              </CustomButton>
            )}

            <CustomButton
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.meristem.meritradeapp&hl=en_US",
                  "_blank"
                );
              }}
              className="w-full md:w-[198px]"
              variant="primary-outline">
              Download app
            </CustomButton>
          </div>
        </div>

        <div className="md:border-b border-stone-950 border-opacity-10"></div>
      </div>
    </>
  );
}

export default PageNavBar;
