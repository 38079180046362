import Spinner from "../../icons/spinner"
import clsx from "clsx"
import React from "react"

type ButtonProps = {
  isLoading?: boolean
  variant?: "primary" | "referral" | "primary-outline" | "primary-grey"
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  children,
  className,
  isLoading = false,
  variant = "primary",
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      className={clsx(
        `font-bold whitespace-nowrap  pt-[7.47px] pr-[22.41px] pb-[7.47px] pl-[22.41px] flex flex-row gap-[18.68px] items-center justify-center h-[49px] transition-colors duration-200 disabled:opacity-50`,
        {
          "rounded-md bg-green-900  hoverbg-green-500  text-white disabled:hover:bg-green-500 ":
            variant === "primary",
          "rounded-[5.08px] border border-gray-400 text-green-900 text-sm font-semibold capitalize":
            variant === "primary-outline",
            "rounded-r-lg bg-green-900  hoverbg-green-500  text-white disabled:hover:bg-green-500 ":
            variant === "referral",

            "text-green-900 text-sm font-semibold capitalize bg-green-900 bg-opacity-5 rounded border border-gray-400":
            variant === "primary-grey",
        },
        className
      )}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

export default Button
