import PageNavBar from "../layout/PageNavBar";
import { capitalizeSentence, formatMoney } from "@/lib/library";
import { Account } from "@/lib/services/class/account";
import { Key, useContext, useEffect } from "react";
import { AppContext } from "@/lib/contexts/App.context";
import SmallLogo from "@/assets/images/logo_small.svg";
import { Portfolio } from "@/lib/services/class/portfolio";
import portfolioOperations from "@/lib/store/features/portfolio/portfolioOperations";
import portfolioSelector from "@/lib/store/features/portfolio/portfolioSelector";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import accountSelector from "@/lib/store/features/account/accountSelector";
import accountOperations from "@/lib/store/features/account/accountOperations";
import { ThunkDispatch } from "@reduxjs/toolkit";

function AssetAllocation() {
  const { user } = useContext(AppContext);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const portfolioData = useSelector<RootState, Portfolio[]>(
    portfolioSelector.getPortfolio
  );

  const accountData = useSelector<RootState, Account[]>(
    accountSelector.getAccount
  );

  useEffect(() => {
    dispatch(portfolioOperations.fetchPortfolio());
    dispatch(accountOperations.fetchAccounts());
  }, [dispatch]);

  const getTotalAccount = (arr: Account[]) => {
    const result = arr.reduce(function (acc, obj) {
      return acc + obj.unClearedBalance;
    }, 0);
    return result;
  };

  const getTotalPortfolio = (arr: Portfolio[]) => {
    const result = arr.reduce(function (acc, obj) {
      return acc + obj.currentValuation?.amount;
    }, 0);
    return result;
  };

  return (
    <>
      <PageNavBar
        title="Account Summary"
        primary="Portfolio"
        primaryLink="/dashboard/portfolio"
        secondary="Account Summary"
        showTradeNowButton={true}
      />

      <div
        className=" mx-4 mb-6 
      grid lg:grid-cols-6 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-12 gap-0">
        <div className="hidden md:flex sticky top-28 h-max col-span-2 px-[52px] pt-6 pb-20 bg-white rounded-lg border border-stone-950 border-opacity-10 flex-col justify-start items-center gap-6 ">
          <img
            alt="profile"
            className="w-[215px] h-[215px]  rounded-full"
            src={
              user?.picture
                ? `data:${user?.pictureMimeType};base64,${user?.picture}`
                : SmallLogo
            }
          />

          <div className="text-center pt-4">
            <div className="pb-2 text-stone-950 dark:text-[#f3f3f3] text-xl font-semibold">
              {capitalizeSentence(user?.label)}
            </div>
            <div className=" text-neutral-500 text-sm font-medium leading-tight">
              {user?.name}
            </div>
          </div>
        </div>

        <div className="col-span-4">
          <div className="mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
            <div className="p-6">
              <h3 className=" text-stone-950 dark:text-[#f3f3f3] text-base font-semibold capitalize">
                Account Summary
              </h3>
            </div>

            <table className="min-w-full h-auto table-auto w-full">
              <thead className="[&>tr]:first:rounded-lg">
                <tr className="group outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2">
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    ASSETS
                  </th>
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    Value (N)
                  </th>
                </tr>
              </thead>
              <tbody>
                {accountData.map(
                  (row: Account, index: Key | null | undefined) => {
                    return (
                      <tr key={index} className="">
                        <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                          {row.label}
                        </td>
                        <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                          {formatMoney(row.unClearedBalance)}
                        </td>
                      </tr>
                    );
                  }
                )}
                {portfolioData.map(
                  (row: Portfolio, index: Key | null | undefined) => {
                    return (
                      <tr key={index} className="">
                        <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                          {row.label}
                        </td>
                        <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                          {formatMoney(row.currentValuation?.amount ?? 0)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>

              {portfolioData && (
                <tfoot>
                  <tr>
                    <td className="p-3 text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold capitalize">
                      Net Assets
                    </td>
                    <td className="p-3 text-stone-950 dark:text-[#f3f3f3] text-lg font-semibold capitalize">
                      {formatMoney(
                        getTotalAccount(accountData) +
                          getTotalPortfolio(portfolioData)
                      )}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetAllocation;
