import cancelButton from "@/assets/images/icons/cancel.svg";
import Button from "@/lib/common/components/button";
import Input from "@/lib/common/components/input";
import { watchlistSchema } from "@/lib/services/validators/fund-validator";
import { ErrorProps } from "@/lib/types/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import SuccessfulAction from "@/assets/images/successful_action.svg";
import { EventBus } from "@/lib/EventBus";
import watchlistOperations from "@/lib/store/features/watchlist/watchlistOperations";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  symbol: string;
}

interface FormProps {
  trigger_price: number;
}

export default function AddWatchlistModal({ symbol, setIsOpen }: Props) {
  const [loading, setLoading] = useState(false);
  const [isPosted, setIsPosted] = useState(false);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(watchlistSchema),
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {

      dispatch(watchlistOperations.createWathclist({
        trigger_price: da.trigger_price,
        symbol,
      }));
      setIsPosted(true);
      EventBus.getInstance().dispatch<string>('watchlist', 'reload');
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
      <div
        className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
        style={{ zIndex: 100 }}>
        <div className="my-10 flex h-full md:h-auto items-center justify-center ">
          <div
            className="
             bg-stone-50 rounded-[10px] flex-col justify-center items-start gap-6 inline-flex
            w-full 
            md:w-[468px] mx-auto  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
            <div className="w-full bg-stone-50 rounded-[10px] relative">
              {isPosted ? (
                <>
                  <div className="w-full h-[410px] bg-stone-50 rounded-[10px] relative  text-center s" >
                    <div
                      className="cursor-pointer w-10 h-10 right-5 top-5 absolute bg-black bg-opacity-5 rounded"
                      onClick={() => setIsOpen(false)}>
                      <img src={cancelButton} />
                    </div>
                    <div className="py-10 bg-green-900 bg-opacity-10 flex justify-center items-center">
                      <img src={SuccessfulAction} />
                    </div>
                    <div className="flex-col flex justify-center items-center gap-7 pt-8">
                      <div className="text-center text-stone-950 dark:text-[#f3f3f3] text-2xl font-semibold font-['Inter'] capitalize">
                        Watchlist Successfully
                      </div>
                      <div className="text-center text-neutral-700 text-sm font-normal font-['Inter'] leading-tight">
                         {symbol} has been added to your watchlist
                      </div>

                      <div className="flex justify-center items-center">
                        <Button
                          type="button"
                          variant="primary-outline"
                          onClick={() => setIsOpen(false)}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cursor-pointer w-10 h-10  right-5 top-5 absolute bg-black bg-opacity-5 rounded"
                    onClick={() => setIsOpen(false)}>
                    <img src={cancelButton} />
                  </div>
                </>
              ) : (
                <div className="p-6">
                  <div className="w-full justify-between items-center inline-flex">
                    <div className="text-stone-950 dark:text-[#f3f3f3] text-xl font-semibold font-['Inter']">
                      {symbol}
                    </div>

                    <div
                      className="cursor-pointer w-10 h-10 relative bg-black bg-opacity-5 rounded"
                      onClick={() => setIsOpen(false)}>
                      <img src={cancelButton} />
                    </div>
                  </div>

                  <div className="flex-col flex justify-center items-center gap-7 pt-8">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="pt-4">
                        <Input
                          {...register("trigger_price")}
                          errors={errors.trigger_price}
                          required
                          type="text"
                          label="Trigger Price"
                          className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
                        />
                      </div>

                      <div className="pt-8 w-full text-center text-neutral-500 text-xs font-normal font-['Inter'] leading-[18px]">
                        By clicking save, you will get notification when the
                        price you enter is triggered.
                      </div>

                      <div className="pt-10 flex justify-between items-center">
                        <Button onClick={() => setIsOpen(false)} type="button" variant="primary-outline">Cancel</Button>
                        <Button
                          disabled={loading}
                          isLoading={loading}
                          type="submit">
                          Save
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
