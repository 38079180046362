import Select from "@/lib/common/components/select";
import PageNavBar from "../layout/PageNavBar";
import useOrderSWR from "@/lib/hook/useOrderSWR";
import DateRange from "@/lib/common/components/date/DateRange";
import tradeOrderStatus from "@/lib/utils/trade-order-status.json";
import { StatusProps } from "@/lib/types/global";
import Button from "@/lib/common/components/button";
import { useCallback, useEffect, useState } from "react";
import { Link } from "@tanstack/react-router";
import {
  capitalizeFirstLetter,
  formatDate,
  getSymbolImage,
} from "@/lib/library";
import React from "react";
import NoContent from "@/lib/common/components/table/NoContent";
import CustomPagination from "@/lib/common/components/table/Pagination";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  Spinner,
  TableRow,
  TableCell,
  SortDescriptor,
} from "@nextui-org/react";
import { TradeOrder } from "@/lib/services/class/tradeOrder";
import Constant from "@/lib/constant";
import TableCss from "@/lib/utils/table-css";
import SearchForm from "../layout/component/SearchForm";
import { tradeOrderColumns } from "@/lib/utils/tableColumns";
import SecurityInfo from "./components/SecurityInfo";
import OrderDetailsModal from "./components/OrderDetailsModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { EventBus } from "@/lib/EventBus";

function TradeOrderPage() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tradeOrder, setTradeOrder] = useState<TradeOrder>();

  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(1);
  const hasSearchFilter = Boolean(filterValue);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "orderDate",
    direction: "descending",
  });
  const {
    data,
    status,
    setStatus,
    handleChangeStatus,
    handleChangeDate,
    dateRange,
    isLoading,
    mutate,
  } = useOrderSWR();

  useEffect(() => {
    setStatus("null");
  }, [setStatus]);

  useEffect(() => {
    EventBus.getInstance().register("tradeOrder", () => {
      mutate();
    });
  });

  const onSearchChange = React.useCallback((value: string | undefined) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const filteredItems = React.useMemo(() => {
    const result =
      data != undefined && data.data.result ? data.data.result : [];
    let filteredUsers = [...result];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter(
        (user: TradeOrder) =>
          user.securityName.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.name.toLowerCase().includes(filterValue)
      );
    }

    return filteredUsers;
  }, [data, filterValue, hasSearchFilter]);

  const pages = Math.ceil(filteredItems.length / Constant.RowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * Constant.RowsPerPage;
    const end = start + Constant.RowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof TradeOrder];
      const second = b[sortDescriptor.column as keyof TradeOrder];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = useCallback((order: TradeOrder, columnKey: React.Key) => {
    const cellValue = order[columnKey as keyof TradeOrder];

    switch (columnKey) {
      case "securityName":
        return (
          <SecurityInfo symbol={order.securityName}>
            <div className="justify-start items-center gap-2 flex">
              {order.securityName && (
                <LazyLoadImage
                  className="w-[25px] h-[25px] rounded-full"
                  src={getSymbolImage(order.securityName)}
                />
              )}

              <div>{cellValue}</div>
            </div>
          </SecurityInfo>
        );
      case "orderDate":
        return formatDate(cellValue, "YYYY-MM-DD");
      case "fixOrderStatus":
        if (order.fixOrderStatus === "EXPIRED")
          return (
            <div className="text-neutral-700 text-sm font-normal font-['Inter'] leading-tight">
              Expired
            </div>
          );
        else if (order.fixOrderStatus === "REJECTED")
          return (
            <div className="text-red-600 text-sm font-normal font-['Inter'] leading-tight">
              Rejected
            </div>
          );
        else if (order.fixOrderStatus === "FILLED")
          return (
            <div className="text-green-600 text-sm font-normal font-['Inter'] leading-tight">
              Filled
            </div>
          );
        else return cellValue;
      case "orderStatus":
        if (order.orderStatus === "EXPIRED")
          return (
            <div className="text-neutral-700 text-sm font-normal font-['Inter'] leading-tight">
              {capitalizeFirstLetter(order.orderStatus)}
            </div>
          );
        else if (order.orderStatus === "CANCELLED")
          return (
            <div className="text-red-600 text-sm font-normal font-['Inter'] leading-tight">
              {capitalizeFirstLetter(order.orderStatus)}
            </div>
          );
        else if (order.orderStatus === "EXECUTED")
          return (
            <div className="text-green-600 text-sm font-normal font-['Inter'] leading-tight">
              {capitalizeFirstLetter(order.orderStatus)}
            </div>
          );
        else if (order.orderStatus === "EXECUTING")
          return (
            <div className="text-blue-600 text-sm font-normal font-['Inter'] leading-tight">
              {capitalizeFirstLetter(order.orderStatus)}
            </div>
          );
        else if (order.orderStatus === "SUSPENDED")
          return (
            <div className="text-brown-600 text-sm font-normal font-['Inter'] leading-tight">
              {capitalizeFirstLetter(order.orderStatus)}
            </div>
          );
        else return cellValue;

      case "orderType":
        return order.orderType === "BUY" ? (
          <div className="text-emerald-700 text-sm font-normal leading-tight">
            Buy
          </div>
        ) : (
          <div className="text-red-600 text-sm font-normal leading-tight">
            Sell
          </div>
        );

      case "actions":
        return (
          <>
            <Button
              onClick={() => {
                setIsOpen(true);
                setTradeOrder(order);
              }}
              variant="primary-outline">
              More Details
            </Button>
          </>
        );
      default:
        return cellValue;
    }
  }, []);

  const loadingState = isLoading ? "loading" : "idle";

  const classNames = React.useMemo(() => TableCss, []);

  return (
    <>
      {isOpen && (
        <OrderDetailsModal setIsOpen={setIsOpen} tradeOrder={tradeOrder} />
      )}

      <PageNavBar
        title="Trade Order"
        primary="Trade Order"
        showTradeNowButton={true}
      />

      <div className=" mx-4 mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
        <div className="p-4">
          <div className="mb-8">
            <SearchForm
              placeholder="Search order no or symbol"
              onChange={(value) => {
                onSearchChange(value);
              }}
            />
          </div>

          <div className="flex-col md:flow-row flex justify-between items-start gap-4">
            <Select
              className="w-48"
              value={status}
              onChange={(event) => {
                handleChangeStatus(event.target.value);
              }}
              label="Select a Status"
              placeholder="Select Starting Point">
              {tradeOrderStatus.map((item: StatusProps) => {
                return item.value === status ? (
                  <option key={item.value} value={item.value} selected>
                    {item.name}
                  </option>
                ) : (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </Select>

            <div className="flex justify-center items-end gap-2 ">
              <DateRange
                label="Date Range"
                value={dateRange}
                onChange={(values) => {
                  handleChangeDate(values);
                }}
              />

              <Link to="/dashboard/orders/create">
                <Button variant="primary-outline" type="button">
                  New Order
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div className="mx-4 p-5 bg-red-600 bg-opacity-10 rounded text-red-600 text-sm font-normal">
          If you want to <span className="leading-normal">cancel</span>
          <span> an ORDER, kindly send a mail to </span>
          <a
            href="mailto:contact@meristemng.com"
            className="text-red-600 text-sm font-semibold font-inter leading-normal">
            contact@meristemng.com
          </a>{" "}
          or send a message via the live chat platform
        </div>

        <div className="py-5 w-full">
          <Table
            isCompact
            classNames={classNames}
            sortDescriptor={sortDescriptor}
            onSortChange={setSortDescriptor}
            bottomContent={
              pages > 0 ? (
                <div className="flex w-full justify-center">
                  <CustomPagination
                    isCompact
                    showControls
                    showShadow
                    page={page}
                    total={pages}
                    onChange={(page: React.SetStateAction<number>) =>
                      setPage(page)
                    }
                  />
                </div>
              ) : null
            }>
            <TableHeader columns={tradeOrderColumns}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                  allowsSorting={column.sortable}>
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody<TradeOrder>
              isLoading={isLoading}
              emptyContent={<NoContent />}
              items={sortedItems}
              loadingContent={<Spinner />}
              loadingState={loadingState}>
              {(item: TradeOrder) => {
                return (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                );
              }}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default TradeOrderPage;
