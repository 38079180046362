import { RootState } from "../../index";

const getTopPercentGainer = (state: RootState) => state.security.topPercentGainer;
const getTopPercentLooser = (state: RootState) => state.security.topPercentLooser;
const getTopValueGainer = (state: RootState) => state.security.topValueGainer;
const getTopValueLooser = (state: RootState) => state.security.topValueLooser;
const getMostActive = (state: RootState) => state.security.mostActive;
const getSecurities = (state: RootState) => state.security.data;
const getDetails = (state: RootState) => state.security.details;


const getIsLoading = (state: RootState) => state.security.isLoading;
const getError = (state: RootState) => state.security.error;

export default {
  getTopPercentGainer,
  getTopPercentLooser,
  getTopValueGainer,
  getTopValueLooser,
  getMostActive,
  getSecurities,
  getDetails,
  getIsLoading,
  getError,
};
