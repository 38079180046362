import * as Yup from "yup";
import { validateInputEmail, validatePassword } from "./validatesInput";

export const chnagePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Password is required"),
  newPassword: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=(.*[0-9]))(?=.*[#@$?!])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/,
      "Password must contain at least 8 characters, at least 1 numeric character, at least 1 lowercase letter, at least 1 uppercase letter and at least any of this special character #@$?!"
    ),
  confirm_newPassword: Yup.string()
    .required("Confirm new password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match New Password"),
});

export const nextOfKinSchema = Yup.object().shape({
  nexofKin: Yup.string().required(),
  nexofKinEmailAddress: Yup.string()
    .email("Email is invalid")
    .required("Email is required")
    .test("Email", "accents not allowed", (value: string) =>
      validateInputEmail(value)
    ),
  nextofKinPhone: Yup.string().required("Next of kin phone is required"),
  nextofKinAddress: Yup.string().required("Next of kin address is required"),
  nextofKinRelationship: Yup.string().required(
    "Next of kin relationship is required"
  ),
});

export const registerSchema = Yup.object().shape({
    title: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL" ? field.required("Title is required") : field
  ),
  referalCode: Yup.string(),
  passport: Yup.mixed<FileList>()
    .test(
      "required",
      "Your passport is required",
      (files) => !!(files && files.length > 0)
    )
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
    
  firstName: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("First name is required")
      : field
  ),
  middleName: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "ORGANIZATION"
      ? field.required("Contact Person Name is required")
      : field
  ),
  lastName: Yup.string().when("partnerType", (partnerType, field) => {
    return partnerType[0] == "INDIVIDUAL"
      ? field.required("Surname is required")
      : field.required("Company name is required");
  }),
  portalUserName: Yup.string().required("Username is required"),
  portalPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
    .test(
      "one-uppercase character special character and a number",
      "Password must contain at least one uppercase letter, one special character and one number",
      (value: string) => validatePassword(value)
    ),
  emailAddress1: Yup.string()
    .email("Email is invalid")
    .required("Email is required")
    .test("Email", "accents not allowed", (value: string) =>
      validateInputEmail(value)
    ),

  sex: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL" ? field.required("Sex is required") : field
  ),

  identifier: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "ORGANIZATION"
      ? field.required("Identifier is required")
      : field
  ),

  nexofKin: Yup.string().required(),

  nextofKinPhone: Yup.string().required("Next of kin phone is required"),
  motherMaidenName: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("Mother maiden name is required")
      : field
  ),
  birthDate: Yup.date().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.typeError("Birth date is required")
      : field.typeError("Date of Registration is required")
  ),
  primaryAddress1: Yup.string().required("Address is required"),
  primaryCity: Yup.string().required("City is required"),
  primaryState: Yup.string().required("State is required"),
  primaryCountry: Yup.string().required("Country is required"),

  cellPhone: Yup.string().required("Cell phone is required"),
  partnerType: Yup.string().required("Partner type is required"),

  bvnCode: Yup.string()
    .min(11, "BVN must be have 11 characters")
    .max(11, "BVN must be have 11 characters")
    .required("BVN is required"),
  setttlementBankAccountNumber: Yup.string()
    .min(10, "Account number must have 10 characters")
    .max(10, "Account number must have 10 characters")
    .required("Account number is required"),
  setttlementBankName: Yup.string().required("Bank name is required"),
  setttlementBankAccountName: Yup.string().required("Account name is required"),
});

export const updateDetailsSchema = Yup.object().shape({
  partnerType: Yup.string().required("Partner type is required"),

  identification: Yup.mixed<FileList>()
    .test(
      "required",
      "Your identification is required",
      (files) => !!(files && files.length > 0)
    )

    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
  utilityBill: Yup.mixed<FileList>()
    .test(
      "required",
      "Your utility bill is required",
      (files) => !!(files && files.length > 0)
    )

    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
  signature: Yup.mixed<FileList>()
    .test(
      "required",
      "Your signature is required",
      (files) => !!(files && files.length > 0)
    )

    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
  religion: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("Religion is required")
      : field
  ),
  nationality: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("Nationality is required")
      : field
  ),
  overallInvstObj: Yup.string().required(
    "Pverall investment objective is required"
  ),
  riskTolerance: Yup.string().required("Risk Tolerance is required"),
  sourceOfWealth: Yup.string().required("Source of wealth is required"),
  invstKnowledge: Yup.string().required("Investment knowledge is required"),
  estimatedNetWorth: Yup.number()
    .typeError("must be numeric")
    .required("Estimated net worth is required"),
  estimatedAnnualIncome: Yup.number()
    .typeError("must be numeric")
    .required("Estimated annual income is required"),
});

/*

export const profileInformationSchema = Yup.object().shape({
  portalUserName: Yup.string().required("Username is required"),
  portalPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
    .test(
      "one-uppercase character special character and a number",
      "Password must contain at least one uppercase letter, one special character and one number",
      (value: string) => validatePassword(value)
    ),
  firstName: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("First name is required")
      : field
  ),
  middleName: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "ORGANIZATION"
      ? field.required("Contact Person Name is required")
      : field
  ),
  lastName: Yup.string().when("partnerType", (partnerType, field) => {
    return partnerType[0] == "INDIVIDUAL"
      ? field.required("Surname is required")
      : field.required("Company name is required");
  }),
  religion: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("Religion is required")
      : field
  ),
  nationality: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("Nationality is required")
      : field
  ),
  sex: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL" ? field.required("Sex is required") : field
  ),
  motherMaidenName: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.required("Mother maiden name is required")
      : field
  ),
  emailAddress1: Yup.string()
    .email("Email is invalid")
    .required("Email is required")
    .test("Email", "accents not allowed", (value: string) =>
      validateInputEmail(value)
    ),
  overallInvstObj: Yup.string().required(
    "Pverall investment objective is required"
  ),
  riskTolerance: Yup.string().required("Risk Tolerance is required"),
  sourceOfWealth: Yup.string().required("Source of wealth is required"),
  invstKnowledge: Yup.string().required("Investment knowledge is required"),
  estimatedNetWorth: Yup.number()
    .typeError("must be numeric")
    .required("Estimated net worth is required"),
  estimatedAnnualIncome: Yup.number()
    .typeError("must be numeric")
    .required("Estimated annual income is required"),

  birthDate: Yup.date().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL"
      ? field.typeError("Birth date is required")
      : field.typeError("Date of Registration is required")
  ),
  title: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "INDIVIDUAL" ? field.required("Title is required") : field
  ),
  identifier: Yup.string().when("partnerType", (partnerType, field) =>
    partnerType[0] == "ORGANIZATION" ? field.required("Identifier ") : field
  ),
  cellPhone: Yup.string().required("Cell phone is required"),
  partnerType: Yup.string().required("Partner type is required"),
  referalCode: Yup.string(),
});

export const bankInformationSchema = Yup.object().shape({
  bvnCode: Yup.string()
    .min(11, "BVN must be have 11 characters")
    .max(11, "BVN must be have 11 characters")
    .required("BVN is required"),
  setttlementBankBranch: Yup.string(),
  setttlementBankAccountNumber: Yup.string()
    .min(10, "Account number must have 10 characters")
    .max(10, "Account number must have 10 characters")
    .required("Account number is required"),
  setttlementBankAccountSCode: Yup.string(),
  setttlementBankName: Yup.string().required("Bank name is required"),
  setttlementBankAccountName: Yup.string().required("Account name is required"),
});

export const contactInformationSchema = Yup.object().shape({
  primaryAddress1: Yup.string().required("Address is required"),
  primaryCity: Yup.string().required("City is required"),
  primaryState: Yup.string().required("State is required"),
  primaryPostCode: Yup.string().required("Postal code is required"),
  primaryCountry: Yup.string().required("Country is required"),
});

export const kycnformationSchema = Yup.object().shape({
  passport: Yup.mixed<FileList>()
    .test(
      "required",
      "Your passport is required",
      (files) => !!(files && files.length > 0)
    )
    .test(
      "fileSize",
      "Only documents up to 2MB are permitted.",
      (files) =>
        !files ||
        files.length === 0 ||
        Array.from(files).every((file) => file.size <= 2048576)
    )
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
  identification: Yup.mixed<FileList>()
    .test(
      "required",
      "Your identification is required",
      (files) => !!(files && files.length > 0)
    )
    .test(
      "fileSize",
      "Only documents up to 2MB are permitted.",
      (files) =>
        !files ||
        files.length === 0 ||
        Array.from(files).every((file) => file.size <= 2048576)
    )
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
  utilityBill: Yup.mixed<FileList>()
    .test(
      "required",
      "Your utility bill is required",
      (files) => !!(files && files.length > 0)
    )
    .test(
      "fileSize",
      "Only documents up to 2MB are permitted.",
      (files) =>
        !files ||
        files.length === 0 ||
        Array.from(files).every((file) => file.size <= 2048576)
    )
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
  signature: Yup.mixed<FileList>()
    .test(
      "required",
      "Your signature is required",
      (files) => !!(files && files.length > 0)
    )
    .test(
      "fileSize",
      "Only documents up to 2MB are permitted.",
      (files) =>
        !files ||
        files.length === 0 ||
        Array.from(files).every((file) => file.size <= 2048576)
    )
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg, .pdf ",
      (files) => {
        return (
          !files ||
          files.length === 0 ||
          Array.from(files).every(
            (file) =>
              file.type === "image/jpeg" ||
              file.type === "image/png" ||
              file.type === "application/pdf"
          )
        );
      }
    ),
});
*/
