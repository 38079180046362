import Button from "@/lib/common/components/button";
import CardTemplate from "../../dashboard/components/card";
import CopyWhiteSVG from "@/assets/images/icons/copy-white.svg";
import { toast } from "react-toastify";
import Service from "@/lib/services/request";
import useSWR from "swr";

export default function VirtualAccount() {
  const account = useSWR(Service.virtualAccount, {
    revalidateOnFocus: false, // Disable revalidation when the window is focused
    dedupingInterval: 600000, // Cache for 10 minute before revalidation
  });

  return (
    <>
      {account?.error?.["message"] && (
        <div className="mb-5 p-5 bg-red-600 bg-opacity-10 rounded text-red-600 text-sm font-normal">
          <div className="flex flex-col justify-start items-start gap-1">
            <span className="font-semibold">Virtual account error:</span>
            <span>{account?.error?.["message"]}</span>
          </div>
        </div>
      )}
      {account?.data?.["data"]?.["message"] && (
        <div className="mb-5 p-5 bg-green-600 bg-opacity-10 rounded text-green-600 text-sm font-normal">
          <div className="flex flex-col justify-start items-start gap-1">
            <span className="font-semibold">Virtual account status:</span>
            <span>{account?.data?.["data"]?.["message"]}</span>
          </div>
        </div>
      )}

      {account?.data?.["data"]?.["data"] && (
        <CardTemplate title="Virtual Account" showBorder={false}>
          <div className="w-full px-6 pb-6 flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              {/* <div className="flex justify-between items-center text-[14px] "> */}
              <div>
                Bank Name:&nbsp;
                <span className="font-bold text-[#0E0E0E]">
                  {account?.data?.["data"]?.["data"]?.["last_name"]}
                </span>
              </div>
              <div>
                Account Name:{" "}
                <span className="font-bold text-[#0E0E0E]">
                  {account?.data?.["data"]?.["data"]?.["bank_code"]}
                </span>
              </div>
              {/* </div> */}

              <div className="flex flex-col justify-start items-start gap-1">
                Account Number:
                <div className="font-bold text-[24px] leading-[29.05px] text-[#0E0E0E]">
                  {
                    account?.data?.["data"]?.["data"]?.[
                      "virtual_account_number"
                    ]
                  }
                </div>
              </div>
            </div>

            <Button>
              <div
                onClick={() => {
                  navigator.clipboard.writeText(
                    account?.data?.["data"]?.["data"]?.[
                      "virtual_account_number"
                    ]
                  );
                  toast("Copy account Details", {
                    type: "success",
                  });
                }}
                className="whitespace-nowrap cursor-pointer justify-center gap-2 items-center inline-flex  text-[14px] font-semibold "
              >
                <img src={CopyWhiteSVG} />
                Copy account Details
              </div>
            </Button>

           

            <div className="bg-[#144D330D] p-4 rounded-[6px] flex flex-col gap-3">
              <span className="font-semibold text-[#0E0E0E] text-[16px] leading-[19.36px]">
                🔹 How it works:
              </span>

              <ol className="text-[#3E3E3E] list-decimal list-outside ms-5 leading-[25px]">
                <li>Transfer funds to this account from your bank.</li>
                <li>Your wallet is credited automatically.</li>
                <li>You get notified once the deposit is successful.</li>
                <li>
                  Please note that the transaction fee is 1%, with a maximum
                  charge of ₦300
                </li>
              </ol>
            </div>
          </div>
        </CardTemplate>
      )}
    </>
  );
}
