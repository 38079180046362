import SettingProfileSVG from "../common/icons/menus/setting_profile";
import SettingSecuritySVG from "../common/icons/menus/setting_security";
const data = [
  {
    title: "Profile",
    Image: SettingProfileSVG,
    items: [
      {
        title: "My Profile",
        link: "/dashboard/setting",
      },
      {
        title: "Next of kin",
        link: "/dashboard/setting/next-of-kin",
      },
    ],
  },
  {
    title: "Security & Safety",
    Image: SettingSecuritySVG,
    items: [
      {
        title: "Change Password",
        link: "/dashboard/setting/change-password",
      },
      {
        title: "Configuration",
        link: "/dashboard/setting/configuration",
      },
    ],
  },
];

export default data;
