import { combineReducers, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';
import { watchlistReducer } from './features/watchlist/watchlistSlice';
import { securityReducer } from './features/security/securitySlice';
import { portfolioReducer } from './features/portfolio/portfolioSlice';
import { accountReducer } from './features/account/accountSlice';

const persistConfig : any = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({ 
  watchlist: watchlistReducer,
  security: securityReducer,
  portfolio: portfolioReducer,
  account: accountReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: (arg0: { serializableCheck: { ignoredActions: ("persist/FLUSH" | "persist/REHYDRATE" | "persist/PAUSE" | "persist/PERSIST" | "persist/PURGE" | "persist/REGISTER")[]; }; }) => any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type Store = EnhancedStore;

export const persistor = persistStore(store);
