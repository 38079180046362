import { ReactNode, useContext, useEffect, useState } from "react";
import HeaderLayout from "./HeaderLayout";
import SidebarLayout from "./SidebarLayout";
import CountdownTimer from "./component/countdown/CountdownTimer";
import { ModalContext } from "@/lib/contexts/modal-context";
import { getMarketTime } from "@/lib/library";
import { toast } from "react-toastify";
import SessionHandler from "./component/SessionHandler";
import Tawk from "@/lib/common/components/tawk";
import AddWatchlistModal from "../portfolio/components/watchlist/AddWatchlistModal";
import { AppContext } from "@/lib/contexts/App.context";
import StockTicker from "./StockTicker";
import MobileMenu from "./component/MobileMenu";

type Props = {
  children: ReactNode;
};

function DashboardLayout({ children }: Props) {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const {
    tradingTime,
    setTradingTime,
    watchlistModal,
    setWatchlistModal,
    symbol,
  } = useContext(ModalContext);
  const { user } = useContext(AppContext);

  const marketPer = getMarketTime();

  useEffect(() => {
    function offlineHandler() {
      toast("You are offline. Please check your internet connection.", {
        type: "error",
      });
    }

    window.addEventListener("offline", offlineHandler);
    return () => {
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <div className="bg-[#F8FAF9] dark:bg-[#18181B] min-h-screen scroll-smooth hover:scroll-auto">
      {watchlistModal && (
        <AddWatchlistModal setIsOpen={setWatchlistModal} symbol={symbol} />
      )}

      {showMobileMenu && <MobileMenu setShowMobileMenu={setShowMobileMenu} />}

      <SessionHandler />

      <div className="block md:hidden py-2 border-b border-stone-950 border-opacity-10">
        <StockTicker />
      </div>
      <HeaderLayout setShowMobileMenu={setShowMobileMenu} />

      <SidebarLayout />

      <div className=" content ml-0 md:ml-24 md:pt-24 ">
        {children}

        {user &&
          user.marketdataCountdown == false &&
          tradingTime &&
          marketPer.date != undefined && (
            <CountdownTimer
              targetDate={marketPer.date}
              setIsOpen={setTradingTime}
            />
          )}
      </div>
      <Tawk />
    </div>
  );
}

export default DashboardLayout;
