import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { Portfolio } from '@/lib/services/class/portfolio';
import portfolioOperations from './portfolioOperations';

interface PortfolioState {
  data: Portfolio[];
  isLoading: boolean;
  isEditing: boolean;
  error: string | null;
}

const initialState: PortfolioState = {
  data: [],
  isLoading: false,
  isEditing: false,
  error: null,
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<PortfolioState>) => {
    builder
      .addCase(portfolioOperations.fetchPortfolio.pending, (state: PortfolioState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        portfolioOperations.fetchPortfolio.fulfilled,
        (state: PortfolioState, action: any) => {
          state.data = action.payload ? action.payload['result'] : [];
          state.isLoading = false;
        },
      )
      .addCase(
        portfolioOperations.fetchPortfolio.rejected,
        (state: PortfolioState) => {
          state.isLoading = false;
        },
      )
  },
});

export const  portfolioReducer = portfolioSlice.reducer;