import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";

import { Security } from "@/lib/services/class/security";
import securityOperations from "./securityOperations";
import { defaultSecurity } from "@/lib/services/class/defaultSecurity";

interface SecurityState {
  data: Security[];
  topPercentGainer: Security[];
  topPercentLooser: Security[];
  topValueGainer: Security[];
  topValueLooser: Security[];
  mostActive: Security[];
  details: Security;
  isLoading: boolean;
  error: string | null;
}

const initialState: SecurityState = {
  data: [],
  topPercentGainer: [],
  topPercentLooser: [],
  topValueGainer: [],
  topValueLooser: [],
  mostActive: [],
  details: defaultSecurity,
  isLoading: false,
  error: null,
};

const securitySlice = createSlice({
  name: "security",
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<SecurityState>) => {
    builder
      .addCase(
        securityOperations.fetchSecurity.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchSecurity.fulfilled,
        (state: SecurityState, action: any) => {
          state.data = action.payload ? action.payload["result"] : [];
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchSecurity.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchTopPercentGainer.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchTopPercentGainer.fulfilled,
        (state: SecurityState, action: any) => {
          state.topPercentGainer = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchTopPercentGainer.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )

      .addCase(
        securityOperations.fetchTopPercentLooser.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchTopPercentLooser.fulfilled,
        (state: SecurityState, action: any) => {
          state.topPercentLooser = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchTopPercentLooser.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )

      .addCase(
        securityOperations.fetchTopValueGainer.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchTopValueGainer.fulfilled,
        (state: SecurityState, action: any) => {
          state.topValueGainer = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchTopValueGainer.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )

      .addCase(
        securityOperations.fetchTopValueLooser.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchTopValueLooser.fulfilled,
        (state: SecurityState, action: any) => {
          state.topValueLooser = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchTopValueLooser.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )
      
      .addCase(
        securityOperations.fetchSecurityDetails.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchSecurityDetails.fulfilled,
        (state: SecurityState, action: any) => {
          state.details = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchSecurityDetails.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchMostActive.pending,
        (state: SecurityState) => {
          state.isLoading = true;
          state.error = null;
        }
      )
      .addCase(
        securityOperations.fetchMostActive.fulfilled,
        (state: SecurityState, action: any) => {
          state.mostActive = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(
        securityOperations.fetchMostActive.rejected,
        (state: SecurityState) => {
          state.isLoading = false;
        }
      )
      ;
  },
});


export const securityReducer = securitySlice.reducer;
