import { AppContext } from "@/lib/contexts/App.context";
import { formatDate } from "@/lib/library";
import { useContext } from "react";


interface Props {
  name: string;
  value: string;
  className: string;
}

export default function ProfileDetails() {
  const { user } = useContext(AppContext);

  return (
    <>
      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="First Name"
          value={user?.firstName  ? user?.firstName : "-"}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="Last Name"
          value={user?.lastName ?? "-"}
          className="md:w-60 w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Middle Name"
          value={user?.middleName ?? "-"}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="Email address"
          value={user?.emailAddress1 ?? "-"}
          className="md:w-60 w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo name="Gender" value={user?.sex ?? "-"} className="md:w-60 w-full" />
        <ProfileInfo
          name="Title"
          value={user?.title ?? "-"}
          className="md:w-60 w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Date of Birth"
          value={formatDate(user?.birthDate)}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="Cell Phone"
          value={user?.cellPhone ?? "-"}
          className="md:w-60 w-full"
        />
      </div>

      <div className="text-neutral-400 dark:text-[#ffffff] text-base font-semibold capitalize">
        Contact Address
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Address"
          value={user?.primaryAddress1 ?? "-"}
          className="md:w-[495px] w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Local Government Area"
          value={user?.primaryCity ?? "-"}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="State"
          value={user?.primaryState ?? "-"}
          className="md:w-60 w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Country"
          value={user?.primaryCountry  ? user?.primaryCountry : "-"}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="Postal Code"
          value={user?.primaryPostCode ?? "-"}
          className="md:w-60 w-full"
        />
      </div>


      <div className="text-neutral-400 dark:text-[#ffffff]  text-base font-semibold capitalize">
        Next of kin
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Name"
          value={user?.nexofKin ?? "-"}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="Email address"
          value={user?.nexofKinEmailAddress ?? "-"}
          className="md:w-60 w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Address"
          value={user?.nextofKinAddress ?? "-"}
          className="md:w-[495px] w-full"
        />
      </div>

      <div className="flex justify-between items-center gap-4 w-full md:w-auto">
        <ProfileInfo
          name="Phone"
          value={user?.nextofKinPhone ?? "-"}
          className="md:w-60 w-full"
        />
        <ProfileInfo
          name="Relationship"
          value={user?.nextofKinRelationship ?? "-"}
          className="md:w-60 w-full"
        />
      </div>
    </>
  );
}

function ProfileInfo({ name, value, className }: Props) {
  return (
    <div
      className={` flex-col justify-start items-start gap-2 inline-flex ${className} `}>
      <div className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize">
        {name}
      </div>
      <div className="w-full px-4 py-5 rounded-md border border-stone-950 border-opacity-10 justify-between items-center inline-flex">
        <div className="text-stone-950 dark:text-[#f3f3f3] text-opacity-30 text-sm font-medium capitalize">
          {value}
        </div>
      </div>
    </div>
  );
}
