import Zenith from "@/assets/images/banks/zenith.svg";
import GTB from "@/assets/images/banks/gtb.svg";
import FirstBank from "@/assets/images/banks/first_bank.svg";
import AccessBank from "@/assets/images/banks/access_bank.svg";
import FidelityBank from "@/assets/images/banks/fidelity_bank.svg";
import WemaBank from "@/assets/images/banks/wema.svg";

const banks = [
  {
    title: "Zenith",
    image: Zenith,
    bank: "1014048866",
  },
  {
    title: "GTB",
    image: GTB,
    bank: "0149543092",
  },
  {
    title: "First Bank",
    image: FirstBank,
    bank: "2025196348",
  },
  {
    title: "Access Bank",
    image: AccessBank,
    bank: "0690035760",
  },
  {
    title: "Fidelity Bank",
    image: FidelityBank,
    bank: "4010926009",
  },
  {
    title: "Wema Bank",
    image: WemaBank,
    bank: "0122805303",
  },
];

export default banks;
