import SelectSearch, { SelectedOptionValue } from "react-select-search";
import Search from "@/lib/common/icons/search";
import { SelectGroupProps } from "@/lib/types/global";

interface Props {
  onChange: (value: SelectedOptionValue | SelectedOptionValue[]) => void;
  value?: string | string[] | undefined;
  label?: string;
  options: SelectGroupProps[];
}
function Autocomplete({ label, onChange, value, options }: Props) {
  return (
    <div>
      {label && (
        <label
          className={
            "transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5"
          }>
          {label}
        </label>
      )}

      <div className="relative flex items-center ">
        <SelectSearch
          value={value}
          placeholder="Select"
          emptyMessage={<div className="pt-2">No option is available</div>}
          search
          autoComplete="on"
          onChange={(r) => onChange(r)}
          options={options}
          onBlur={() => {
            /* No-op for now */
          }}
          onFocus={() => {
            /* No-op for now */
          }}
        />
        <span className="z-52 absolute right-4 inset-y-0 flex items-center pointer-events-none">
          <Search className="text-black" />
        </span>
      </div>
    </div>
  );
}

export default Autocomplete;
