import { useState } from "react";
import MarketDataType from "../../../layout/component/TabDataType";
import MarketUpdate from "./MarketUpdate";
import types from "@/lib/utils/market-data-type.json";

function MarketData() {
  const [type, setType] = useState<string | undefined>("TOP_PERCENT_GAINER");
  return (
    <div className="mb-6 p-0 bg-white rounded-lg border border-stone-950 border-opacity-10">
      <div className="p-4 lg:p-8">
        <div className="flex flex-col lg:flex-row gap-4 items-center justify-start lg:justify-between w-full">
          <h3 className="text-stone-950 text-sm md:text-base font-bold capitalize pr-20">
            Market Updates
          </h3>
          <MarketDataType types={types} onChange={(r) => setType(r)} />
        </div>
      </div>
      <div className="pb-5">
        <MarketUpdate type={type} />
      </div>
    </div>
  );
}

export default MarketData;
