import types from "@/lib/utils/stock-update-data.json";
import TabDataType from "../layout/component/TabDataType";
import SearchSelect from "@/lib/common/components/select/SearchSelect";
import { SelectGroupProps } from "@/lib/types/global";
import SecurityChart from "../order/components/SecurityChart";
import { useSearch } from "@tanstack/react-router";
import useSecuritySWR from "@/lib/hook/useSecuritySWR";
import { useEffect, useState } from "react";
import CardTemplate from "../dashboard/components/card";
import BidOfferTable from "../order/components/BidOfferTable";
import { formatNum } from "@/lib/library";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import { Security } from "@/lib/services/class/security";
import securityOperations from "@/lib/store/features/security/securityOperations";
import securitySelector from "@/lib/store/features/security/securitySelector";
import {ThunkDispatch} from "@reduxjs/toolkit";
type SearchField = {
  symbol: string;
};

function StockUpdate() {
  const { symbol }: SearchField = useSearch({
    strict: false,
  });
  const { data, handleChangeSymbol, isLoading } = useSecuritySWR();
  const [type, setType] = useState<string | undefined>("Straight_line");

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  
  const securityData = useSelector<RootState, Security[]>(
    securitySelector.getSecurities
  );

  useEffect(() => {
    dispatch(securityOperations.fetchSecurity());
  }, [dispatch]);


  const getGroupedData = () => {
    const res: SelectGroupProps[] = [];
    for (let i = 0; i < securityData.length; i++) {
      const item = securityData[i];
      if (item.type !== "GO") {
        res.push({ name: item.name, value: item.name });
      }
    }

    return res;
  };

  useEffect(() => {
    if (symbol) {
      handleChangeSymbol(symbol);
    }
  }, []);

  return (
    <div className="w-full">
      <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-0">
        <div className="col-span-9">
          <CardTemplate showBorder={false} className="p-4">
            <div className="mb-6 flex-col md:flex-row flex items-start gap-6 md:gap-0 md:items-center md:justify-between gap-4">
              <div className="md:w-36 w-full">
                <SearchSelect
                  label="Security"
                  options={getGroupedData()}
                  onChanging={(symbol) => {
                    handleChangeSymbol(symbol.toString());
                  }}
                />
              </div>

              <TabDataType types={types} onChange={(r) => setType(r)} />
            </div>

            <SecurityChart
              symbol={data != undefined && data.data ? data.data.symbol : ''}
              type={type}
            />
          </CardTemplate>
        </div>
        <div className="col-span-3">
          <CardTemplate showBorder={false}>
            <table className="w-full">
              <tbody>
                <TableTr
                  name="Last Price"
                  value={formatNum(
                    data != undefined && data.data ? data.data.lastPx : 0,
                    2
                  )}
                />
                <TableTr
                  name="Quantity Traded"
                  value={formatNum(
                    data != undefined && data.data ? data.data.lastQty : 0,
                    0
                  )}
                />
                <TableTr
                  name="Price Change"
                  value={formatNum(
                    data != undefined && data.data
                      ? data.data.netChgPrevDay
                      : 0,
                    2
                  )}
                />
                <TableTr
                  name="Value Traded"
                  value={formatNum(
                    data != undefined && data.data ? data.data.valTraded : 0,
                    2
                  )}
                />
                <TableTr
                  name="Volume Traded"
                  value={formatNum(
                    data != undefined && data.data ? data.data.volTraded : 0,
                    0
                  )}
                />
                <TableTr
                  name="Best Bid Price"
                  value={formatNum(
                    data != undefined && data.data ? data.data.bestBidPx : 0,
                    2
                  )}
                />
                <TableTr
                  name="Best Bid Quantity"
                  value={formatNum(
                    data != undefined && data.data ? data.data.bestBidQty : 0,
                    0
                  )}
                />
                <TableTr
                  name="Best Offer Price"
                  value={formatNum(
                    data != undefined && data.data ? data.data.bestOfferPx : 0,
                    2
                  )}
                />
                <TableTr
                  name="Best Offer Quantity"
                  value={formatNum(
                    data != undefined && data.data ? data.data.bestOfferQty : 0,
                    0
                  )}
                />
              </tbody>
            </table>
          </CardTemplate>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-0">
        <CardTemplate title="Offers" showBorder={false}>
          <BidOfferTable
            data={data != undefined && data.data ? data.data.offers : []}
            isLoading={isLoading}
            action="BUY"
            symbol={data != undefined && data.data ? data.data.symbol : ''}
          />
        </CardTemplate>
        <CardTemplate title="Bids" showBorder={false}>
          <BidOfferTable
            data={data != undefined && data.data ? data.data.bids : []}
            isLoading={isLoading}
            action="SELL"
            symbol={data != undefined && data.data ? data.data.symbol : ''}
          />
        </CardTemplate>
      </div>
    </div>
  );
}

export default StockUpdate;

interface Props {
  name: string;
  value: string | number;
}
function TableTr({ name, value }: Props) {
  return (
    <tr className="border border-stone-950 border-opacity-5 ">
      <td className="px-3 py-[17.4px] w-1/2 text-neutral-700 text-sm font-normal leading-tight">
        {name}
      </td>
      <td className="px-3 py-[17.4px] w-1/2  ext-stone-950 text-sm font-medium leading-normal">
        {value}
      </td>
    </tr>
  );
}
