import { useContext, useEffect, useState } from "react";

import { useNavigate, useSearch } from "@tanstack/react-router";
import { AppContext } from "@/lib/contexts/App.context";
import RegisterSidebar from "./component/RegisterSidebar";
import arrowLeft from "@/assets/images/arrow-left.svg";
import { Link } from "@tanstack/react-router";
import Service from "@/lib/services/request";
import { registerSchema } from "@/lib/services/validators/profile-validator";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GetCountries, GetState } from "react-country-state-city";
import MessageModal from "../dashboard/layout/component/modal/MessageModal";
import Button from "@/lib/common/components/button";
import clsx from "clsx";
import AccountTypeWidget from "./register_old/components/AccountTypeWidget";
import Input from "@/lib/common/components/input";
import SearchSelect from "@/lib/common/components/select/SearchSelect";
import DateSingle from "@/lib/common/components/date";
import { ErrorProps } from "@/lib/types/error";
import moment from "moment";
import { convertFileToBase64 } from "@/lib/library";

type SearchField = {
  referalCode: string;
};
interface FormProps {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  portalUserName: string;
  portalPassword: string;
  emailAddress1: string;
  sex?: string;
  nexofKin: string;
  nextofKinPhone: string;
  motherMaidenName?: string;
  birthDate?: Date;

  primaryAddress1: string;
  primaryCity: string;
  primaryState: string;
  primaryCountry: string;

  cellPhone: string;
  partnerType: string;
  referalCode?: string;

  bvnCode: string;
  setttlementBankAccountNumber: string;
  setttlementBankName: string;
  setttlementBankAccountName: string;
  title?: string;
  passport?: FileList;
  identifier?: string;
}

export default function Register() {
  const navigate = useNavigate();
  const { token } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      navigate({ to: "/dashboard" });
    }
  });

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [accountType, setAccountType] = useState<string>("INDIVIDUAL");

  const { referalCode }: SearchField = useSearch({
    strict: false,
  });

  // referalCode
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);

  useEffect(() => {
    GetCountries().then((result: any) => {
      setCountriesList(result);
    });
  }, []);
  useEffect(() => {
    if (referalCode) {
      setValue("referalCode", referalCode);
    }
  }, [referalCode]);

  useEffect(() => {
    if (accountType) {
      setValue("partnerType", accountType);
    }
  }, [accountType]);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    const validateIser = await Service.get(
      Service.ValidateUsername + "?portalUserName=" + da.portalUserName
    );

    if (validateIser.data.isAvaliable) {
      try {
        const pictureList = getValues("passport") as FileList;

        let picture = "";
        let pictureMimeType = "";
        let pictureFileName = "";

        if (pictureList?.length > 0) {
          const file = pictureList[0];
          picture = await convertFileToBase64(file);
          pictureFileName = file.name;
          pictureMimeType = file.type;
        }

        const formData = {
          picture,
          pictureMimeType,
          pictureFileName,
          channel: "WEB",
          firstName: da.firstName,
          middleName: da.middleName,
          lastName: da.lastName,
          portalUserName: da.portalUserName,
          portalPassword: da.portalPassword,
          emailAddress1: da.emailAddress1,
          sex: da.sex,
          title: da.title,
          nexofKin: da.nexofKin,
          nextofKinPhone: da.nextofKinPhone,
          motherMaidenName: da.motherMaidenName,
          birthDate: moment(da.birthDate).format("YYYY-MM-DD") ?? "",
          primaryAddress1: da.primaryAddress1,
          primaryCity: da.primaryCity,
          primaryState: da.primaryState,
          primaryCountry: da.primaryCountry,
          cellPhone: da.cellPhone,
          partnerType: da.partnerType,
          referalCode: da.referalCode,
          bvnCode: da.bvnCode,
          setttlementBankAccountNumber: da.setttlementBankAccountNumber ?? "",
          setttlementBankName: da.setttlementBankName,
          setttlementBankAccountName: da.setttlementBankAccountName ?? "",
          identifier: da.identifier,
        };

        await Service.post(Service.Register, formData);
        localStorage.setItem(Service.StoreRegisterUser, "");
        setIsOpen(true);
      } catch (error: unknown) {
        if (error) {
          toast((error as ErrorProps).message, {
            type: "error",
          });
        }
      }
    } else {
      toast("Username already in use. Please choose a different name", {
        type: "error",
      });
    }

    setLoading(false);
    return null;
  };

  const isMoreThanSeven = () => {
    return password && password.length > 7 ? true : false;
  };

  const hasNumber = () => {
    return /\d/.test(password);
  };

  const hasUpperCase = () => {
    return /\p{Lu}/u.test(password);
  };

  const hasLowerCase = () => {
    return /[a-z]/g.test(password);
  };

  const hasSpecialCharacter = () => {
    return /[@#$%!]+/.test(password);
  };

  return (
    <>
      {isOpen && (
        <MessageModal
          title="Congratulation!"
          description="<p className='mb-2'>Your account has been created successfully.</p> <p>A CSCS account will be created within 24-48 hours to enable you place trades.</p>"
          actions={
            <Link to="/auth/login">
              <Button variant="primary-outline">Log in to your account</Button>
            </Link>
          }
        />
      )}
      <div className="p-4 grid lg:grid-cols-5  grid-cols-1 lg:gap-8 md:gap-12 gap-0">
        <RegisterSidebar />
        <div className="lg:col-span-3">
          <div className="pt-8 md:pt-16 px-8 md:px-24 lg:px-32">
            <div className="flex flex-col justify-start items-start gap-8">
              <Link
                to="/auth/login"
                className="w-10 h-10 p-2 bg-green-900 bg-opacity-0 rounded border justify-center items-center flex"
              >
                <div className="grow shrink basis-0 self-stretch justify-center items-center inline-flex">
                  <img src={arrowLeft} alt="" />
                </div>
              </Link>

              <div className="flex-col justify-center items-start gap-4 inline-flex">
                <div className=" text-stone-950 dark:text-[#f3f3f3] text-[32px] font-semibold">
                  Sign up to begin trading
                </div>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col pt-3 md:pt-8 w-full aaalg:w-[430px]"
              >
                <div>
                  <label className="transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5 font-semibold">
                    Account Type *
                  </label>

                  <AccountTypeWidget
                    accountType={accountType}
                    onClick={(str) => {
                      setValue("partnerType", str);
                      setAccountType(str);
                    }}
                  />
                </div>

                <div className="pt-4">
                  <Input
                    {...register("passport")}
                    errors={errors.passport}
                    required
                    type="file"
                    label="Passport Photograph"
                  />
                </div>
                <div className="pt-4">
                  <Input
                    {...register("portalUserName")}
                    errors={errors.portalUserName}
                    required
                    type="text"
                    label="Username"
                  />
                </div>
                <div className="pt-4">
                  <Input
                    {...register("portalPassword")}
                    onChange={(e) => {
                      setPassword(e.target.value ?? "");
                    }}
                    required
                    type="password"
                    label="New Password"
                    errors={errors.portalPassword}
                  />
                  <p className="py-2">
                    <span className="font-bold">* At least:</span>{" "}
                    <span
                      className={clsx(``, {
                        " text-green-900 font-bold": isMoreThanSeven(),
                        "text-grey-600": !isMoreThanSeven(),
                      })}
                    >
                      8 characters
                    </span>
                    ,{" "}
                    <span
                      className={clsx(``, {
                        " text-green-900 font-bold": hasNumber(),
                        "text-grey-600": !hasNumber(),
                      })}
                    >
                      1 numbers
                    </span>
                    ,{" "}
                    <span
                      className={clsx(``, {
                        " text-green-900 font-bold": hasUpperCase(),
                        "text-grey-600": !hasUpperCase(),
                      })}
                    >
                      1 upper
                    </span>
                    ,{" "}
                    <span
                      className={clsx(``, {
                        " text-green-900 font-bold": hasLowerCase(),
                        "text-grey-600": !hasLowerCase(),
                      })}
                    >
                      1 lower
                    </span>
                    ,{" "}
                    <span
                      className={clsx(``, {
                        " text-green-900 font-bold": hasSpecialCharacter(),
                        "text-grey-600": !hasSpecialCharacter(),
                      })}
                    >
                      1 special characters - *[@#$%!
                    </span>
                  </p>
                </div>

                {accountType === "INDIVIDUAL" && (
                  <>
                    <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                      <SearchSelect
                        {...register("title")}
                        errors={errors.title}
                        required
                        defaultValue={getValues("title")}
                        label="Title"
                        options={[
                          {
                            value: "Mr.",
                            name: "Mr.",
                          },
                          {
                            value: "Mrs.",
                            name: "Mrs.",
                          },
                          {
                            value: "Miss.",
                            name: "Miss.",
                          },
                          {
                            value: "Ms.",
                            name: "Ms.",
                          },
                          {
                            value: "Dr.",
                            name: "Dr.",
                          },
                          {
                            value: "Prof.",
                            name: "Prof.",
                          },
                        ]}
                        onChanging={(r) => {
                          setValue("title", r.toString());
                        }}
                      />
                    </div>
                    <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                      <Input
                        {...register("firstName")}
                        errors={errors.firstName}
                        required
                        label="First name"
                      />
                      <Input
                        {...register("middleName")}
                        errors={errors.middleName}
                        label="Middle name"
                      />
                    </div>

                    <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                      <Input
                        {...register("lastName")}
                        errors={errors.lastName}
                        required
                        label="Surname"
                      />

                      <Input
                        {...register("motherMaidenName")}
                        errors={errors.motherMaidenName}
                        required
                        label="Mother Maiden Name"
                      />
                    </div>

                    <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                      <Input
                        {...register("emailAddress1")}
                        errors={errors.emailAddress1}
                        required
                        label="Email Address"
                      />

                      <Input
                        {...register("cellPhone")}
                        errors={errors.cellPhone}
                        required
                        label="Mobile number"
                      />
                    </div>

                    <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                      <SearchSelect
                        {...register("sex")}
                        errors={errors.sex}
                        label="Gender"
                        required
                        defaultValue={getValues("sex")}
                        options={[
                          {
                            value: "MALE",
                            name: "Male",
                          },
                          {
                            value: "FEMALE",
                            name: "Female",
                          },
                        ]}
                        onChanging={(r) => {
                          setValue("sex", r.toString());
                        }}
                      />

                      <DateSingle
                        defaultValue={""}
                        required
                        {...register("birthDate")}
                        errors={errors.birthDate}
                        label="Date of birth"
                        onChanging={(r: Date | undefined) => {
                          setValue("birthDate", r);
                        }}
                      />
                    </div>
                  </>
                )}

                {accountType === "ORGANIZATION" && (
                  <>
                    <div className="pt-4">
                      <Input
                        {...register("identifier")}
                        errors={errors.identifier}
                        required
                        label="R/C Number"
                      />
                    </div>

                    <div className="pt-4">
                      <Input
                        {...register("lastName")}
                        errors={errors.lastName}
                        required
                        label="Company Name"
                      />
                    </div>

                    <div className="pt-4">
                      <DateSingle
                        {...register("birthDate")}
                        defaultValue={""}
                        errors={errors.birthDate}
                        label="Date of Registration"
                        onChanging={(r: Date | undefined) => {
                          setValue("birthDate", r);
                        }}
                      />
                    </div>

                    <div className="pt-4">
                      <Input
                        {...register("middleName")}
                        errors={errors.middleName}
                        required
                        label="Contact Person Name"
                      />
                    </div>

                    <div className="pt-4">
                      <Input
                        {...register("cellPhone")}
                        errors={errors.cellPhone}
                        required
                        label="Contact Person Number"
                      />
                    </div>

                    <div className="pt-4">
                      <Input
                        {...register("emailAddress1")}
                        errors={errors.emailAddress1}
                        required
                        label="Email Address"
                      />
                    </div>
                  </>
                )}

                <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                  <Input
                    {...register("referalCode")}
                    errors={errors.referalCode}
                    label="Referal Code"
                  />
                </div>
                <div className="py-7 flex-col justify-center items-start gap-4 inline-flex">
                  <div className=" text-stone-950 dark:text-[#f3f3f3] text-[16px] font-semibold">
                    Next of Kin Information
                  </div>
                </div>

                <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                  <Input
                    {...register("nexofKin")}
                    errors={errors.nexofKin}
                    required
                    type="text"
                    label="Next of Kin"
                  />

                  <Input
                    {...register("nextofKinPhone")}
                    required
                    type="text"
                    label="Next of Kin Phone"
                    errors={errors.nextofKinPhone}
                  />
                </div>

                <div className="py-7 flex-col justify-center items-start gap-4 inline-flex">
                  <div className=" text-stone-950 dark:text-[#f3f3f3] text-[16px] font-semibold">
                    Contact Information
                  </div>
                </div>

                <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                  <Input
                    {...register("primaryAddress1")}
                    errors={errors.primaryAddress1}
                    required
                    type="text"
                    label="Address"
                  />

                  <SearchSelect
                    errors={errors.primaryCountry}
                    label="Country"
                    required
                    defaultValue={getValues("primaryState")}
                    options={countriesList.map((item: any) => {
                      return { name: item.name, value: item.name };
                    })}
                    onChanging={(r: any) => {
                      setValue("primaryCountry", r.toString());

                      const country: any = countriesList.find(
                        (row: any) => row.name === r
                      );
                      if (country) {
                        GetState(country.id).then((result: any) => {
                          setStateList(result);
                        });
                      }
                    }}
                  />
                </div>

                <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                  <SearchSelect
                    errors={errors.primaryState}
                    label="State"
                    required
                    defaultValue={getValues("primaryState")}
                    options={stateList.map((item: any) => {
                      return { name: item.name, value: item.name };
                    })}
                    onChanging={(r: any) => {
                      setValue("primaryState", r.toString());
                    }}
                  />

                  <Input
                    {...register("primaryCity")}
                    errors={errors.primaryCity}
                    required
                    label="LGA/City"
                  />
                </div>

                <div className="py-7 flex-col justify-center items-start gap-4 inline-flex">
                  <div className=" text-stone-950 dark:text-[#f3f3f3] text-[16px] font-semibold">
                    Bank Information
                  </div>
                </div>

                <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                  <SearchSelect
                    {...register("setttlementBankName")}
                    errors={errors.setttlementBankName}
                    defaultValue={getValues("setttlementBankName")}
                    label="Bank Name"
                    required
                    options={[
                      // { name: "Afribank", value: "Afribank" },
                      // { name: "Bank PHB", value: "Bank PHB" },
                      { name: "Access Bank", value: "Access Bank" },

                      { name: "Citibank", value: "Citibank" },

                      { name: "Diamond Bank", value: "Diamond Bank" },
                      { name: "Ecobank", value: "Ecobank" },
                      {
                        name: "Equitorial Trust Bank",
                        value: "Equitorial Trust Bank",
                      },
                      { name: "Finbank", value: "Finbank" },
                      {
                        name: "FBNQuest merchant bank",
                        value: "FBNQuest merchant bank",
                      },
                      {
                        name: "First City Monument Bank",
                        value: "First City Monument Bank",
                      },

                      // FBNQUEST MERCHANT BANK

                      {
                        name: "First Bank of Nigeria",
                        value: "First Bank of Nigeria",
                      },
                      //
                      { name: "Fidelity Bank", value: "Fidelity Bank" },
                      { name: "FSDH", value: "FSDH" },
                      {
                        name: "Globus Bank",
                        value: "Globus Bank",
                      },

                      {
                        name: "Greenwich Merchant Bank",
                        value: "Greenwich Merchant Bank",
                      },

                      {
                        name: "Guarantee Trust Bank",
                        value: "Guarantee Trust Bank",
                      },

                      {
                        name: "Heritage Bank",
                        value: "Heritage Bank",
                      },
                      {
                        name: "HopePSB",
                        value: "HopePSB",
                      },
                      {
                        name: "Jaiz Bank",
                        value: "Jaiz Bank",
                      },
                      {
                        name: "Keystone Bank",
                        value: "Keystone Bank",
                      },
                      {
                        name: "Kuda Microfinance Bank",
                        value: "Kuda Microfinance Bank",
                      },
                      {
                        name: "Lotus Bank",
                        value: "Lotus Bank",
                      },
                      {
                        name: "Nova MB",
                        value: "Nova MB",
                      },
                      {
                        name: "Opay",
                        value: "Opay",
                      },
                      {
                        name: "Palmpay",
                        value: "Palmpay",
                      },
                      {
                        name: "Polaris Bank",
                        value: "Polaris Bank",
                      },
                      {
                        name: "Providus Bank",
                        value: "Providus Bank",
                      },
                      {
                        name: "Premium Trust",
                        value: "Premium Trust",
                      },
                      {
                        name: "Rand Merchant Bank",
                        value: "Rand Merchant Bank",
                      },

                      { name: "Stanbic IBTC Bank", value: "Stanbic IBTC Bank" },

                      {
                        name: "Standard Chartered Bank",
                        value: "Standard Chartered Bank",
                      },
                      {
                        name: "Sterling Bank",
                        value: "Sterling Bank",
                      },
                      {
                        name: "SunTrust Bank",
                        value: "SunTrust Bank",
                      },
                      {
                        name: "Taj Bank",
                        value: "Taj Bank",
                      },
                      {
                        name: "Titan Trust Bank",
                        value: "Titan Trust Bank",
                      },

                      { name: "Union Bank", value: "Union Bank" },
                      {
                        name: "United Bank for Africa",
                        value: "United Bank for Africa",
                      },

                      { name: "Unity Bank", value: "Unity Bank" },
                      { name: "WEMA Bank", value: "WEMA Bank" },
                      { name: "Zenith Bank", value: "Zenith Bank" },

                      // { name: "Others", value: "Others" },
                    ]}
                    onChanging={(r) => {
                      setValue("setttlementBankName", r.toString());
                    }}
                  />

                  <Input
                    {...register("setttlementBankAccountName")}
                    errors={errors.setttlementBankAccountName}
                    required
                    label="Account name"
                  />
                </div>
                <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
                  <Input
                    {...register("setttlementBankAccountNumber")}
                    errors={errors.setttlementBankAccountNumber}
                    required
                    label="Account number"
                  />

                  <Input
                    {...register("bvnCode")}
                    errors={errors.bvnCode}
                    required
                    type="text"
                    label="BVN"
                  />
                </div>

                <div className="py-10">
                  <Button
                    className="w-full"
                    disabled={loading}
                    isLoading={loading}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
