import { formatMoney } from "@/lib/library";

interface Props {
  title: string;
  amount: number;
}
function DashAccountSummary(props: Props) {
  return (
    <div className="p-4 bg-white rounded-lg border border-stone-950 border-opacity-10 flex-col justify-start items-start gap-4 inline-flex">
      <div className="text-neutral-500 text-xs font-medium capitalize">
        {props.title}
      </div>
      <div className=" text-stone-950 dark:text-[#f3f3f3] dark:text-[#f3f3f3] text-base  md:text-lg font-semibold capitalize">
        {formatMoney(props.amount)}
      </div>
    </div>
  );
}

export default DashAccountSummary;
