import { Watchlist } from '@/lib/services/class/watchlist';
import Service from '@/lib/services/request';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchWatchlists = createAsyncThunk<Watchlist[]>('watchlist/fetching', async () => {
  try {
    const { data } = await Service.get(Service.WatchlistList);

    return data;
  } catch (error) {
    throw  error;
  }
});

const createWathclist = createAsyncThunk<Watchlist, any>('watchlist/add', async (watchlist: Watchlist) => {
  try {
    const { data } = await Service.post(Service.WatchlistList, watchlist);
    return data;
  } catch (error) {
    throw error;
  }
});

const deleteWatchlist = createAsyncThunk<Watchlist, number>('watchlist/del', async (wathclistId: number) => {
  try {
    const { data } = await Service.delete(`${Service.WatchlistList}/${wathclistId}`);
    return data;
  } catch (error) {
    throw error;
  }
});

const updateWatchlist = createAsyncThunk<Watchlist, Watchlist>('watchlist/update', async (watchlist: Watchlist) => {
  try {
    const { data } = await Service.put(`/watchlist/${watchlist.id}`, watchlist);
    return data;
  } catch (error) {
    throw error;
  }
});

export default { fetchWatchlists, createWathclist, deleteWatchlist, updateWatchlist };