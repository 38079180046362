import { formatMoney } from "@/lib/library";

interface Props {
  availableBalance: number;
  totalBalance: number;
  marginPowerBalance: number;
}

function AccountBalance({
  availableBalance,
  totalBalance,
  marginPowerBalance,
}: Props) {
  return (
    <div className="mb-6 p-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
      <div className=" divide-x divide-stone-950 divide-opacity-10 grid grid-cols-2 md:gap-6 gap-3">
        <div className="flex-col justify-center items-start gap-2 inline-flex">
          <div className="text-neutral-500 text-xs font-medium capitalize">
            Available Balance
          </div>
          <div className="text-black text-base font-semibold">
            {formatMoney(availableBalance)}
          </div>
        </div>
        <div className="pl-5  text-start flex-col justify-center items-start gap-2 inline-flex">
          <div className="text-neutral-500 text-xs font-medium capitalize">
            Total Balance
          </div>
          <div className="text-black text-base font-semibold">
            {formatMoney(totalBalance)}
          </div>
        </div>
      </div>

      {marginPowerBalance > 0 && (
        <div className="pt-6  flex justify-between items-center">
          <div className="flex-col justify-center items-start gap-2 inline-flex">
            <div className="text-neutral-500 text-xs font-medium capitalize">
              Margin Purchasing power
            </div>
            <div className="text-black text-base font-semibold">
              {formatMoney(marginPowerBalance)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountBalance;
