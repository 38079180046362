"use client";

import React, { useState } from "react";
import { getMarketTime } from "../library";

interface ModalContextInterface {
  setTradingTime: React.Dispatch<React.SetStateAction<boolean>>;
  tradingTime: boolean;

  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  symbol: string;

  setWatchlistModal: React.Dispatch<React.SetStateAction<boolean>>;
  watchlistModal: boolean;

  setNotification: React.Dispatch<React.SetStateAction<boolean>>;
  notification: boolean;
}

interface ModalProviderProps {
  children?: React.ReactNode;
}

export const ModalContext = React.createContext({} as ModalContextInterface);

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const marketPer = getMarketTime();

  const [tradingTime, setTradingTime] = useState<boolean>(marketPer.isActive);
  const [watchlistModal, setWatchlistModal] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<string>("");
  const [notification, setNotification] = useState<boolean>(false);

  const values = {
    setTradingTime,
    tradingTime,
    setWatchlistModal,
    watchlistModal,

    setSymbol,
    symbol,

    notification,
    setNotification,
  };
  return (
    <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
  );
};
