import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import useSWR from "swr";
import Service from "@/lib/services/request";
import { useEffect, useState } from "react";
import { SecContent } from "@/lib/types/global";

interface Props {
  symbol?: string;
  type?: string;
  mutliple?: SecContent[];
}

interface LineProp {
  name: string;
  data: string;
  tooltip: {
    valueDecimals: number;
  };
}

const SecurityChart = (props: Props) => {
  const [serials, setSerials] = useState<LineProp[]>([]);
  useEffect(() => {
    async function getData() {
      if (props.mutliple) {
        const seriesMembers: LineProp[] = [];
        for (let i = 0; i < props.mutliple?.length; i++) {
          const data = await Service.get(
            `/securityOverview/${props.mutliple[i].name}`
          );
          seriesMembers.push({
            name: data != undefined && data.data ? data.data.symbol : "",
            data: data != undefined && data.data ? data.data.chartData : [],
            tooltip: {
              valueDecimals: 2,
            },
          });
        }
        setSerials(seriesMembers);
      }
    }

    getData();
  }, []);


  const getPlotOption = () => {
    return {
      area: {
        fillColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, "#dbe0dc"],
            [1, "#ffffff"],
          ],
        },

        lineColor: "#285131",
        lineWidth: 1,
        marker: {
          enabled: null,
          radius: 2,
          lineWidth: 1,
          lineColor: "#285131",
        },
        shadow: false,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    };
  };

  const getOptionValue = () => {
    if (props.type == "compare") {
      return {
        credits: false,

        series: serials,

      };
    } else if (props.type == "pointedMarker") {
      const { data, isLoading } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        rangeSelector: {
          inputEnabled: false,
        },

       
        credits: false,
        loading: isLoading,

        title: {
          text: props.symbol + " POINTED MARKER",
        },

        series: [
          {
            name: props.symbol + " POINTED MARKER",
            data: data != undefined && data.data ? data.data.chartData : [],
            lineWidth: 0,
            marker: {
              enabled: true,
              radius: 2,
            },
            tooltip: {
              valueDecimals: 2,
            },
            states: {
              hover: {
                lineWidthPlus: 0,
              },
            },
          },
        ],
      };
    } else if (props.type == "stepLine") {
      const { data } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        rangeSelector: {
          inputEnabled: false,
        },

        

        title: {
          text: props.symbol + " STEP LINE",
        },

        series: [
          {
            name: props.symbol + " STEP LINE",
            data: data != undefined && data.data ? data.data.chartData : [],
            step: true,
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],
      };
    } else if (props.type == "volume") {
      const { data, isLoading } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        credits: false,
        loading: isLoading,

        chart: {
          alignTicks: false,
        },

        rangeSelector: {
          inputEnabled: false,
        },

        
        title: {
          text: props.symbol + " VOLUME CHART",
        },

        series: [
          {
            type: "column",
            name: props.symbol + "Stock Volume",
            data: data != undefined && data.data ? data.data.chartData : [],
            dataGrouping: {
              units: [
                ["week", [1]],
                ["month", [1, 2, 3, 4, 6]],
              ],
            },
          },
        ],
      };
    } else if (props.type == "ohlc") {
      const { data, isLoading } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        // rangeSelector: {
        //   selected: 2,
        // },
        credits: false,
        loading: isLoading,

        title: {
          text: props.symbol + " OHLC CHART",
        },
        rangeSelector: {
          inputEnabled: false,
        },

        
        series: [
          {
            type: "ohlc",
            name: props.symbol + " OHLC CHART",
            data: data != undefined && data.data ? data.data.chartData : [],
            dataGrouping: {
              units: [
                ["week", [1]],
                ["month", [1, 2, 3, 4, 6]],
              ],
            },
          },
        ],
      };
    } else if (props.type == "area") {
      const { data, isLoading } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        title: {
          text: props.symbol + " AREA CHART",
        },
        credits: false,
        loading: isLoading,

        xAxis: {
          gapGridLineWidth: 0,
        },

        rangeSelector: {
          inputEnabled: false,
        },

        series: [
          {
            name: props.symbol + " AREA CHART",
            type: "area",
            data: data != undefined && data.data ? data.data.chartData : [],
            gapSize: 5,
            tooltip: {
              valueDecimals: 2,
            },
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              // stops: [
              //     [0, Highcharts.getOptions().colors[0]],
              //     [1, Highcharts.Color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              // ]
            },
            threshold: null,
          },
        ],
      };
    } else if (props.type == "candle") {
      const { data, isLoading } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        // rangeSelector: {
        //   selected: 1,
        // },
        credits: false,
        loading: isLoading,

        title: {
          text: props.symbol + " CANDLE STICK CHART",
        },
        rangeSelector: {
          inputEnabled: false,
        },

        
        series: [
          {
            type: "candlestick",
            name: props.symbol + " CANDLE STICK CHART",
            data: data != undefined && data.data ? data.data.chartData : [],
            dataGrouping: {
              units: [
                ["week", [1]],
                ["month", [1, 2, 3, 4, 6]],
              ],
            },
          },
        ],
      };
    } else {
      const { data, isLoading } = useSWR(
        props.symbol ? `/securityOverview/${props.symbol}` : null
      );

      return {
        credits: false,
        loading: isLoading,

        plotOptions: getPlotOption(),

        series: [
          {
            name: data != undefined && data.data ? data.data.symbol : "",
            data: data != undefined && data.data ? data.data.chartData : [],
            type: "area",
            tooltip: {
              valueDecimals: 2,
            },
          },
        ],

        rangeSelector: {
          inputEnabled: false,
        },

        
      };
    }
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={getOptionValue()}
    />
  );
};
export default SecurityChart;
