/* eslint-disable no-unused-vars */
import React, { ReactNode, useState } from 'react';
import Service from '../services/request.ts';
import { User } from '../services/class/user.ts';
import DataCache from '../DataCache.ts';

interface AppContextInterface {
  token: string;
  user: User | null;
  setToken: (value: string) => void;
  setUser: (value: User | null) => void;
}
type Props = {
  children: ReactNode
}

export const AppContext = React.createContext({} as AppContextInterface);

export function AppProvider({ children }: Props) {

  const [token, setToken] = useState<string>(
    DataCache.getItem(Service.StoreToken) || '',
  );

  const [user, setUser] = useState<User | null>(
    (JSON.parse(localStorage.getItem(Service.StoreUser) || '{}') as User) || null,
  );

  const values = {
    token,
    setToken,
    user,
    setUser,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}
