import LargeLogo from "@/assets/images/logo_large.svg";
import SmallLogo from "@/assets/images/logo_small.svg";
import navigation from "@/lib/utils/main-menu.ts";
import { Link, useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { useEffect, useState } from "react";

function SidebarLayout() {
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();

  const isExist = () => {
    for (let i = 0; i < navigation.length; i++) {
      if (navigation[i].link == window.location.pathname) return true;
    }
    return false;
  };

  useEffect(() => {
    if (isExist()) {
      setActiveLink(window.location.pathname);
    } else {
      setActiveLink(navigation[0].link);
    }

    const sidebar = document.querySelector(".sidebar");
    if (sidebar) {
      sidebar.addEventListener("mouseenter", function () {
        openNav(true);
      });
      return () => {
        sidebar.addEventListener("mouseleave", function () {
          openNav(false);
        });
      };
    }
  }, []);

  function openNav(isOpen: boolean) {
    const sidebar = document.querySelector(".sidebar");
    const maxSidebar = document.querySelector(".max");
    const miniSidebar = document.querySelector(".mini");
    const maxToolbar = document.querySelector(".max-toolbar");
    const smallLogo = document.querySelector(".small-logo");

    const content = document.querySelector(".content");

    if (isOpen) {
      if (sidebar) {
        sidebar.classList.remove("-translate-x-48");
        sidebar.classList.add("translate-x-none");
      }

      if (maxSidebar) {
        maxSidebar.classList.remove("hidden");
        maxSidebar.classList.add("flex");
      }
      if (miniSidebar) {
        miniSidebar.classList.remove("flex");
        miniSidebar.classList.add("hidden");
      }
      if (maxToolbar) {
        maxToolbar.classList.add("translate-x-0");
        maxToolbar.classList.remove("translate-x-24", "scale-x-0");
      }

      if (smallLogo) {
        smallLogo.classList.remove("translate-x-0");
        smallLogo.classList.add("translate-x-24", "scale-x-0");
      }

      if (content) {
        content.classList.remove("ml-24");
        content.classList.add("ml-24", "md:ml-72");
      }
    } else {
      if (sidebar) {
        sidebar.classList.add("-translate-x-48");
        sidebar.classList.remove("translate-x-none");
      }
      if (maxSidebar) {
        maxSidebar.classList.add("hidden");
        maxSidebar.classList.remove("flex");
      }
      if (miniSidebar) {
        miniSidebar.classList.add("flex");
        miniSidebar.classList.remove("hidden");
      }
      if (maxToolbar) {
        maxToolbar.classList.add("translate-x-24", "scale-x-0");
        maxToolbar.classList.remove("translate-x-0");
      }

      if (smallLogo) {
        smallLogo.classList.remove("translate-x-24", "scale-x-0");
        smallLogo.classList.add("translate-x-0");
      }

      if (content) {
        content.classList.remove("ml-24", "md:ml-72");
        content.classList.add("ml-24");
      }
    }
  }
  return (
    <div
      onMouseEnter={() => openNav(true)}
      onMouseLeave={() => openNav(false)}
      className="hidden md:flex sidebar overflow-y-scroll overflow-x-hidden scrollbar-w-thin] scrollbar-h-[1px] scrollbar scrollbar-thumb-[#edf1ef] scrollbar-track-white scrollbar-corner-white w-72 -translate-x-48 fixed transition transform ease-in-out duration-1000 z-50  h-screen  bg-white border-r border-stone-950 border-opacity-10">
      <div className="max-toolbar translate-x-24 scale-x-0 w-full -right-6 transition transform ease-in duration-300 flex items-center justify-between   absolute top-4">
        <Link to="/">
          <img src={LargeLogo} />
        </Link>
      </div>
      <div className="small-logo right-3 transition transform ease-in-out duration-500 flex  absolute top-4 ">
        <Link to="/">
          <img src={SmallLogo} />
        </Link>
      </div>

      <div className=" max hidden text-white mt-36 flex-col space-y-2 w-full h-[calc(100vh)]">
        <ul className="space-y-2 ">
          {navigation.map(
            ({ link, title, Image, items, ImageActive }, index) => (
              <li
                role="presentation"
                onClick={() => {
                  setActiveLink(link);
                  if (!items || (items && items?.length == 0)) {
                    navigate({ to: link });
                  }
                }}
                key={index}>
                <div
                  role="presentation"
                  key={index}
                  className={clsx(
                    "cursor-pointer w-full  font-medium p-3 pl-8 transform ease-in-out duration-300 flex flex-row items-center space-x-4",
                    {
                      "text-neutral-400 text-base": activeLink !== link,
                    },
                    {
                      " text-green-900 border-y  border-opacity-20 border-green-900 bg-green-900 bg-opacity-10 ":
                        activeLink === link,
                    }
                  )}>
                  {activeLink === link ? (
                    <ImageActive className="text-green-900" />
                  ) : (
                    <Image className="text-neutral-400" />
                  )}
                  <div>{title}</div>
                </div>

                {items && items.length > 0 && (
                  <ul
                    className={clsx(
                      "pt-2",
                      {
                        hidden: activeLink !== link,
                      },
                      {
                        block: activeLink === link,
                      }
                    )}>
                    {items.map(({ link, title }, index) => (
                      <li
                        onClick={() => {
                          // setActiveLink(link);
                          navigate({ to: link });
                        }}
                        key={index}
                        className={clsx(
                          "cursor-pointer w-full h-12 pl-[72px] pr-6 py-3.5 rounded justify-start items-start gap-2.5 inline-flex",
                          {
                            "bg-green-900 bg-opacity-10 rounded ":
                              window.location.pathname == link,
                          }
                        )}>
                        <div className="justify-start items-center gap-4 flex">
                          <div
                            className={clsx(
                              "text-sm font-normal leading-tight",
                              {
                                " text-green-900 font-semibolds":
                                  window.location.pathname == link,
                              },
                              {
                                " text-neutral-400 ":
                                  window.location.pathname != link,
                              }
                            )}>
                            {title}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )
          )}
        </ul>
      </div>

      <div className="mini mt-36 flex flex-col space-y-2 w-full h-[calc(100vh)]">
        {navigation.map(({ link, title, Image, ImageActive }) => (
          <Link
            to={link}
            role="presentation"
            key={title}
            className="
          
          justify-end pr-5 text-white w-full p-1 roundedd-full transform ease-in-out duration-300 flex"
            search={{}}
            params={{}}>
            <div
              className={clsx("rounded-md  py-2 px-4", {
                "border  border-opacity-20 border-green-900 bg-green-900 bg-opacity-10 ":
                  activeLink === link,
              })}>
              {activeLink === link ? (
                <ImageActive className="text-green-900" />
              ) : (
                <Image className="text-neutral-400" />
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SidebarLayout;
