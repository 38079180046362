import Button from "@/lib/common/components/button";
import PageNavBar from "../layout/PageNavBar";
import DashAccountSummary from "./components/AccountSummary";
import CashAccountCard from "./components/CashAccountCard";

import TransactionCard from "./components/card/transaction_card";
import AssetAllocationCard from "./components/card/asset_allocation";
import SecurityChart from "../order/components/SecurityChart";
import MarketData from "./components/marketData";
import Service from "@/lib/services/request";
import useSWR from "swr";
import { Link } from "@tanstack/react-router";
import CardTemplate from "./components/card";
import IncompleteKYC from "../layout/IncompleteKYC";

function Dashboard() {
  const { data } = useSWR(Service.CustomerBalanceSummary);

  return (
    <>
      <PageNavBar title="Dashboard" showTradeNowButton={true} />

      <div className="px-6 grid lg:grid-cols-7 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-12 gap-0">
        <div className="col-span-5">
          
       
           <IncompleteKYC/>
          
          <div className="mb-6 grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 lg:gap-8  gap-4">
            <DashAccountSummary
              title="Total portfolio Valuation"
              amount={
                data != undefined && data.data ? data.data.totalPortfolio : 0
              }
            />
            <DashAccountSummary
              title="Equity"
              amount={
                data != undefined && data.data ? data.data.equityBalance : 0
              }
            />
            <DashAccountSummary
              title="NASD"
              amount={
                data != undefined && data.data ? data.data.nasdBalance : 0
              }
            />
            <DashAccountSummary
              title="MARGIN TRADING"
              amount={
                data != undefined && data.data ? data.data.marginBalance : 0
              }
            />
          </div>

          

          <div className="block md:hidden">
            <CashAccountCard
              availableBalance={
                data != undefined && data.data ? data.data.availableBalance : 0
              }
              totalCashBalance={
                data != undefined && data.data ? data.data.totalCashBalance : 0
              }
            />

            <div className="py-6 grid grid-cols-2 gap-6">
              <Link to="/dashboard/deposit" className="w-full">
                <Button className="w-full" variant="primary-grey">
                  Deposit
                </Button>
              </Link>
              <Link to="/dashboard/withdrawal" className="w-full">
                <Button className="w-full" variant="primary-grey">
                  Withdraw
                </Button>
              </Link>
            </div>

            <TransactionCard />
          </div>

       <CardTemplate title="All Share Index" showBorder={true}>
            <div className="p-3 md:p-5 ">
              <SecurityChart symbol="ASI" />
            </div>
          </CardTemplate>

          <MarketData />
        </div>
        <div className="sticky top-28 h-max col-span-2">
          <div className="hidden md:block">
            <CashAccountCard
              availableBalance={
                data != undefined && data.data ? data.data.availableBalance : 0
              }
              totalCashBalance={
                data != undefined && data.data ? data.data.totalCashBalance : 0
              }
            />

            <div className="py-6 grid grid-cols-2 gap-6">
              <Link to="/dashboard/deposit" className="w-full">
                <Button className="w-full">Deposit</Button>
              </Link>
              <Link to="/dashboard/withdrawal" className="w-full">
                <Button className="w-full" variant="primary-outline">
                  Withdraw
                </Button>
              </Link>
            </div>

            <TransactionCard />
          </div>

          <AssetAllocationCard /> 
        </div>
      </div>
    </>
  );
}

export default Dashboard;
