import React,{
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
} from "@nextui-org/react";

import NoContent from "@/lib/common/components/table/NoContent";
import {Portfolio} from "@/lib/services/class/portfolio";
import usePortfolioSWR from "@/lib/hook/usePortfolioSWR";
import {PortfolioHolding} from "@/lib/services/class/portfolio_holding";
import {formatNum,getSymbolImage} from "@/lib/library";
import {Link} from "@tanstack/react-router";
import TableCss from "@/lib/utils/table-css";
import SecurityInfo from "../../order/components/SecurityInfo";
import {ModalContext} from "@/lib/contexts/modal-context";
import {LazyLoadImage} from 'react-lazy-load-image-component';

interface Props {
  portfolio?: Portfolio;
}
export default function PortfolioTable({portfolio}: Props) {
  const {setWatchlistModal,setSymbol}=useContext(ModalContext);
  const {data,isLoading,handleChange}=usePortfolioSWR();
  const [contents, setContents] = useState<PortfolioHolding[]>([]);
  useEffect(() => {
    handleChange(portfolio?.id);
  },[handleChange,portfolio]);

  useEffect(() => {
      const resp=data!=undefined&&data.data.result? data.data.result:[];
      resp.push({
        securityId: 23323,
      })
      setContents(resp);
  }, [data])

  const renderCell=useCallback(
    (portfolio: PortfolioHolding,columnKey: React.Key) => {
      const cellValue=portfolio[columnKey as keyof PortfolioHolding];

      switch(columnKey) {
      case "securityName":
        return (
          <SecurityInfo symbol={portfolio.securityName}>
            <div className="justify-start items-center gap-2 flex">
              <LazyLoadImage
                className="w-[25px] h-[25px] rounded-full"
                src={getSymbolImage(portfolio.securityName)}
              />
              <div>{cellValue}</div>
            </div>
          </SecurityInfo>
        );
      case "marketPrice":
        return formatNum(cellValue);
      case "quantityHeld":
        return formatNum(cellValue,0);
      case "gain":
        if(portfolio&&portfolio?.gain>0) {
          return (
            <span style={{color: "green"}}>{formatNum(cellValue)}</span>
          );
        } else {
          return <span style={{color: "red"}}>{formatNum(cellValue)}</span>;
        }
      case "percentGain":
        if(portfolio&&portfolio?.gain>0) {
          return (
            <span style={{color: "green"}}>{formatNum(cellValue)}</span>
          );
        } else {
          return <span style={{color: "red"}}>{formatNum(cellValue)}</span>;
        }
      case "totalCost":
        return formatNum(portfolio.costBasis*portfolio.quantityHeld);
      case "marketValue":
        return formatNum(cellValue);
      case "costBasis":
        return formatNum(cellValue);
      case "actions":
        return (
          <div className="justify-start items-start gap-2 inline-flex">
            <Link
              to="/dashboard/orders/create"
              search={{symbol: portfolio.securityName,orderType: "BUY"}}
              className="px-3.5 py-2 bg-emerald-500 bg-opacity-10 rounded-sm ">
              <span className="text-emerald-500 text-xs font-semibold uppercase">
                BUY
              </span>
            </Link>
            <Link
              to="/dashboard/orders/create"
              search={{symbol: portfolio.securityName,orderType: "SELL"}}
              className="px-3.5 py-2 bg-red-600 bg-opacity-10 rounded-sm ">
              <span className="text-red-600 text-xs font-semibold uppercase">
                SELL
              </span>
            </Link>
            <div
              onClick={() => {
                setWatchlistModal(true);
                setSymbol(portfolio.securityName);
              }}
              className="cursor-pointer px-3.5 py-2 bg-lime-500 bg-opacity-10 rounded-sm ">
              <span className="text-lime-500 text-xs font-semibold uppercase">
                WATCHLIST
              </span>
            </div>
          </div>
        );
      default:
        return cellValue;
      }
    },
    []
  );


  const getSummation=(field: string) => {

    const result = contents.reduce(function (acc, obj) {
      if(field == "totalCost" && obj.costBasis && obj.quantityHeld){
        return acc + (obj.costBasis*obj.quantityHeld)
      }else if(field == "marketValue" &&  obj.marketValue){
        console.log(acc + obj.marketValue)
        return acc + obj.marketValue;
      }
      return acc ;
    }, 0);
    return result;
  };

  const loadingState=isLoading? "loading":"idle";

  const classNames=React.useMemo(() => TableCss,[]);

  return (
    <Table
      aria-label="Example table with client async pagination"
      classNames={classNames}

    >
      <TableHeader>
        <TableColumn key="securityName">SYMBOL</TableColumn>
        <TableColumn key="marketPrice">LAST PRICE(₦)</TableColumn>
        <TableColumn key="quantityHeld">QUANTITY</TableColumn>
        <TableColumn key="costBasis">PRICE PAID(₦)</TableColumn>
        <TableColumn key="gain">GAIN/LOSS</TableColumn>

        <TableColumn key="percentGain">%GAIN/LOSS</TableColumn>
        <TableColumn key="totalCost">TOTAL COST(₦)</TableColumn>
        <TableColumn key="marketValue">MARKET VALUE(₦)</TableColumn>
        <TableColumn key="actions">ACTIONS</TableColumn>
      </TableHeader>

      <TableBody<PortfolioHolding>
        emptyContent={<NoContent />}
        items={contents}
        loadingContent={<Spinner />}
        loadingState={loadingState}>
        {(item: PortfolioHolding) => {
          if(item.securityId==23323)
            return <TableRow key={item.securityId}>

              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell>{""}</TableCell>
              <TableCell><div className="font-bold">{formatNum(getSummation('totalCost'))}</div></TableCell>
              <TableCell><div className="font-bold">{formatNum(getSummation('marketValue'))}</div></TableCell>
              <TableCell>{""}</TableCell>


            </TableRow>
          else
            return (
              <TableRow key={item.securityId}>
                {(columnKey) => (
                  <TableCell>{renderCell(item,columnKey)}</TableCell>
                )}
              </TableRow>
            );
        }}
      </TableBody>

    </Table>
  );
}
