import { InputHTMLAttributes,useState } from "react";
import { FieldError } from "react-hook-form";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import clsx from "clsx";

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'defaultValue'> {
  errors?: FieldError;
  label?: string;
  defaultValue: string | Date; // Allow both string and Date
  placeholder?: string;
  startFrom?: Date;
  required?: boolean;
  onChanging: (arg0?: Date) => void;
}

const DateSingle = (
  {
    required,
    errors,
    label,
    defaultValue,
    startFrom,
    onChanging,
  }: InputProps
) => {
  const [dateValue, setDateValue] = useState<DateValueType>({
    startDate: typeof defaultValue === "string" ? new Date(defaultValue) : defaultValue,
    endDate: typeof defaultValue === "string" ? new Date(defaultValue) : defaultValue,
  });

  return (
    <div className="flex flex-col">
      {label && (
        <label
          className={clsx(
            "transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5",
            { "!text-rose-500": errors }
          )}
        >
          {label} {required ? "*" : ""}
        </label>
      )}
      <div>
        <Datepicker
          primaryColor="green"
          placeholder=" "
          useRange={false}
          asSingle
          startFrom={startFrom}
          popoverDirection="down"
          value={dateValue}
          onChange={(option: DateValueType) => {
            setDateValue(option);
            onChanging(option?.startDate ? new Date(option.startDate) : undefined);
          }}
          inputClassName="w-full appearance-none px-3 py-3.5 rounded-md bg-white border border-stone-950 border-opacity-10 rounded-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          aria-label={label}
        />
      </div>
      {errors && <div className="text-red-400 text-xs">{errors.message}</div>}
    </div>
  );
};


export default DateSingle;
