import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
} from "@nextui-org/react";
import NoContent from "@/lib/common/components/table/NoContent";
import TableCss from "@/lib/utils/table-css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Security } from "@/lib/services/class/security";
import TrendDown from "@/assets/images/icons/trend-down.svg";
import TrendUp from "@/assets/images/icons/trend-up.svg";
import { formatNum, getSymbolImage } from "@/lib/library";
import SecurityInfo from "@/pages/dashboard/order/components/SecurityInfo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import securityOperations from "@/lib/store/features/security/securityOperations";
import { RootState } from "@/lib/store";
import { useDispatch, useSelector } from "react-redux";
import securitySelector from "@/lib/store/features/security/securitySelector";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  type: string | undefined;
}

export default function MartketUpdate({ type }: Props) {
  const [data, setData] = useState<Security[]>([]);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const topPercentGainerData = useSelector<RootState, Security[]>(
    securitySelector.getTopPercentGainer
  );
  const topPercentLooserData = useSelector<RootState, Security[]>(
    securitySelector.getTopPercentLooser
  );
  const topValueGainerData = useSelector<RootState, Security[]>(
    securitySelector.getTopValueGainer
  );
  const topValueLooserData = useSelector<RootState, Security[]>(
    securitySelector.getTopValueLooser
  );

  const isLoading = useSelector<RootState, boolean>(
    securitySelector.getIsLoading
  );

  useEffect(() => {
    if (type == "TOP_PERCENT_GAINER") {
      setData(topPercentGainerData);
    } else if (type == "TOP_PERCENT_LOOSER") {
      setData(topPercentLooserData);
    } else if (type == "TOP_VALUE_GAINER") {
      setData(topValueGainerData);
    } else if (type == "TOP_VALUE_LOOSER") {
      setData(topValueLooserData);
    }
  }, [
    type,
    topPercentGainerData,
    topPercentLooserData,
    topValueGainerData,
    topValueLooserData,
  ]);

  useEffect(() => {
    dispatch(securityOperations.fetchTopPercentGainer());
    dispatch(securityOperations.fetchTopPercentLooser());
    dispatch(securityOperations.fetchTopValueGainer());
    dispatch(securityOperations.fetchTopValueLooser());
  }, [dispatch]);

  const loadingState = isLoading ? "loading" : "idle";

  const classNames = useMemo(() => TableCss, []);

  const renderCell = useCallback(
    (portfolio: Security, columnKey: React.Key) => {
      const cellValue = portfolio[columnKey as keyof Security];

      switch (columnKey) {
        case "symbol":
          return (
            <SecurityInfo symbol={portfolio.symbol}>
              <div className="justify-start items-center gap-2 flex">
                <LazyLoadImage
                  className="w-[25px] h-[25px] rounded-full"
                  src={getSymbolImage(portfolio.symbol)}
                />
                <div>{cellValue}</div>
              </div>
            </SecurityInfo>
          );
        case "lastPx":
          return formatNum(cellValue);
        case "netChgPrevDay":
          return formatNum(cellValue);
        case "netChgPrevDayPerc":
          return formatNum(cellValue);
        case "status":
          if (portfolio && portfolio?.netChgPrevDay > 0) {
            return <img src={TrendUp} />;
          } else {
            return <img src={TrendDown} />;
          }

        default:
          return cellValue;
      }
    },
    []
  );

  return (
    <Table
      aria-label="Example table with client async pagination"
      classNames={classNames}>
      <TableHeader>
        <TableColumn key="symbol">Symbol</TableColumn>
        <TableColumn key="lastPx">Current Price</TableColumn>
        <TableColumn key="netChgPrevDay">Change</TableColumn>
        <TableColumn key="netChgPrevDayPerc">% Change</TableColumn>

        <TableColumn key="status">Status</TableColumn>
      </TableHeader>
      <TableBody<Security>
        emptyContent={<NoContent />}
        isLoading={isLoading}
        items={data != undefined && data.length > 0 ? data : []}
        loadingContent={<Spinner />}
        loadingState={loadingState}>
        {(item: Security) => {
          const res = item as Record<string, unknown>;
          return (
            <TableRow key={res.symbol as string}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          );
        }}
      </TableBody>
    </Table>
  );
}
