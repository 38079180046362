import AssetAllocationChart from "@/pages/dashboard/portfolio/components/AssetAllocation";
import CardTemplate from "./index";
import useAssetAllocationSWR from "@/lib/hook/useAssetAllocationSWR";
import { AssetProps } from "@/lib/types/global";
import { formatMoney, formatNum } from "@/lib/library";

function AssetAllocationCard() {
  const { data } = useAssetAllocationSWR();

  const getGetValue = () => {
    const result = data != undefined && data.data && data.data.find((v: AssetProps) => v.title == 'Equities')
    return result && result.asset_value ? formatMoney(result.asset_value) : 0;
  }

  const getGetPercent = () => {
    const result = data != undefined && data.data && data.data.find((v: AssetProps) => v.title == 'Equities')
    return result && result.asset_per ?  formatNum(result.asset_per) : 0;
  }

  return (
    <CardTemplate title="Asset Allocation" showBorder={true}>
      <div className="p-5">
        <div className="pb-6 justify-between items-center gap-[30px] flex">
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-neutral-700 text-xs font-medium capitalize">
              Equity Asset Value
            </div>
            <div className=" text-stone-950 dark:text-[#f3f3f3] text-lg font-semibold capitalize">
              {getGetValue()}
            </div>
          </div>
          <div className="flex-col justify-start items-start gap-2 inline-flex">
            <div className="text-neutral-700 text-xs font-medium capitalize">
              Equity Per
            </div>
            <div className=" text-stone-950 dark:text-[#f3f3f3] text-lg font-semibold capitalize">
              {getGetPercent()}%
            </div>
          </div>
         
        </div>

        <AssetAllocationChart
          data={data != undefined && data.data ? data.data : []}
        />
      </div>
    </CardTemplate>
  );
}

export default AssetAllocationCard;
