import cancelButton from "@/assets/images/icons/cancel.svg";
import Button from "@/lib/common/components/button";
import { usePaystackPayment } from "react-paystack";
import { DepositProps } from "@/lib/types/global";
import { AppContext } from "@/lib/contexts/App.context";
import { useContext } from "react";
import { formatMoney } from "@/lib/library";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deposit?: DepositProps;
  onSuccessRes: () => void;
}
export default function DepositSummary({ deposit, setIsOpen, onSuccessRes }: Props) {
  const { user } = useContext(AppContext);

  const getTransactionFeesPayStack = () => {
    var total = addTransactionAmountAndFeesPayStack();
    var value = deposit?.amount ?? 0;
    return total - value;
  };
  const addTransactionAmountAndFeesPayStack = () => {
    const value = deposit?.amount ?? 0;

    if (isNaN(value)) {
      return 0;
    }

    var charge = 0;
    if (value < 2500) {
      charge = value / (1 - 0.015);
    } else {
      charge = (value + 100) / (1 - 0.015);
      var res = charge - value;
      if (res > 2000) {
        charge = value + 2000;
      }
    }
    return charge;
  };

  const onSuccess = () => {
    setIsOpen(false);
    onSuccessRes()
  };

  const onClose = () => {
    // setIsOpen(false);
  };

  const config = {
    tx_ref: Date() + Math.floor(Math.random() * 6) + 1,
    email: user && user?.emailAddress1 ? user?.emailAddress1 : "",
    amount: parseInt(addTransactionAmountAndFeesPayStack().toString()) * 100,
    publicKey: "pk_live_0de4a629a36d4496b49c215eb7e3dee721e9f2f5",
    firstname: user && user?.firstName ? user?.firstName : "",
    lastname: user && user?.lastName ? user?.lastName : "",
    metadata: {
      cash_account_id: deposit?.accountId,
      user_id: user && user?.id ? user.id : "",
      user_name: user && user?.label ? user?.label : "",
      custom_fields: [],
    },
  };

  const initializePayment = usePaystackPayment(config);

  return (
    <div
      className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
      style={{ zIndex: 100 }}>
      <div className="my-10 flex h-full md:h-auto items-center justify-center  text-center ">
        <div
          className="
            p-6 bg-stone-50 rounded-[10px] flex-col justify-center items-start gap-6 inline-flex
            w-full 
            md:w-[611px] mx-auto  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
          <div className="w-full justify-between items-center inline-flex">
            <div className=" text-stone-950 dark:text-[#f3f3f3] text-xl font-semibold font-['Inter']">
              Confirm your deposit
            </div>

            <div
              className="cursor-pointer w-10 h-10 relative bg-black bg-opacity-5 rounded"
              onClick={() => setIsOpen(false)}>
              <img src={cancelButton} />
            </div>
          </div>

          <table className="text-start rounded-md border border-black border-opacity-5 w-full">
            <tr>
              <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                Method of payment
              </td>
              <td
                className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                Card Payment
              </td>
            </tr>
            <tr>
              <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                Amount
              </td>
              <td
                className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                {formatMoney(deposit != null ? deposit?.amount : 0)}
              </td>
            </tr>

            <tr>
              <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                Estimated transaction fees
              </td>
              <td
                className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                {formatMoney(getTransactionFeesPayStack())}
              </td>
            </tr>

            <tr>
              <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                Total Amount
              </td>
              <td
                className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                {formatMoney(addTransactionAmountAndFeesPayStack())}
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <div className="py-3 px-2  flex justify-between items-center">
                  <Button
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    variant="primary-outline">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      initializePayment(onSuccess, onClose);
                    }}>
                    Submit
                  </Button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
