import { useState } from "react";
import useSWR from "swr";

export default function useSecuritySWR() {
  const [symbol, setSymbol] = useState<string | undefined>();

  const handleChangeSymbol = (e: string | undefined) => {
    if (e) {
      setSymbol(e);
    }
  };

  const parameters = {
    revalidateIfState: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };

  const { data, error, isLoading } = useSWR(
    symbol ? `securityOverview/${symbol}` : null,
    parameters
  );
  // if (symbol && data) {
  //   DataCache.setItem(symbol, data);
  // }
  return {
    data,
    handleChangeSymbol,
    symbol,
    setSymbol,
    error,
    isLoading,
  };
}
