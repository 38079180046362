import React, { useCallback, useEffect } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
} from "@nextui-org/react";

import NoContent from "@/lib/common/components/table/NoContent";
import { formatDate, formatNum } from "@/lib/library";
import TableCss from "@/lib/utils/table-css";
import usePortfolioMarginCallSWR from "@/lib/hook/usePortfolioMarginCallSWR";
import { marginCallolumns } from "@/lib/utils/tableColumns";
import { MarginCall } from "@/lib/services/class/marginCall";
import { Portfolio } from "@/lib/services/class/portfolio";

interface Props {
  portfolio?: Portfolio;
}
export default function MarginCallTable({ portfolio }: Props) {

  const { data, isLoading, handleChange } = usePortfolioMarginCallSWR();

  useEffect(() => {
    handleChange(portfolio?.id);
  }, [handleChange, portfolio]);

  const renderCell = useCallback(
    (portfolio: MarginCall, columnKey: React.Key) => {
      const cellValue = portfolio[columnKey as keyof MarginCall];

      switch (columnKey) {
        case "callDate":
          return formatDate(cellValue);
        case "callValue":
          return formatNum(cellValue, 0);
        case "portfolioValue":
          return formatNum(cellValue, 0);
        case "equityValue":
          return formatNum(cellValue, 0);
        case "maintMarginRate":
          return formatNum(cellValue, 0);

        default:
          return cellValue;
      }
    },
    []
  );


  const loadingState = isLoading ? "loading" : "idle";

  const classNames = React.useMemo(() => TableCss, []);

  return (
    <Table
      aria-label="Example table with client async pagination"
      classNames={classNames}
      >
      <TableHeader columns={marginCallolumns}>
        {(column) => (
          <TableColumn
            key={column.uid}
            align={column.uid === "actions" ? "center" : "start"}
            allowsSorting={column.sortable}>
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody<MarginCall>
        emptyContent={<NoContent />}
        items={data != undefined && data.data.result ? data.data.result : []}
        loadingContent={<Spinner />}
        loadingState={loadingState}>
        {(item: MarginCall) => {
          return (
            <TableRow key={item.name}>
              {(columnKey) => (
                <TableCell>{renderCell(item, columnKey)}</TableCell>
              )}
            </TableRow>
          );
        }}
      </TableBody>
    </Table>
  );
}
