import axios, { AxiosError } from 'axios';
import Service from './request.ts';
import { onLogout } from '../library.ts';
import * as rax from 'retry-axios';
import DataCache from '../DataCache.ts';

axios.defaults.baseURL = 'https://www.meritrade.com/api/v1/';

axios.interceptors.request.use(async (config) => {
  const token = await DataCache.getItem(Service.StoreToken);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Platform'] = "WEB";
  config.headers['App_Version'] = "1.0";
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {

    const { data }: any = error.response ?? {};

    if (data && data.message == 'Unauthenticated.') {
      onLogout();
    }

    return Promise.reject(data);
  },
);

rax.attach(axios);

export default axios;
