import SelectSearch, { SelectedOptionValue } from "react-select-search";
import ChevronDown from "../../icons/chevron-down";
import { SelectGroupProps } from "@/lib/types/global";
import { FieldError } from "react-hook-form";
import clsx from "clsx";
import {
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  multiple?: boolean;
  placeholder?: string;
  touched?: Record<string, unknown>;
  onChanging: (value: SelectedOptionValue | SelectedOptionValue[]) => void;
  defaultValue?: string | string[] | undefined;
  label?: string;
  errors?: FieldError;
  required?: boolean;
  options: SelectGroupProps[];
}

const SearchSelect = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder = "Select...",
      required,
      multiple = false,
      errors,
      label,
      options,
      defaultValue,
      onChanging,
    }: InputProps,
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string | string[] | undefined>(
      defaultValue
    );


    useImperativeHandle(ref, () => inputRef.current!);

    return (
      <div ref={inputRef}>
        {label && (
          <label
            className={clsx(
              "transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5",
              {
                "text-rose-500": errors,
              }
            )}>
            {label} {required ? "*" : ""}
          </label>
        )}

        <div
          className={clsx("relative flex items-center ", {
            "border-rose-500 focus:border-rose-500": errors,
          })}>
          <SelectSearch
            multiple={multiple}
            value={value ?? defaultValue}
            placeholder={placeholder}
            emptyMessage={<div className="pt-2">No option is available</div>}
            search
            onChange={(option) => {
              setValue(option.toString());
              onChanging(option);
              console.log(option)
            }}
            options={options}
            onBlur={() => {
              /* No-op for now */
            }}
            onFocus={() => {
              /* No-op for now */
            }}
          />
          <span className="z-52 absolute right-4 inset-y-0 flex items-center pointer-events-none">
            <ChevronDown />
          </span>
        </div>

        {errors && (
          <div className="text-red-400 text-xs px-1.5">{errors.message}</div>
        )}
      </div>
    );
  }
);

SearchSelect.displayName = "SearchSelect";

export default SearchSelect;
