import cancelButton from "@/assets/images/icons/cancel.svg";
import LargeLogo from "@/assets/images/logo_large.svg";
import { Link, useNavigate } from "@tanstack/react-router";
import navigation from "@/lib/utils/main-menu.ts";
import clsx from "clsx";
import { useState } from "react";

interface Props {
  setShowMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MobileMenu({ setShowMobileMenu }: Props) {
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <div className="flex ">
        <div
          style={{ zIndex: 100000 }}
          className="
        transition ease-out duration-300
        fixed top-0 left-0 w-72 h-full  transform  bg-white shadow-lg "
        >
          <div className="px-6 py-4">
            <Link to="/">
              <img src={LargeLogo} />
            </Link>

            <div
              className="cursor-pointer w-10 h-10 right-3 top-3 absolute bg-black bg-opacity-5 rounded"
              onClick={() => setShowMobileMenu(false)}
            >
              <img src={cancelButton} />
            </div>

            <div className="overflow-y-scroll overflow-x-hidden scrollbar-w-thin] scrollbar-h-[1px] scrollbar scrollbar-thumb-[#edf1ef] scrollbar-track-white scrollbar-corner-white max text-white mt-12 flex-col space-y-2 w-full h-[calc(100vh)]">
              <ul className="space-y-2 ">
                {navigation.map(
                  ({ link, title, Image, items, ImageActive }, index) => (
                    <li
                      role="presentation"
                      onClick={() => {
                        setActiveLink(link);
                        if (!items || (items && items?.length == 0)) {
                          navigate({ to: link });
                          setShowMobileMenu(false)
                        }
                      }}
                      key={index}
                    >
                      <div
                        role="presentation"
                        key={index}
                        className={clsx(
                          "cursor-pointer w-full  font-medium p-3 pl-8 transform ease-in-out duration-300 flex flex-row items-center space-x-4",
                          {
                            "text-neutral-400 text-base": activeLink !== link,
                          },
                          {
                            " text-green-900 border-y  border-opacity-20 border-green-900 bg-green-900 bg-opacity-10 ":
                              activeLink === link,
                          }
                        )}
                      >
                        {activeLink === link ? (
                          <ImageActive className="text-green-900" />
                        ) : (
                          <Image className="text-neutral-400" />
                        )}
                        <div>{title}</div>
                      </div>

                      {items && items.length > 0 && (
                        <ul
                          className={clsx(
                            "pt-2",
                            {
                              hidden: activeLink !== link,
                            },
                            {
                              block: activeLink === link,
                            }
                          )}
                        >
                          {items.map(({ link, title }, index) => (
                            <li
                              onClick={() => {
                                // setActiveLink(link);
                                navigate({ to: link });
                                setShowMobileMenu(false)
                              }}
                              key={index}
                              className={clsx(
                                "cursor-pointer w-full h-12 pl-[72px] pr-6 py-3.5 rounded justify-start items-start gap-2.5 inline-flex",
                                {
                                  "bg-green-900 bg-opacity-10 rounded ":
                                    window.location.pathname == link,
                                }
                              )}
                            >
                              <div className="justify-start items-center gap-4 flex">
                                <div
                                  className={clsx(
                                    "text-sm font-normal leading-tight",
                                    {
                                      " text-green-900 font-semibolds":
                                        window.location.pathname == link,
                                    },
                                    {
                                      " text-neutral-400 ":
                                        window.location.pathname != link,
                                    }
                                  )}
                                >
                                  {title}
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
