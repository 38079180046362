import Select from "@/lib/common/components/select";
import PageNavBar from "../layout/PageNavBar";
import useSWR from "swr";
import Service from "@/lib/services/request";
import { Portfolio } from "@/lib/services/class/portfolio";
import CashInfo from "./components/CashInfo";
import PortfolioTable from "./components/PortfolioTable";
import { useEffect, useState } from "react";
import CardTemplate from "../dashboard/components/card";
import MarginCallTable from "./components/MarginCallTable";
import { RootState } from "@/lib/store";
import portfolioOperations from "@/lib/store/features/portfolio/portfolioOperations";
import portfolioSelector from "@/lib/store/features/portfolio/portfolioSelector";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

function PortfolioPage() {
  const [portfolio, setPortfolio] = useState<Portfolio>();
  const balance = useSWR(Service.CustomerBalanceSummary);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const portfolioData = useSelector<RootState, Portfolio[]>(
    portfolioSelector.getPortfolio
  );

  useEffect(() => {
    dispatch(portfolioOperations.fetchPortfolio());
  }, [dispatch]);

  useEffect(() => {
    if (!portfolio && portfolioData) {
      setPortfolio(portfolioData[0]);
    }
  }, [portfolioData]);

  return (
    <>
      <PageNavBar
        title="Portfolio"
        primary="Portfolio"
        showTradeNowButton={true}
      />

      <div className="mb-6 mx-4 bg-white rounded-lg border border-stone-950 border-opacity-10">
        <div className="p-8">
          <div className="flex-col md:flex-row flex justify-between items-start gap-5 ">
            <Select
              className="w-full md:w-48"
              value={portfolio?.id}
              onChange={(event) => {
                const portfolio = portfolioData.find((item: Portfolio) => {
                  const id = event.target.value as unknown as number;
                  if (id == item.id) return item;
                });
                console.log(portfolio);
                setPortfolio(portfolio);
              }}
              label="Select a Portfolio"
              placeholder="Select Starting Point">
              {portfolioData.map((item: Portfolio) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.label}
                  </option>
                );
              })}
            </Select>

            {portfolio && portfolio?.portfolioType == "MARGIN PORTFOLIOS" ? (
              <>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 w-full md:w-auto">
                  <CashInfo
                    title="Current Valuation Cash"
                    amount={portfolio ? portfolio?.currentValuation?.amount : 0}
                    info="Current Equity Value means the difference between the current market value of all securities in the margin account and the amount owed by the client to the broker in the margin account."
                  />

                  <CashInfo
                    title="Margin Portfolio Value"
                    amount={
                      portfolio ? portfolio?.marginPortfolioValue?.amount : 0
                    }
                    info="It means the current market value of all securities in the margin account calculated on a daily basis."
                  />

                  <CashInfo
                    title="Margin Call Value"
                    amount={portfolio ? portfolio?.marginCallValue?.amount : 0}
                  />
                  <CashInfo
                    title="Margin Min Maintain Value"
                    amount={
                      portfolio ? portfolio?.marginMinMaintainValue?.amount : 0
                    }
                    info=" Maintenance margin is the minimum equity an investor must hold in the margin
                account after the purchase has been made; it is currently set at 40% of the total
               value of the securities in a margin account
                "
                  />

                  <CashInfo
                    title="Margin Trading Power"
                    amount={
                      portfolio ? portfolio?.marginTradingPower?.amount : 0
                    }
                    info="Margin Purchasing Power Power is the amount of money an investor has available to buy securities in a margin account."
                  />
                  <CashInfo
                    title="Cost Basis"
                    amount={portfolio ? portfolio?.costBasis?.amount : 0}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 w-full md:w-auto">
                  <CashInfo
                    title="Available Cash"
                    amount={portfolio ? portfolio?.availableCash?.amount : 0}
                  />
                  <CashInfo
                    title="Total Cash"
                    amount={
                      balance != undefined &&
                      balance.data != undefined &&
                      balance.data.data
                        ? balance.data.data.totalCashBalance
                        : 0
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="py-5 w-full">
          <PortfolioTable key={portfolio?.id} portfolio={portfolio} />
        </div>
      </div>

      {portfolio && portfolio?.portfolioType == "MARGIN PORTFOLIOS" && (
        <CardTemplate
          title="Margin Call"
          showBorder={true}
          className="mb-6 m-4">
          <div className=" w-full">
            <MarginCallTable portfolio={portfolio} />
          </div>
        </CardTemplate>
      )}
    </>
  );
}

export default PortfolioPage;
