export const tradeOrderColumns = [
  { name: "Created on", uid: "orderDate", sortable: true },
  { name: "Symbol", uid: "securityName", sortable: true },
  { name: "Name", uid: "name", sortable: true },

  { name: "Order Type", uid: "orderType", sortable: true },
  { name: "Quantity", uid: "quantityRequested", sortable: true },
  
  { name: "Price type", uid: "priceType" , sortable: true},
  { name: "Term", uid: "orderTermName", sortable: true },
  { name: "Order Status", uid: "orderStatus" , sortable: true},
  { name: "Market Status", uid: "fixOrderStatus" , sortable: true},
  { name: "Message", uid: "fixMessage", sortable: true },
  { name: "Actions", uid: "actions" },
];

export const cashStatementColumns = [
  { name: "Name", uid: "name", sortable: true },
  { name: "Description", uid: "description", sortable: true },
  { name: "Currency", uid: "currency", sortable: true },
  { name: "Debit Amount", uid: "debitAmount", sortable: true },
  { name: "Credit Amount", uid: "creditAmount" },
  { name: "Balance", uid: "balance" },
  { name: "Transaction Date", uid: "transactionDate", sortable: true },
  { name: "Value Date", uid: "valueDate" },
];

export const contractColumns = [
  { name: "Contract #", uid: "name", sortable: true },
  { name: "Order #", uid: "tradeOrderId", sortable: true },
  { name: "Transaction Date", uid: "transactionDate", sortable: true },
  { name: "Price", uid: "contractPrice", sortable: true },
  { name: "Quantity", uid: "quantity" },
  { name: "Symbol", uid: "symbol" },
  { name: "Amount", uid: "contractAmount", sortable: true },
  { name: "Actions", uid: "actions" },
];

export const watchlistColumns = [
  { name: "Symobl", uid: "symbol", sortable: true },
  { name: "Trigger Price", uid: "trigger_price", sortable: true },
  { name: "Current Price", uid: "current_price", sortable: true },
  { name: "Actions", uid: "actions" },
];

export const marginCallolumns = [
  { name: "CALL #", uid: "name", sortable: true },
  { name: "CALL DATE", uid: "callDate", sortable: true },
  { name: "CALL VALUE(₦)", uid: "callValue", sortable: true },
  { name: "PORTFOLIO ID", uid: "portfolioId" },
  { name: "PORTFOLIO VALUE(₦)", uid: "portfolioValue" },
  { name: "EQUITY VALUE(₦)", uid: "equityValue" },
  { name: "MAINTENANCE RATE(%)", uid: "maintMarginRate" },
  { name: "STATUS", uid: "status" },
];

