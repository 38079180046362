import DateRange from "@/lib/common/components/date/DateRange";
import PageNavBar from "../layout/PageNavBar";
import AssetAllocationChart from "./components/AssetAllocation";
import useAssetAllocationSWR from "@/lib/hook/useAssetAllocationSWR";
import { AssetProps } from "@/lib/types/global";
import NoContent from "@/lib/common/components/table/NoContent";
import { Key } from "react";
import { formatNum } from "@/lib/library";

function AssetAllocation() {
  const { data, dateRange, handleChangeDate } = useAssetAllocationSWR();

  return (
    <>
      <PageNavBar
        title="Asset Allocation"
        primary="Portfolio"
        primaryLink="/dashboard/portfolio"
        secondary="Asset Allocation"
        showTradeNowButton={true}
      />

      <div className=" mx-4 mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
        <div className="p-8">
          <div className="flex justify-between items-start">
            <div className="flex justify-center items-center gap-2">
              <DateRange
                label="Date Range"
                value={dateRange}
                onChange={(values) => {
                  handleChangeDate(values);
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-fill h-[0px] border border-stone-950 border-opacity-5"></div>

        <div className="p-5">
          <div className="flex-col md:flex-row flex justify-center items-center gap-5">
            <AssetAllocationChart
              data={data != undefined && data.data ? data.data : []}
            />

            <div >


            <table
              className="min-w-full h-auto table-auto w-full"
            >
              <thead className="[&>tr]:first:rounded-lg">
                <tr className="group outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2">
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    Title
                  </th>
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                   Asset Value (N)
                  </th>
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    Asset Return (N)
                  </th>
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    Asset (%) 
                  </th>
                </tr>
              </thead>
              <tbody>
                {data != undefined &&
                  data.data &&
                  data.data.map(
                    (row: AssetProps, index: Key | null | undefined) => {
                      return (
                        <tr key={index} className="">
                          <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                            {row.title}
                          </td>
                          <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                            {formatNum(row.asset_value)}
                          </td>
                          <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                            {formatNum(row.asset_return)}
                          </td>
                          <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                            {formatNum(row.asset_per)}
                          </td>
                        </tr>
                      );
                    }
                  )}

                {data == undefined ||
                  (data.data && data.data.count === 0 && (
                    <tr>
                      <td colSpan={2}>
                        <NoContent />
                      </td>
                    </tr>
                  ))}
              </tbody>

            
            </table>

          
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetAllocation;
