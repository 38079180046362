import CardTemplate from "../dashboard/components/card";
import NewsBody from "./components/NewsBody";
import { News } from "@/lib/services/class/news";
import CustomPagination from "@/lib/common/components/table/Pagination";
import useNewsSWR from "@/lib/hook/useNewsSWR";

function StockNews() {
  const { data, page, setPage } = useNewsSWR();
  // const [page, setPage] = useState(0);
  return (
    <>
      <CardTemplate showBorder={false} className="p-4 w-3/4">
        <div className="grid grid-cols-1 divide-y divide-black divide-opacity-5 ">
          {data &&
            data.data &&
            data.data.content &&
            data.data.content.map((row: News) => {
              return <NewsBody news={row} />;
            })}
        </div>

        <CustomPagination
          isCompact={true}
          showControls={true}
          showShadow={true}
          page={page}
          total={
            data && data.data && data.data.content && data.data.totalPages
              ? data.data.totalPages
              : 0
          }
          onChange={(pag: React.SetStateAction<number>) => setPage(pag)}
        />
      </CardTemplate>
    </>
  );
}

export default StockNews;
