import LargeLogo from "@/assets/images/logo_large.svg";
import BgLogin from "@/assets/images/bg_login.webp";
import Service from "@/lib/services/request";
import { loginSchema } from "@/lib/services/validators/auth-validator";
import { ErrorProps } from "@/lib/types/error";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "@/lib/common/components/input";
import Button from "@/lib/common/components/button";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { AppContext } from "@/lib/contexts/App.context";
import { User } from "@/lib/services/class/user";
import SearchSelect from "@/lib/common/components/select/SearchSelect";
import Tawk from "@/lib/common/components/tawk";
import DataCache from "@/lib/DataCache";
import Constant from "@/lib/constant";
import nightwind from 'nightwind/helper';

interface FormProps {
  username: string;
  password: string;
  page?: string;
  remember?: string;
}

type SearchField = {
  redirect: string;
};

function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token, setToken, setUser } = useContext(AppContext);
  const { redirect }: SearchField = useSearch({
    strict: false,
  });

  useEffect(() => {
    if (token) {
      navigate({ to: "/dashboard" });
    }
  });

  useEffect(() => {
    const storedPref = window.localStorage.getItem('nightwind-mode');
    nightwind.enable(storedPref === 'dark');
  }, []);


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {
      const res = await Service.post(Service.Login, da);

      if (res) {
        const token = res.data.token;
        if (token) {
          setToken(token);

          DataCache.setItem(Service.StoreToken, token, Constant.websiteTimeout);
        }

        const user = res.data.user as User;
        if (user) {
          localStorage.setItem(Service.StoreUser, JSON.stringify(user));
          setUser(user);
        }
      }
      return navigate({ to: redirect ? redirect : da.page ?? "/dashboard" });
    } catch (error: unknown) {
      console.log(error)
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
      <div className="w-full flex-wrap min-h-screen flex items-stretch">
        <div className="w-1/2 hidden lg:block relative bg-no-repeat bg-cover items-center p-4">
          <div
            className=" relative bg-no-repeat bg-contain h-full w-full bg-zinc-100 rounded-[10px]"
            style={{
              backgroundImage: `url(${BgLogin})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left",
            }}
          />
          <a
            href="https://www.meritrade.com"
            className="hidden md:block top-8 absolute left-12">
            <img src={LargeLogo} alt="Logo" />
          </a>
        </div>

        <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
          <div className="flex flex-col justify-start md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
            <a
              href="https://www.meritrade.com"
              className="block md:hidden top-8 absolute left-8">
              <img src={LargeLogo} alt="Logo" />
            </a>
            <h3 className="pb-4  text-stone-950 dark:text-[#f3f3f3] text-[32px] font-semibold ">
              <span className="capitalize">Sign </span>
              <span className="lowercase">in to</span>
              <span className="capitalize"> Meritrade</span>
            </h3>

            <p className="text-neutral-700 text-[15px] font-normal ">
              <span className="capitalize">Enter </span>
              <span className="lowercase">your username and password</span>
            </p>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col pt-3 md:pt-8">
              <div className="pt-4">
                <Input
                  {...register("username")}
                  errors={errors.username}
                  required
                  type="text"
                  label="Username"
                  className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
                />
              </div>

              <div className="pt-4">
                <Input
                  {...register("password")}
                  required
                  type="password"
                  label="Password"
                  errors={errors.password}
                  className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
                />
              </div>

              <div className="pt-4">
                <SearchSelect
                  {...register("page")}
                  errors={errors.page}
                  label="Select Starting Point"
                  defaultValue={"Dashboard"}
                  options={[
                    { value: "/dashboard", name: "Dashboard" },
                    { value: "/dashboard/portfolio", name: "Portfolio" },
                    { value: "/dashboard/orders", name: "Orders" },
                  ]}
                  onChanging={(r) => {
                    setValue("page", r.toString());
                  }}
                />
              </div>

              <div className="pt-4 w-full justify-between items-center gap-2.5 inline-flex">
                <div className="justify-center items-center gap-1 flex">
                  <div className="w-4 h-4 relative">
                    <input
                      className="bg-opacity-5 rounded-sm border order border-green-900"
                      {...register("remember")}
                      type="checkbox"
                    />
                  </div>
                  <div className="text-neutral-700 text-sm font-normal">
                    Remember me
                  </div>
                </div>
                <Link
                  to="/auth/forgot-password"
                  className="text-green-900 text-sm font-medium">
                  Forgot password?
                </Link>
              </div>

              <div className="pt-10">
                <Button
                  className="w-full"
                  disabled={loading}
                  isLoading={loading}
                  type="submit">
                  Sign in
                </Button>
              </div>
            </form>
            <div className="text-center pt-3 pb-12">
              <p className="text-neutral-700 text-sm font-normal">
                Don't have an account?{" "}
                <Link
                  to="/auth/register"
                  className="text-green-900 text-sm font-medium">
                  Create an account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Tawk />
    </>
  );
}

export default Login;
