import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  title?: string;
  showBorder: boolean;
  className?: string;
}

function CardTemplate({ className, showBorder, children, title }: Props) {
  return (
    <div
      className={`bg-white rounded-lg border border-stone-950 border-opacity-10 mb-6 ${className}`}>
      {title && (
        <div className="p-4 md:p-6 ">
          <h3 className="  text-stone-950 dark:text-[#f3f3f3] text-sm md:text-base font-semibold capitalize">
            {title}
          </h3>
        </div>
      )}

      {showBorder && (
        <div className="w-fill h-[0px] border border-stone-950 border-opacity-5"></div>
      )}

      {children}
    </div>
  );
}

export default CardTemplate;
