import React from "react";
import { IconProps } from "@/lib/types/icon";

const PortfolioActiveSVG: React.FC<IconProps> = ({
  size = "24",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      {...attributes}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.09 6.97953C20.24 6.03953 18.82 5.56953 16.76 5.56953H16.52V5.52953C16.52 3.84953 16.52 1.76953 12.76 1.76953H11.24C7.48004 1.76953 7.48004 3.85953 7.48004 5.52953V5.57953H7.24004C5.17004 5.57953 3.76004 6.04953 2.91004 6.98953C1.92004 8.08953 1.95004 9.56953 2.05004 10.5795L2.06004 10.6495L2.13749 11.4628C2.15176 11.6126 2.23242 11.7479 2.35831 11.8303C2.59812 11.9872 2.99946 12.2459 3.24004 12.3795C3.38004 12.4695 3.53004 12.5495 3.68004 12.6295C5.39004 13.5695 7.27004 14.1995 9.18004 14.5095C9.27004 15.4495 9.68004 16.5495 11.87 16.5495C14.06 16.5495 14.49 15.4595 14.56 14.4895C16.6 14.1595 18.57 13.4495 20.35 12.4095C20.41 12.3795 20.45 12.3495 20.5 12.3195C20.8968 12.0953 21.3083 11.819 21.6835 11.5484C21.7965 11.4668 21.8688 11.3408 21.8842 11.2023L21.9 11.0595L21.95 10.5895C21.96 10.5295 21.96 10.4795 21.97 10.4095C22.05 9.39953 22.03 8.01953 21.09 6.97953ZM13.09 13.8295C13.09 14.8895 13.09 15.0495 11.86 15.0495C10.63 15.0495 10.63 14.8595 10.63 13.8395V12.5795H13.09V13.8295ZM8.91004 5.56953V5.52953C8.91004 3.82953 8.91004 3.19953 11.24 3.19953H12.76C15.09 3.19953 15.09 3.83953 15.09 5.52953V5.57953H8.91004V5.56953Z"
        fill={color}
      />
      <path
        d="M20.8736 13.7339C21.2272 13.5656 21.6344 13.8459 21.599 14.2359L21.2401 18.1897C21.0301 20.1897 20.2101 22.2297 15.8101 22.2297H8.19009C3.79009 22.2297 2.97009 20.1897 2.76009 18.1997L2.41938 14.4519C2.38433 14.0664 2.78229 13.7865 3.13493 13.946C4.27434 14.4615 6.37748 15.3761 7.67666 15.7164C7.84097 15.7594 7.97386 15.877 8.0458 16.0309C8.65277 17.329 9.9692 18.0197 11.8701 18.0197C13.7522 18.0197 15.0852 17.3024 15.6942 16.0011C15.7663 15.8471 15.8993 15.7296 16.0637 15.6863C17.4433 15.3233 19.6819 14.301 20.8736 13.7339Z"
        fill={color}
      />
    </svg>
  );
};

export default PortfolioActiveSVG;
