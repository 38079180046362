import Search from "@/lib/common/icons/search";
import clsx from "clsx";
import { useState } from "react";

interface Props {
  onChange: (arg0?: string) => void;
  placeholder?: string;
}

function SearchForm({ onChange, placeholder }: Props) {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={clsx(
        "w-[309px] h-12 p-3 bg-stone-950 bg-opacity-0 rounded-lg border border-stone-950 border-opacity-5 justify-between items-center gap-2 inline-flex",
        {
          "border-opacity-20": hover,
        }
      )}>
      <input
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder ?? "Search"}
        className="
      w-full appearance-none focus:outline-none focus:shadow-outline
    text-neutral-500 text-sm font-normal leading-normal"
      />

      <Search
        className={clsx("", {
          "text-black": hover,
        })}
      />
    </div>
  );
}

export default SearchForm;
