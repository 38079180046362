import { ReactNode, useContext } from "react";
import Popup from "reactjs-popup";
import { formatNum, getSymbolImage } from "@/lib/library";
import { Link } from "@tanstack/react-router";
import { ModalContext } from "@/lib/contexts/modal-context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Security } from "@/lib/services/class/security";
import { useDispatch, useSelector } from "react-redux";
import securityOperations from "@/lib/store/features/security/securityOperations";
import { RootState } from "@/lib/store";
import securitySelector from "@/lib/store/features/security/securitySelector";
import { ThunkDispatch } from "@reduxjs/toolkit";

type Props = {
  children: ReactNode;
  symbol: string;
};

export default function SecurityInfo({ symbol, children }: Props) {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const { setWatchlistModal, setSymbol } = useContext(ModalContext);

  const data = useSelector<RootState, Security>(
    securitySelector.getDetails
  );

  return (
    <>
      <Popup
        onOpen  = {() => {
          dispatch(securityOperations.fetchSecurityDetails({symbol, useCache: true}));
        }}
        trigger={() => <div className="cursor-pointer">{children}</div>}
        on={["hover", "focus"]}
        position={[
          "bottom center",
          "bottom left",
          "bottom right",
          "top left",
          "top center",
          "top right",
          "right top",
          "right center",
          "right bottom",
          "left top",
          "left center",
          "left bottom",
        ]}>
        <div className="flex flex-col justify-center items-center gap-2 pb-2">
          <LazyLoadImage
            className="w-[40px] h-[40px] radius-1/2 "
            src={getSymbolImage(symbol)}
          />

          <div className="text-center">
            {data != undefined && data ? data?.secDesc : ""}
          </div>
        </div>

        <div className="grid grid-cols-1 divide-y gap-2">
          <div className="pt-2 flex justify-between items-center text-center text-neutral-500 text-xs font-semibold uppercase">
            <div>Last Price</div>
            <div>
              {formatNum(
                data != undefined && data ? data.lastPx : 0,
                2
              )}
            </div>
          </div>

          <div className="pt-2 flex justify-between items-center text-center text-neutral-500 text-xs font-semibold uppercase">
            <div>Quantity Traded</div>
            <div>
              {formatNum(
                data != undefined && data ? data.lastQty : 0,
                2
              )}
            </div>
          </div>

          <div className="pt-2 flex justify-between items-center text-center text-neutral-500 text-xs font-semibold uppercase">
            <div>Vol. Traded</div>
            <div>
              {formatNum(
                data != undefined && data ? data.valTraded : 0,
                2
              )}
            </div>
          </div>

          <div className="pt-2 flex justify-between items-center text-center text-neutral-500 text-xs font-semibold uppercase">
            <div>Offer Depth</div>
            <div>
              {formatNum(
                data != undefined && data ? data.offerDepth : 0,
                2
              )}
            </div>
          </div>

          <div className="p-1 gap-1 flex justify-between items-center">
            <Link
              to="/dashboard/orders/create"
              search={{ symbol: symbol, orderType: "BUY" }}
              className="px-3.5 py-2 bg-emerald-500 bg-opacity-10 rounded-sm border-none ">
              <span className="text-emerald-500 text-xs font-semibold uppercase">
                BUY
              </span>
            </Link>
            <Link
              onClick={() => {
                setWatchlistModal(true);
                setSymbol(symbol);
              }}
              className="px-2 py-2 bg-lime-500 bg-opacity-10 rounded-sm ">
              <span className="text-lime-500 text-xs font-semibold uppercase">
                WATCH
              </span>
            </Link>
            <Link
              to="/dashboard/orders/create"
              search={{ symbol: symbol, orderType: "SELL" }}
              className="px-3.5 py-2 bg-red-600 bg-opacity-10 rounded-sm ">
              <span className="text-red-600 text-xs font-semibold uppercase">
                SELL
              </span>
            </Link>
          </div>
        </div>
      </Popup>
    </>
  );
}
