import PageNavBar from "../layout/PageNavBar";
import CashAccountCard from "../dashboard/components/CashAccountCard";
import TransactionCard from "../dashboard/components/card/transaction_card";
import Service from "@/lib/services/request";
import useSWR from "swr";
import CardTemplate from "../dashboard/components/card";
import bankAccounts from "@/lib/utils/payment-bank";
import { Key, useState } from "react";
import Input from "@/lib/common/components/input";
import Button from "@/lib/common/components/button";
import { cardWithdrawSchema } from "@/lib/services/validators/fund-validator";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { ErrorProps } from "@/lib/types/error";
import MessageModal from "../layout/component/modal/MessageModal";
import { Link } from "@tanstack/react-router";
import IncompleteKYC from "../layout/IncompleteKYC";

interface FormProps {
  amount: number;
}

interface Props {
  title: string;
  image: string;
  bank: string;
}

export default function FundWithdrawal() {
  const [loading, setLoading] = useState(false);
  const { data } = useSWR(Service.CustomerBalanceSummary);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(cardWithdrawSchema),
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {
      await Service.post(Service.CreatePaymentPartnerCashTransaction, da);
      reset();
      setIsOpen(true);
      // toast("Your withdrawal request has been submitted", {
      //   type: "success",
      // });
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
      {isOpen && (
        <MessageModal
          onClick={() => {
            setIsOpen(false);
          }}
          title="Transaction successful"
          description="Your transaction is successful and your fund has been added to your account "
          actions={
            <div className="flex justify-between items-center">
              <Link to="/dashboard/orders/create" className="w-full">
                <Button className="w-full">Trade now</Button>
              </Link>
              <Link to="/dashboard" className="w-full">
                <Button variant="primary-outline" className="w-full">
                  Back to Dashboard
                </Button>
              </Link>
            </div>
          }
        />
      )}

      <PageNavBar
        title="Cash Withdrawal"
        primary="Fund Management"
        showTradeNowButton={true}
      />

      <div className="px-4 grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-0">
        <div className="col-span-4">
          <CashAccountCard
            availableBalance={
              data != undefined && data.data ? data.data.availableBalance : 0
            }
            totalCashBalance={
              data != undefined && data.data ? data.data.totalCashBalance : 0
            }
          />
          <div className="pt-4">
            <TransactionCard />
          </div>
        </div>

        <CardTemplate
          className="col-span-5 h-max pb-6"
          title="Cash Withdraw"
          showBorder={false}>
            <IncompleteKYC/>
            
          <form className="px-4" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 w-full">
              <Input
                {...register("amount")}
                errors={errors.amount}
                required
                label="Enter Amount"
              />
            </div>

            <Button
              disabled={loading}
              isLoading={loading}
              className="w-44"
              type="submit">
              Submit
            </Button>
          </form>
        </CardTemplate>

        <CardTemplate
          className="col-span-3 h-max pb-6"
          title="Pay at bank"
          showBorder={false}>
          <table className="min-w-full h-auto table-auto w-full">
            <thead className="[&>tr]:first:rounded-lg">
              <tr className="group outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2">
                <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase"></th>
                <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                  BANK
                </th>
                <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                  ACCOUNT NO.
                </th>
              </tr>
            </thead>
            <tbody>
              {bankAccounts.map((row: Props, index: Key | null | undefined) => {
                return (
                  <tr key={index} className="">
                    <td className="px-3">
                      <img src={row.image} />
                    </td>
                    <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                      {row.title}
                    </td>
                    <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                      {row.bank}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardTemplate>
      </div>
    </>
  );
}
