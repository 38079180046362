import NoContentImage from "@/assets/images/no_content.svg";

interface Props {
  content?: string,
}
function NoContent({content}: Props) {
  return (
    <div className="py-16 flex-col justify-center items-center gap-4 inline-flex">
      <img src={NoContentImage} />

      <div className="text-neutral-400 text-xs font-semibold capitalize">
        { content ?? 'No data available in table' }
      </div>
    </div>
  );
}

export default NoContent;
