import clsx from "clsx";
import {
  forwardRef,
  SelectHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FieldError } from "react-hook-form";
import ChevronDown from "../../icons/chevron-down";

export type NativeSelectProps = {
  placeholder?: string;
  label?: string;
  errors?: FieldError;
  touched?: Record<string, unknown>;
} & SelectHTMLAttributes<HTMLSelectElement>;

const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
  (
    {
      placeholder = "Select...",
      required,
      label,
      name,
      errors,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLSelectElement>(null);
    const [isPlaceholder, setIsPlaceholder] = useState(false);

    useImperativeHandle<HTMLSelectElement | null, HTMLSelectElement | null>(
      ref,
      () => innerRef.current
    );

    useEffect(() => {
      if (innerRef.current && innerRef.current.value === "") {
        setIsPlaceholder(true);
      } else {
        setIsPlaceholder(false);
      }
    }, [innerRef.current?.value]);

    return (
      <div>
        <div
          onFocus={() => innerRef.current?.focus()}
          onBlur={() => innerRef.current?.blur()}
          className={clsx(
            "z-0 w-full text-base-regular",
            className,
            {
              "text-gray-500": isPlaceholder,
            }
          )}>
          {label && (
            <label
              onClick={() => innerRef.current?.focus()}
              className={clsx(
                "transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5",
                {
                  "!text-rose-500": errors,
                }
              )}
              htmlFor={name}>
              {label} {required ? "*" : ""}
            </label>
          )}
          
          <div className="relative flex items-center ">
            <select
              ref={innerRef}
              name={name}
              {...props}
              className={clsx(
                "rounded-md bg-white border border-stone-950 border-opacity-10 w-full appearance-none px-3 py-3.5 leading-tight focus:outline-none focus:shadow-outline",
                {
                  "border-rose-500 focus:border-rose-500": errors,
                }
              )}>
              <option value="">{placeholder}</option>
              {children}
            </select>
            <span className="absolute right-4 inset-y-0 flex items-center pointer-events-none">
              <ChevronDown />
            </span>
          </div>
        </div>
        {errors && (
          <div className="text-red-400 text-xs px-1.5">{errors.message}</div>
        )}
      </div>
    );
  }
);

NativeSelect.displayName = "NativeSelect";

export default NativeSelect;
