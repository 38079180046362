import LargeLogo from "@/assets/images/logo_large.svg";
import BgLogin from "@/assets/images/bg_login.webp";
import Service from "@/lib/services/request";
import { forgotPasswordSchema } from "@/lib/services/validators/auth-validator";
import { ErrorProps } from "@/lib/types/error";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "@/lib/common/components/input";
import Button from "@/lib/common/components/button";
import { Link, useNavigate } from "@tanstack/react-router";
import Tawk from "@/lib/common/components/tawk";
import { AppContext } from "@/lib/contexts/App.context";

interface FormProps {
  username: string;
}

function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AppContext);

  useEffect(() => {
    if (token) {
      navigate({ to: "/dashboard" });
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const onSubmit = async (data: FormProps) => {
    setLoading(true);

    try {
      const res = await Service.post(Service.ForgotPassword, data);
      if(res.data) {
        toast(res.data.message, {
          type: "success",
        });
      }
      
      return navigate({ to: "/auth/login" });
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
    <div className="w-full flex-wrap min-h-screen flex items-stretch">
      <div className="w-1/2 hidden lg:block relative bg-no-repeat bg-cover items-center p-4">
        <div
          className=" relative bg-no-repeat bg-contain h-full w-full bg-zinc-100 rounded-[10px]"
          style={{
            backgroundImage: `url(${BgLogin})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left",
          }}
        />
        <Link to="/" className="top-8 absolute left-12">
          <img  src={LargeLogo} alt="Logo" />
        </Link>
        
      </div>

      <div className="w-full md:w-1/2 flex flex-col">

            
        <div className="flex flex-col justify-start md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">


      <a href="https://www.meritrade.com" className="block md:hidden top-8 absolute left-8">
              <img src={LargeLogo} alt="Logo" />
            </a>
            
          <h3 className="pb-4  text-stone-950 dark:text-[#f3f3f3] text-[32px] font-semibold ">
            <span className="capitalize">Forgot </span>
            <span className="capitalize"> Password</span>
          </h3>

          <p className="text-neutral-700 text-[15px] font-normal ">
            <span className="capitalize">Enter </span>
            <span className="lowercase">your username and a token will be sent to your registered email address</span>
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col pt-3 md:pt-8">
            <div className="pt-4">
              <Input
                {...register("username")}
                errors={errors.username}
                required
                type="text"
                label="Username"
              />
            </div>

          
          

            <div className="pt-10">
              <Button
                className="w-full"
                disabled={loading}
                isLoading={loading}
                type="submit">
                Send a token
              </Button>
            </div>
          </form>
          <div className="text-center pt-3 pb-12">
            <p className="text-neutral-700 text-sm font-normal">
              Already have an account?{" "}
              <Link to="/auth/login" className="text-green-900 text-sm font-medium">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
      <Tawk/></>
  );
}

export default ForgotPassword;


