import Eye from "../../icons/eye";
import EyeOff from "../../icons/eye-off";
import clsx from "clsx";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import VMasker from "vanilla-masker";

type InputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "placeholder"
> & {
  label?: string;
  errors?: FieldError;
  touched?: Record<string, unknown>;
  name: string;
  placeholder?: string;
  className?: string;
  mask?: {
    values: string | string[];
    maxLength: number;
  };
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      name,
      label,
      mask,
      placeholder,
      errors,
      className,
      required,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [showPassword, setShowPassword] = useState(false);
    const [inputType, setInputType] = useState(type);

    const inputHandler = (
      masks: string | string[],
      max: number,
      event: Event
    ): void => {
      const c = event.target as HTMLInputElement;
      const v = c.value.replace(/\D/g, "");
      const m = masks.length > 1 && c.value.length > max ? 1 : 0;
      VMasker(c).unMask();
      VMasker(c).maskPattern(masks[m]);
      c.value = VMasker.toPattern(v, masks[m]);
    };
    useEffect(() => {
      if (type === "password" && showPassword) {
        setInputType("text");
      }

      if (type === "password" && !showPassword) {
        setInputType("password");
      }
      if (mask) {
        if (!name) return;
        const inputRef = document.getElementById(name);

        if (inputRef) {
          inputRef.addEventListener(
            "input",
            (e) => inputHandler(mask.values, mask.maxLength, e),
            false
          );
        }
      }
    }, [type, showPassword, mask, ref, name]);

    useImperativeHandle(ref, () => inputRef.current!);

    // const hasError = get(errors, name) && get(touched, name);

    return (
      <div>
        <div className="relative z-0 w-full text-base-regular">
          {label && (
            <label
              onClick={() => inputRef.current?.focus()}
              className={clsx(
                `transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5 ${className}`,
                {
                  "!text-rose-500": errors,
                }
              )}
              htmlFor={name}>
              {label} {required ? "*" : ""}
            </label>
          )}
         
          <input
            type={inputType}
            name={name}
            placeholder={placeholder}
            className={clsx(
              " mt-1.5 rounded-md bg-white dark:bg-[#ffffff] border border-stone-950 border-opacity-10 w-full appearance-none px-3 py-3.5 leading-tight focus:outline-none focus:shadow-outline",
              {
                "border-rose-500 focus:border-rose-500": errors,
              }
            )}
            {...props}
            ref={inputRef}
          />

          {type === "password" && (
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-gray-400 px-4 focus:outline-none transition-all duration-150 outline-none focus:text-gray-700 absolute right-0 top-10">
              {showPassword ? <Eye /> : <EyeOff />}
            </button>
          )}
        </div>

        {/* {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => {
              return (
                <div className="text-red-400 text-xs px-1.5">
                  <span>{message}</span>
                </div>
              )
            }}
          />
        )} */}

        {errors && <div className="text-red-400 text-xs px-1.5">{errors.message}</div>}

      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
