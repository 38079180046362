import * as Yup from "yup";

export const cardDepositSchema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(0)
    .typeError("must be numeric")
    .integer()
    .positive()
    .required("Amount is required"),
  accountId: Yup.string().required("Account to fund is required"),
});

export const cardWithdrawSchema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(0)
    .typeError("must be numeric")
    .integer()
    .positive()
    .required("Amount is required"),
});

export const watchlistSchema = Yup.object().shape({
  trigger_price: Yup.number()
    // .moreThan(0)
    .typeError("must be numeric")
    // .integer()
    // .positive()
    .required("Price is required"),
});
