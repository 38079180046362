import { AssetProps } from "@/lib/types/global";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';

interface Props {
    data: AssetProps[]
}


function AssetAllocationChart({ data }: Props) {
  NoDataToDisplay(Highcharts);


  const getChartData = (data: AssetProps[]) => {
    return data && data.map(function (row) {
      return {
        name: row.title,
        y: row.asset_value,
      };
    });
  };

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      backgroundColor: 'transparent',
    },
    credits: false,
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        borderRadius: 5,
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    series: [
      {
        name: "Asset Allocations",
        data: getChartData(data),
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default AssetAllocationChart;
