import { useState } from "react";
import useSWR from "swr";

export default function usePortfolioSWR() {
  const [portfolioId, setPortfolioId] = useState<number | undefined>();

  const handleChange = (e: number | undefined) => {
    setPortfolioId(e);
  };

  const { data, error, isLoading } = useSWR(
    portfolioId ? `portfolio/portfolioHoldings/${portfolioId}` : null
  );
  return {
    data,
    handleChange,
    portfolioId,
    setPortfolioId,
    error,
    isLoading,
  };
}
