import { usePagination, PaginationItemType } from "@nextui-org/react";
import clsx from "clsx";
import { SetStateAction } from "react";

interface Props {
    isCompact: boolean,
    total: number,
    showControls: boolean,
    showShadow: boolean,
    page: number,
    onChange: (page: SetStateAction<number>) => void,
}

export default function CustomPagination(prop: Props) {
  const { total, activePage, range, setPage, onNext, onPrevious } = usePagination(prop)


  return (
  
     
      <ul className="justify-center items-center  flex">
        {range.map((page) => {
          if (page === PaginationItemType.NEXT) {
            return (
              <li key={page} aria-label="next page" >
                <button
                disabled={activePage === total}
                className={clsx(
                    "text-sm font-semibold  ",
                    {
                      "text-stone-300 ": activePage === total,
                    },
                    {
                      "text-zinc-800 ": activePage !== total,
                    }
                  )}
                  onClick={onNext}>
                  Next
                </button>
              </li>
            );
          }

          if (page === PaginationItemType.PREV) {
            return (
              <li key={page} aria-label="previous page">
                <button
                  disabled={activePage === 1}
                  className={clsx(
                    "text-sm font-semibold",
                    {
                      "text-stone-300 ": activePage === 1,
                    },
                    {
                      "text-zinc-800 ": activePage !== 1,
                    }
                  )}
                  onClick={onPrevious}>
                  Prev
                </button>
              </li>
            );
          }

          if (page === PaginationItemType.DOTS) {
            return (
              <li key={page} className="py-2.5 px-3.5  flex-col justify-center items-center flex bg-white rounded-lg border border-zinc-100">
                ...
              </li>
            );
          }

          return (
            <li key={page} aria-label={`page ${page}`}  className="p-1.5  bg-white rounded-lg flex-col justify-center items-center flex">
              <button
                className={clsx(" rounded-lg py-2.5 px-4 ", {
                  "bg-green-900 text-white": activePage === page,
                },
                {
                    "bg-white rounded-lg border border-zinc-100": activePage !== page,
                  }
                  )}
                onClick={() => setPage(page)}>
                {page}
              </button>
            </li>
          );
        })}
      </ul>
    
  );
}
