import DashboardFundBg from "@/assets/images/dashboard_fund.png";
import LargeLogo from "@/assets/images/logo_large.svg";
import { formatMoney } from "@/lib/library";

interface CashAccount {
  availableBalance: number;
  totalCashBalance: number;
  totalCashBalTitle?: string;
}
function CashAccountCard({totalCashBalTitle, availableBalance, totalCashBalance}: CashAccount) {

 
  return (
    <div
      style={{
        backgroundImage: `url(${DashboardFundBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundPosition: "center center",
      }}
      className="p-6 ">
      <div className="pb-10 justify-between items-center flex">
        <img className="w-[96.57px] h-6" src={LargeLogo} />
        <div className=" text-stone-950 dark:text-[#f3f3f3] text-base font-semibold capitalize">
          Funds
        </div>
      </div>

      <div className="flex-col justify-start items-start gap-10 inline-flex">
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-neutral-700 text-xs font-medium capitalize">
            Available balance
          </div>
          <div className=" text-stone-950 dark:text-[#f3f3f3] text-2xl font-bold capitalize">
            {formatMoney(availableBalance)}
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-neutral-700 text-xs font-medium capitalize">
            {totalCashBalTitle ? totalCashBalTitle : 'Total Cash balance'}
          </div>
          <div className=" text-stone-950 dark:text-[#f3f3f3] text-base font-bold capitalize">
          {formatMoney(totalCashBalance)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CashAccountCard;
