import { AppContext } from "@/lib/contexts/App.context";
import { useContext } from "react";
import UpdateProfile from "./components/profile/update-details";
import ProfileDetails from "./components/profile/profile-details";

export default function ChangePassword() {
  const { user } = useContext(AppContext);

  return (
    <>
      { (!user?.kycStatus && user?.kycStatus != "APPROVED_DOCUMENTS") ? <UpdateProfile /> : <ProfileDetails />}
    </>
  );
}
