import SecurityChart from "../order/components/SecurityChart";
import { useState } from "react";
import Button from "@/lib/common/components/button";
import AddOrRemoveSecModal from "./components/AddOrRemoveSecModal";
import { SecContent } from "@/lib/types/global";
import MoreIcon from "@/assets/images/icons/more.svg";

function Research() {
  const [mutliple, setMutliple] = useState<SecContent[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [key, setKey] = useState<number>(0);

  return (
    <>
      {isOpen && (
        <AddOrRemoveSecModal
          setIsOpen={setIsOpen}
          setChange={(r) => {
            setMutliple(r);
            setKey(key + 1);
          }}
        />
      )}
      <div className="w-full">
        {mutliple.length > 0 && (
          <div className="mb-6 flex items-center justify-between ">
            <div></div>
            <Button
              onClick={() => {
                setIsOpen(true);
              }}>
              Add or Remove Security
            </Button>
          </div>
        )}

        {mutliple.length > 0 ? (
          <div className="mb-6 pt-6 bg-white rounded-md border border-stone-950 border-opacity-10">
            <div className="pl-4 pb-5 justify-start items-center gap-2 inline-flex">
              {mutliple.slice(0, 4).map((row: SecContent) => {
                return (
                  <div
                    style={{ color: row.color }}
                    className={` text-xs font-bold capitalize`}>
                    {row.name}
                  </div>
                );
              })}

              {mutliple.length > 4 && <img src={MoreIcon} />}
            </div>

            <SecurityChart mutliple={mutliple} type="compare" key={key} />
          </div>
        ) : (
          <div className="flex-col flex justify-center items-center pt-24 gap-5">
            <div className="text-center text-stone-950  dark:text-[#f3f3f3] text-base font-bold font-['Inter'] capitalize">
            A Side-by-Side Comparison
            </div>
            <div className="w-[405px] text-center text-neutral-500 text-sm font-normal font-['Inter'] leading-tight">
            Get a clear picture of different stocks with our side-by-side comparison, revealing their performance, strengths, and weaknesses
            </div>
            <Button
              onClick={() => {
                setIsOpen(true);
              }}>
              Compare Stocks
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default Research;
