import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "@tanstack/react-router";
import { router } from "./routes";

import "./assets/scss/index.scss";
import "reactjs-popup/dist/index.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SWRProvider } from "./lib/contexts/SWR.context";
import { AppProvider } from "./lib/contexts/App.context";
import { ModalProvider } from "./lib/contexts/modal-context";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { NextUIProvider } from "@nextui-org/react";
import { Provider } from "react-redux";
import { store, persistor } from "./lib/store";
import nightwind from "nightwind/helper";
import * as Sentry from "@sentry/react";
import { PersistGate } from "redux-persist/integration/react";
import { CacheBuster } from "@piplup/cache-buster";

Sentry.init({
  dsn: "https://ce4b0691fab17129439358f91dea55a3@o4507265630470144.ingest.us.sentry.io/4507265640497152",
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  tracesSampleRate: 1.0,
  _experiments: {
    // profilesSampleRate is relative to tracesSampleRate.
    // Here, we'll capture profiles for 100% of transactions.
    profilesSampleRate: 1.0,
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <CacheBuster
      loading={null} // Optional: Add a loading component to display loading.
      verbose={false} // Optional: If true, logs will be visible.
      storageKey="RELEASE" // Optional: local storage key for storing the unique release ID.
      enabled={process.env.NODE_ENV === "production"}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppProvider>
            <NextUIProvider>
              <ModalProvider>
                <HelmetProvider>
                  <Helmet>
                    <script>{nightwind.init()}</script>
                  </Helmet>
                  <SWRProvider>
                    <RouterProvider router={router} />
                    <ToastContainer
                      position="top-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </SWRProvider>
                </HelmetProvider>
              </ModalProvider>
            </NextUIProvider>
          </AppProvider>
        </PersistGate>
      </Provider>
    </CacheBuster>
  </React.StrictMode>
);
