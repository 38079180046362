import Tooltip from "@/lib/common/components/tooltip";
import Info from "@/lib/common/icons/info";
import { formatMoney } from "@/lib/library";
// import { Tooltip } from "@nextui-org/react";

interface Props {
  title: string;
  amount: number | null | undefined;
  info?: string;
}

function CashInfo({ title, amount, info }: Props) {
  return (
    <div className="px-2 md:px-4 py-[8.5px] md:py-[17px] hover:bg-green-900 hover:bg-opacity-10 border border-stone-950 border-opacity-10 flex-col justify-start items-start gap-2.5 inline-flex">
      <div className="flex-col justify-center items-start gap-2 md:gap-4 flex">
        <div className="justify-center items-center gap-2 inline-flex">
          <div className=" text-neutral-500 text-[10px] md:text-xs font-medium uppercase">
            {title}
          </div>
          <div className="w-3.5 h-3.5 relative">
            {
              info && <Tooltip message={info}><Info/></Tooltip>
            }
            
          </div>
        </div>
        <div className="text-black text-sm md:text-base font-semibold">

          {formatMoney(amount ?? 0)}
        </div>
      </div>
    </div>
  );
}

export default CashInfo;
