// import { AxiosResponse } from "axios";
import apiRequest from './api.ts';

export default class Service {
  static StoreToken = 'StoreAccessToken';
  static StoreUser = 'StoreUser';
  static StoreRegisterUser = 'StoreRegisterUser';


  static Login = 'authentication';
  static Register = 'new_account';
  static ValidateUsername = 'validate/username';

  
  static ForgotPassword = 'forgotPassword';
  static AssetAllocation = 'findCustomerAssetAllocation';
  static ActiveUser = 'loadActiveUser';
  static CustomerPortfolios = 'findCustomerPortfolios';
  static FinAccount = 'findCustomerFiAccts';
  static virtualAccount = 'virtual-account';

  static TradeBalance = 'findCustomerTradeBalanceById';
   static ClearedCustomerTradeBalance = 'findClearedCustomerTradeBalanceById';
  static TradeOrderTerms = 'findActiveTradeOrderTerms';
  static ChangePassword = 'changePassword';
  static UpdateNextDetails = 'profile/updateNextofKinDetails';
  static UpdateProfileDetails = 'profile/updateProfileDetails';

  static UpdateProfileSetting = 'profile/settings';

  static CreatePaymentPartnerCashTransaction = 'createPaymentPartnerCashTransaction';

  

  //trades
  static Securitydata = 'securitydata';
  static ValidateTradeOrder = 'getTradeOrderTotal';
  static CreateTradeOrder = 'createTradeOrder';
  static CancelTradeOrder = 'cancelTradeOrder';

  static CustomerBalanceSummary = 'portfolio/customerBalanceSummary';
  static TransactionHistory = 'portfolio/transactionHistory';
  static MarketMovers = 'security/market_mover';
  static SecurityNews = 'security/news';


  static ContractNote = 'statement/pdf/contract_note/';
  static CashStatement = 'statement/pdf/cash_statement/';

  static WatchlistList = 'watchlist';

  static Referral = 'profile/referral';




  static async get(url: string, options?: object) {
    const r = await apiRequest.get(url, options);
    return r;
  }

  static async post(url: string, data?: object, options?: object) {
    const r = await apiRequest.post(url, data, options);
    return r;
  }

  static async put(url: string, data: object) {
    const r = await apiRequest.put(url, data);
    return r;
  }

  static async delete(url: string) {
    const r = await apiRequest.delete(url);
    return r;
  }
}
