import PageNavBar from "../layout/PageNavBar";
import CashAccountCard from "../dashboard/components/CashAccountCard";
import TransactionCard from "../dashboard/components/card/transaction_card";
import Service from "@/lib/services/request";
import useSWR from "swr";
import CardTemplate from "../dashboard/components/card";
import bankAccounts from "@/lib/utils/payment-bank";
import { Key, useContext, useEffect, useState } from "react";
import Input from "@/lib/common/components/input";
import Button from "@/lib/common/components/button";
import { Account } from "@/lib/services/class/account";
import { cardDepositSchema } from "@/lib/services/validators/fund-validator";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DepositProps } from "@/lib/types/global";
import DepositSummary from "./components/DepositSummary";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import accountSelector from "@/lib/store/features/account/accountSelector";
import accountOperations from "@/lib/store/features/account/accountOperations";
import NativeSelect from "@/lib/common/components/select";
import { ThunkDispatch } from "@reduxjs/toolkit";
import VirtualAccount from "./components/VirtualAccount";
import { AppContext } from "@/lib/contexts/App.context";

interface Props {
  title: string;
  image: string;
  bank: string;
}

export default function FundDeposit() {
  const accounts = useSWR(Service.FinAccount);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [depositModal, setDepsoitModal] = useState<DepositProps>();
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const { user } = useContext(AppContext);

  const accountData = useSelector<RootState, Account[]>(
    accountSelector.getAccount
  );

  useEffect(() => {
    if (accountData && accountData.length > 0 && !selectedAccount) {
      setSelectedAccount(accountData[0]);
      setValue("accountId", String(accountData[0].id));
    }
  }, [accountData]);

  useEffect(() => {
    dispatch(accountOperations.fetchAccounts());
  }, [dispatch]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<DepositProps>({
    resolver: yupResolver(cardDepositSchema),
  });

  const onSubmit = async (data: DepositProps) => {
    setIsOpen(true);
    setDepsoitModal(data);
    return null;
  };

  return (
    <>
      {isOpen && (
        <DepositSummary
          setIsOpen={setIsOpen}
          deposit={depositModal}
          onSuccessRes={async () => {
            setTimeout(() => {
              accounts.mutate(Service.FinAccount);
            }, 2000);
          }}
        />
      )}
      <PageNavBar
        title="Deposit Funds"
        primary="Fund Management"
        showTradeNowButton={true}
      />
      <div className="px-4 grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 lg:gap-6 md:gap-6 gap-0">
        <div className="col-span-4">
          <CashAccountCard
            availableBalance={
              selectedAccount != undefined && selectedAccount.clearedBalance
                ? selectedAccount.clearedBalance
                : 0
            }
            totalCashBalTitle="Total Balance"
            totalCashBalance={
              selectedAccount != undefined && selectedAccount.unClearedBalance
                ? selectedAccount.unClearedBalance
                : 0
            }
          />
          <div className="pt-4">
            <TransactionCard />
          </div>
        </div>

        <div className="col-span-5 h-max pb-6 flex flex-col gap-5">
          <VirtualAccount />

          <div className="flex justify-center items-center gap-2">
            <div className="border-[1px] border-[#0E0E0E0D] w-full"></div>
            <div className="text-[#0E0E0E] font-semibold leading-[19.36px] text-[16px]">
              OR
            </div>
            <div className="border-[1px] border-[#0E0E0E0D] w-full"></div>
          </div>

          <CardTemplate title="Fund Deposit" showBorder={false}>
            <form className="px-6 pb-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <NativeSelect
                  {...register("accountId")}
                  errors={errors.accountId}
                  label="Select your acoount "
                  onChange={(e) => {
                    const account = accountData.find(
                      (element: Account) => element.id == Number(e.target.value)
                    );
                    setSelectedAccount(account);
                  }}
                >
                  {accountData.map((item: Account) => {
                    return (
                      <option key={item.id} value={item.id ?? ""}>
                        {item.label}
                      </option>
                    );
                  })}
                </NativeSelect>

                {/* <SearchSelect
                {...register("accountId")}
                errors={errors.accountId}
                label="Select your acoount "
                defaultValue={getValues("accountId")}
                placeholder=" "
                options={
                  accountData != undefined &&
                  accountData.map((item: Account) => {
                    return { name: item.label, value: item.id };
                  })
                }
                onChanging={(r) => {
                  const account = accountData.find(
                    (element: Account) => element.id == r
                  );
                  setSelectedAccount(account);

                  setValue("accountId", r.toString());
                }}
              /> */}
              </div>

              <div className="mb-4 w-full">
                <div>
                  <span className="text-neutral-500 text-xs font-medium font-['Inter'] capitalize">
                    Enter Amount{" "}
                  </span>
                  <span className="text-red-600 text-xs font-medium font-['Inter'] capitalize">
                    (Merchant charges apply)
                  </span>
                </div>
                <Input
                  {...register("amount")}
                  errors={errors.amount}
                  required
                />
              </div>

              <Button className="w-44" type="submit">
                Continue
              </Button>
            </form>
          </CardTemplate>
        </div>

        <CardTemplate
          className="col-span-3 h-max "
          title="Pay at bank"
          showBorder={false}
        >
          <div className="pb-6">
            <table className="min-w-full h-auto table-auto w-full">
              <thead className="[&>tr]:first:rounded-lg">
                <tr className="group outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2">
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase"></th>
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    BANK
                  </th>
                  <th className=" text-start p-3 bg-green-900 bg-opacity-5 text-neutral-500 text-xs font-semibold uppercase">
                    ACCOUNT NO.
                  </th>
                </tr>
              </thead>
              <tbody>
                {bankAccounts.map(
                  (row: Props, index: Key | null | undefined) => {
                    return (
                      <tr key={index} className="">
                        <td className="px-3">
                          <img src={row.image} />
                        </td>
                        <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                          {row.title}
                        </td>
                        <td className="px-3 relative align-middle whitespace-normal text-small font-normal [&>*]:z-1 [&>*]:relative outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 before:content-[''] before:absolute before:z-0 before:inset-0 before:opacity-0 data-[selected=true]:before:opacity-100 group-data-[disabled=true]:text-foreground-300 before:bg-default/40 data-[selected=true]:text-default-foreground first:before:rounded-l-lg last:before:rounded-r-lg border-b border-black border-opacity-5 py-3 group-data-[first=true]:first:before:rounded-none group-data-[first=true]:last:before:rounded-none group-data-[middle=true]:before:rounded-none group-data-[last=true]:first:before:rounded-none group-data-[last=true]:last:before:rounded-none">
                          {row.bank}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>

            <p className="font-normal text-[14px] leading-[20px] p-6">
              Use your Meritrade Account Number:&nbsp;
              <span className="font-bold"> {user?.name}</span> as the reference
              number.
            </p>
          </div>
        </CardTemplate>
      </div>
    </>
  );
}
