import moment from "moment";
import { useState } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import useSWR from "swr";
import { formatDate } from "../library";

export default function useCashStatementSWR() {
  const [account, setAccount] = useState<number| undefined>();

  const [dateRange, setDateRange] = useState<DateValueType>(() => {
    const d = new Date();
    return {
      startDate: moment().subtract(36, "months").toDate(),
      endDate: d,
    };
  });

  const handleChangeDate = (e: DateValueType) => {
    if (e?.startDate != null && e.endDate != null) {
      setDateRange(e);
    }
  };

  const handleChangeAccount = (e: number) => {
    setAccount(e);
  };

  const { data, error, isLoading } = useSWR(
    account ? `statement/cash_statement/${account}?start_date=${formatDate(
      dateRange?.startDate, 
      "YYYY-MM-DD"
    )}&end_date=${formatDate(dateRange?.endDate, "YYYY-MM-DD")}` : null
  );
  return {
    data,
    handleChangeDate,
    account,
    handleChangeAccount,
    dateRange,
    setAccount,
    setDateRange,
    error,
    isLoading,
  };
}
