import React,{useCallback} from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Spinner,
} from "@nextui-org/react";

import NoContent from "@/lib/common/components/table/NoContent";
import {formatNum} from "@/lib/library";
import TableCss from "@/lib/utils/table-css";
import {TradeOfferBid} from "@/lib/services/class/tradeOfferBid";
import {Link} from "@tanstack/react-router";

interface Props {
  data: TradeOfferBid[];
  isLoading: boolean;
  action?: "BUY"|"SELL";
  symbol: string;
}
export default function BidOfferTable({
  symbol,
  action,
  data,
  isLoading,
}: Props) {
  const renderCell=useCallback(
    (portfolio: TradeOfferBid,columnKey: React.Key) => {
      const cellValue=portfolio[columnKey as keyof TradeOfferBid];

      switch(columnKey) {
      case "price":
        return formatNum(cellValue);
      case "qty":
        return formatNum(cellValue,0);
      case "action":

        if(action=="BUY") {
          return <Link
            to="/dashboard/orders/create"
            search={{symbol,orderType: "BUY",limitPrice: portfolio.price}}
            className="w-[70px] h-[31px] px-6 py-2 bg-emerald-50 rounded-sm justify-center items-center gap-2.5 inline-flex">
            <span className="text-emerald-500 text-xs font-semibold uppercase">
              BUY
            </span>
          </Link>
        } else if(action=="SELL") {
          return <Link
            to="/dashboard/orders/create"
            search={{
              symbol,
              orderType: "SELL",
              limitPrice: portfolio.price,
            }}
            className="w-[70px] h-[31px] px-6 py-2 bg-rose-100 rounded-sm justify-center items-center gap-2.5 inline-flex">
            <span className="text-red-600 text-xs font-semibold uppercase">
              SELL
            </span>
          </Link>
        }
        return ""


      default:
        return cellValue;
      }
    },
    []
  );

  const loadingState=isLoading? "loading":"idle";

  const classNames=React.useMemo(() => TableCss,[]);

  return (
    <div className="h-80 overflow-auto">
      <Table
        aria-label="Example table with client async pagination"
        classNames={classNames}>
          {
            action == "BUY" ? <TableHeader>
            <TableColumn key="price">Price(₦)</TableColumn>
            <TableColumn key="qty">QUANTITY</TableColumn>
            <TableColumn key="count">COUNT</TableColumn>
            <TableColumn key="action">ACTION</TableColumn>
          </TableHeader> : <TableHeader>
            <TableColumn key="price">Price(₦)</TableColumn>
            <TableColumn key="qty">QUANTITY</TableColumn>
            <TableColumn key="count">COUNT</TableColumn>
          </TableHeader>
          }
        
        <TableBody<TradeOfferBid>
          emptyContent={<NoContent />}
          isLoading={isLoading}
          items={data!=undefined? data:[]}
          loadingContent={<Spinner />}
          loadingState={loadingState}>
          {(item: TradeOfferBid) => {
            return (
              <TableRow key={item.price??""+item.qty??""}>
                {(columnKey) => (
                  <TableCell>{renderCell(item,columnKey)}</TableCell>
                )}
              </TableRow>
            );
          }}
        </TableBody>
      </Table>
    </div>
  );
}
