import { IconProps } from "@/lib/types/icon";
import React from "react";

const Info: React.FC<IconProps> = ({

  ...attributes
}) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}>
      <path
        d="M14.431 7.50059C14.431 3.9487 11.5516 1.06934 7.99973 1.06934C4.44785 1.06934 1.56848 3.9487 1.56848 7.50059C1.56848 11.0525 4.44785 13.9318 7.99973 13.9318C11.5516 13.9318 14.431 11.0525 14.431 7.50059Z"
        fill="#144D33"
        stroke="#144D33"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M8 4.4375V8.375"
        stroke="#F9F9F9"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="8" cy="10.3438" r="0.65625" fill="#F9F9F9" />
    </svg>
  );
};

export default Info;
