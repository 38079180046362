import { useContext } from 'react';

import { Navigate, Outlet } from '@tanstack/react-router'
import { AppContext } from '../lib/contexts/App.context.tsx';
import DashboardLayout from '@/pages/dashboard/layout/DashboardLayout.tsx';
import Service from '@/lib/services/request.ts';
import DataCache from '@/lib/DataCache.ts';

export function UnprotectedRoute() {
  const { token } = useContext(AppContext);


  return token ? <Navigate to="/dashboard" /> : <Outlet />;
}

export function  Root() {
  return (
    <>
      <Outlet />
    </>
  );
}

export function ProtectedRoute() {
  const token = DataCache.getItem(Service.StoreToken);
  return token ? <DashboardLayout><Outlet /></DashboardLayout> : <Navigate to="/auth/login" />;
}




