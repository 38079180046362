import researcMenu from "@/lib/utils/research-menu";
import { useNavigate } from "@tanstack/react-router";
import clsx from "clsx";
import { useEffect, useState } from "react";

function ResearchMenu() {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState("");
  const isExist = () => {
    for (let i = 0; i < researcMenu.length; i++) {
      if (researcMenu[i].link == window.location.pathname) return true;
    }
    return false;
  };

  useEffect(() => {
    if (isExist()) {
      setActiveLink(window.location.pathname);
    } else {
      setActiveLink(researcMenu[0].link);
    }
  }, []);
  return (
    <div className="mb-4 p-2 bg-white rounded-lg border border-stone-950 border-opacity-10 justify-center items-center gap-2 inline-flex">
      {researcMenu.map(({ link, title, Image }, index) => (
        <div
          key={index}
          onClick={() => {
            setActiveLink(link);
            navigate({ to: link });
          }}
          className={clsx(
            "p-2 justify-center items-center gap-2.5 flex cursor-pointer",

            {
              " bg-green-900 rounded flex-col items-center gap-2.5":
                activeLink == link,
            }
          )}>
          <div
            className={clsx(
              "flex justify-center items-center gap-2 text-sm font-medium capitalize text-center",
              {
                "text-stone-950  dark:text-[#f3f3f3]": activeLink != link,
              },
              {
                "text-stone-50": activeLink == link,
              }
            )}>
            <Image
              className={clsx(
                "",
                {
                  "text-stone-50": activeLink == link,
                },
                {
                  "text-stone-950 dark:text-[#f3f3f3]": activeLink != link,
                }
              )}
            />{" "}
            {title}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ResearchMenu;
