import cancelButton from "@/assets/images/icons/cancel.svg";
import Button from "@/lib/common/components/button";
import { formatMoney, formatNum } from "@/lib/library";
import Service from "@/lib/services/request";
import { ErrorProps } from "@/lib/types/error";
import { TradeOrderSummaryProps } from "@/lib/types/global";
import { toast } from "react-toastify";
import useSWR from "swr";
import SuccessfulAction from "@/assets/images/successful_action.svg";
import { useState } from "react";
import { Link } from "@tanstack/react-router";
import { EventBus } from "@/lib/EventBus";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tradeOrder?: TradeOrderSummaryProps;
}
export default function OrderModal({ tradeOrder, setIsOpen }: Props) {
  const balance = useSWR(Service.CustomerBalanceSummary);
  const [isPosted, setIsPosted] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const res = await Service.post(Service.CreateTradeOrder, tradeOrder);

      if (res) {
        if (res.data.error) {
          toast(res.data.error, {
            type: "error",
          });
        } else {
          setIsPosted(true);
          EventBus.getInstance().dispatch<string>('tradeOrder', 'reload');
        }
      }
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  return (
    <div
      className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
      style={{ zIndex: 100 }}>
      <div className="my-10 flex h-full md:h-auto items-center justify-center  text-center ">
        <div
          className="
             bg-stone-50 rounded-[10px] flex-col justify-center items-start gap-6 inline-flex
            w-full 
            md:w-[611px] mx-auto  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
          {isPosted ? (
            <>
              <div className="w-full h-[410px] bg-stone-50 rounded-[10px] relative">
                <div
                  className="cursor-pointer w-10 h-10 right-5 top-5 absolute bg-black bg-opacity-5 rounded"
                  onClick={() => setIsOpen(false)}>
                  <img src={cancelButton} />
                </div>
                <div className="py-10 bg-green-900 bg-opacity-10 flex justify-center items-center">
                  <img src={SuccessfulAction} />
                </div>
                <div className="flex-col flex justify-center items-center gap-7 pt-8">
                  <div className="text-center  text-stone-950 dark:text-[#f3f3f3] text-2xl font-semibold font-['Inter'] capitalize">
                    Order Submitted Successfully
                  </div>
                  <div className="text-center text-neutral-700 text-sm font-normal font-['Inter'] leading-tight">
                    Your mandate has been submitted. 
                  </div>

                  <div className="flex justify-between items-center gap-3">
                    <Link to="/dashboard/orders" className="w-full">
                      <Button variant="primary-outline" className="w-full">
                        My Trade orders
                      </Button>
                    </Link>
                    <Button
                      onClick={() => setIsOpen(false)}
                      variant="primary-outline"
                      className="w-full">
                      Close
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer w-10 h-10  right-5 top-5 absolute bg-black bg-opacity-5 rounded"
                onClick={() => setIsOpen(false)}>
                <img src={cancelButton} />
              </div>
            </>
          ) : (
            <div className="p-6 w-full">
              <div className="pb-4 w-full justify-between items-center inline-flex">
                <div className=" text-stone-950 dark:text-[#f3f3f3] text-xl font-semibold font-['Inter']">
                  Confirm New trade order
                </div>

                <div
                  className="cursor-pointer w-10 h-10 relative bg-black bg-opacity-5 rounded"
                  onClick={() => setIsOpen(false)}>
                  <img src={cancelButton} />
                </div>
              </div>

              <table className="text-start rounded-md border border-black border-opacity-5 w-full">
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Available Balance
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {balance != undefined &&
                    balance.data != undefined &&
                    balance.data.data
                      ? formatMoney(balance.data.data.availableBalance)
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Total Balance
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {balance != undefined &&
                    balance.data != undefined &&
                    balance.data.data
                      ? formatMoney(balance.data.data.totalCashBalance)
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Portfolio
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? tradeOrder.portfolio : ""}
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Symbol
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? tradeOrder.security : ""}
                  </td>
                </tr>

                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Order Type
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? tradeOrder.orderType : ""}
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Quantity
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? formatNum(tradeOrder.quantity, 0) : ""}
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Price Type
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? tradeOrder.priceType : ""}
                  </td>
                </tr>
                <tr>
                  <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Order Term
                  </td>
                  <td
                    className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? tradeOrder.orderTerm : ""}
                  </td>
                </tr>
                {tradeOrder && tradeOrder.limitPrice && (
                  <tr>
                    <td className="py-3 px-2 w-1/2 border border-black border-opacity-5">
                      Limit Price
                    </td>
                    <td
                      className="
               text-stone-950 dark:text-[#f3f3f3] text-sm font-medium
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                      {tradeOrder ? formatMoney(tradeOrder.limitPrice) : ""}
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    className="
              bg-green-900 bg-opacity-10  text-stone-950 dark:text-[#f3f3f3] text-sm font-medium 
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    Total Order
                  </td>
                  <td
                    className="
              bg-green-900 bg-opacity-10  text-stone-950 dark:text-[#f3f3f3] text-base font-semibold
              py-3 px-2 w-1/2 border border-black border-opacity-5">
                    {tradeOrder ? formatMoney(tradeOrder.amount) : ""}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className="py-3 px-2  flex justify-between items-center">
                      <Button
                        onClick={() => setIsOpen(false)}
                        variant="primary-outline">
                        Cancel
                      </Button>
                      <Button
                        disabled={loading}
                        isLoading={loading}
                        onClick={() => onSubmit()}>
                        Submit
                      </Button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
