import { formatNum, getSymbolImage } from "@/lib/library";
import { Security } from "@/lib/services/class/security";
import { useNavigate } from "@tanstack/react-router";
import Marquee from "react-fast-marquee";
import SecurityInfo from "../order/components/SecurityInfo";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RootState } from "@/lib/store";
import { useDispatch, useSelector } from "react-redux";
import securitySelector from "@/lib/store/features/security/securitySelector";
import securityOperations from "@/lib/store/features/security/securityOperations";
import { useEffect } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";

function StockTicker() {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const mostActive = useSelector<RootState, Security[]>(
    securitySelector.getMostActive
  );

  useEffect(() => {
    dispatch(securityOperations.fetchMostActive());
  }, [dispatch]);

  return (
    <Marquee speed={15} pauseOnHover={true}>
      <div className="pl-10 flex gap-10 justify-start items-center">
      {mostActive != undefined && mostActive.length > 0 && mostActive.map((row: Security, index: number) => {
        return (
          <div 
          onClick={() => {
            navigate({
              to: "/dashboard/orders/create",
              search: {
                symbol: row.symbol,
                limitPrice: row.lastPx,
              },
            });
          }}
          key={index} className="cursor-pointer justify-start items-center gap-2 flex">

            <SecurityInfo symbol={row.symbol}>
            <div className="justify-start items-center gap-2 flex">
              <LazyLoadImage
                className="w-[25px] h-[25px] rounded-full"
                src={getSymbolImage(row.symbol)}
              />
              <div className="text-stone-950 dark:text-[#f3f3f3] text-xs font-semibold">
                {row.symbol}
              </div>
            </div>
            </SecurityInfo>

            {
              row.delta  > 0 ?  <div className="text-emerald-500 text-xs font-semibold">
              {formatNum(row.lastPx)} ({formatNum(row.delta)})
            </div> :  <div className="text-red-500 text-xs font-semibold">
              {formatNum(row.lastPx)} ({formatNum(row.delta)})
            </div>
            }
           
          </div>
        );
      })}
      </div>
    </Marquee>
  );
}

export default StockTicker;
