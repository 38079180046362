import moment from "moment";
import { useState } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import useSWR from "swr";
import { formatDate } from "../library";

export default function useOrderSWR() {

  const [status, setStatus] = useState<string>();

  const [dateRange, setDateRange] = useState<DateValueType>(() => {
    const d = new Date();
    return {
      startDate: moment().subtract(36, "months").toDate(),
      endDate: d,
    };
  });

  const handleChangeDate = (e: DateValueType) => {
    if (e?.startDate != null && e.endDate != null) {
      setDateRange(e);
    }
  };

  const handleChangeStatus = (e: string) => {
    setStatus(e);
  };

  const { data, error, isLoading, mutate } = useSWR(
    `findCustomerOrders/portfolioId/${status}/${formatDate(
      dateRange?.startDate,
      "YYYY-MM-DD"
    )}/${formatDate(dateRange?.endDate, "YYYY-MM-DD")}/instrumentType`
  );
  return {
    data,
    status,
    setStatus,
    handleChangeStatus,
    handleChangeDate,
    dateRange,
    setDateRange,
    error,
    isLoading,
    mutate,
  };
}
