import SearchSelect from "@/lib/common/components/select/SearchSelect";
import { AppContext } from "@/lib/contexts/App.context";
import Service from "@/lib/services/request";
import { updateDetailsSchema } from "@/lib/services/validators/profile-validator";
import { ErrorProps } from "@/lib/types/error";
import { yupResolver } from "@hookform/resolvers/yup";

import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GetCountries } from "react-country-state-city";
import Button from "@/lib/common/components/button";
import Input from "@/lib/common/components/input";
import { convertFileToBase64 } from "@/lib/library";

interface FormProps {
  identification?: FileList;
  utilityBill?: FileList;
  signature?: FileList;

  religion?: string;
  nationality?: string;
  sex?: string;

  overallInvstObj: string;
  riskTolerance: string;
  sourceOfWealth: string;
  invstKnowledge: string;
  estimatedNetWorth: number;
  estimatedAnnualIncome: number;

  partnerType: string;

  title?: string;
}

export default function UpdateProfile() {
  const { user, setUser } = useContext(AppContext);
  const [loading, setLoading] = useState(false);

  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    GetCountries().then((result: any) => {
      setCountriesList(result);
    });
  }, []);

  useEffect(() => {
    if (user) {
      setValue("partnerType", user.partnerType ?? "INDIVIDUAL");
    }
  }, [user]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(updateDetailsSchema),
    defaultValues: {
      religion: user?.religion ?? "",
      nationality: user?.nationality ?? "",

      overallInvstObj: user?.overallInvstObj ?? "",
      riskTolerance: user?.riskTolerance ?? "",
      sourceOfWealth: user?.sourceOfWealth ?? "",
      invstKnowledge: user?.invstKnowledge ?? "",
      estimatedNetWorth: user?.estimatedNetWorth ?? undefined,
      estimatedAnnualIncome: user?.estimatedAnnualIncome ?? undefined,

      title: user?.title ?? "",
    },
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {
      const signatureList = getValues("signature") as FileList;
      const identificationList = getValues("identification") as FileList;
      const utilityBillList = getValues("utilityBill") as FileList;

      let signature = "";
      let signatureFileName = "";
      let signatureMimeType = "";

      let identification = "";
      let identificationMimeType = "";
      let identificationFileName = "";

      let utilityBill = "";
      let utilityBillMimeType = "";
      let utilityBillFileName = "";

      if (signatureList?.length > 0) {
        const file = signatureList[0]; 
        signature = await convertFileToBase64(file);
        signatureFileName = file.name
        signatureMimeType = file.type
      }

      if (identificationList?.length > 0) {
        const file = identificationList[0]; 
        identification = await convertFileToBase64(file);
        identificationFileName = file.name
        identificationMimeType = file.type
       
      }

      if (utilityBillList?.length > 0) {
        const file = utilityBillList[0]; 
        utilityBill = await convertFileToBase64(file);
        utilityBillFileName = file.name
        utilityBillMimeType = file.type
      }

      const formData = {
        signature,
        signatureFileName,
        signatureMimeType,

        identification,
        identificationMimeType,
        identificationFileName,

        utilityBill,
        utilityBillMimeType,
        utilityBillFileName,

        religion: da.religion ?? "",
        nationality: da.nationality ?? "",
        sex: da.sex ?? "",
        overallInvstObj: da.overallInvstObj ?? "",
        riskTolerance: da.riskTolerance ?? "",
        sourceOfWealth: da.sourceOfWealth ?? "",
        invstKnowledge: da.invstKnowledge ?? "",
        estimatedNetWorth: da.estimatedNetWorth,
        estimatedAnnualIncome: da.estimatedAnnualIncome,
      };

      const res = await Service.post(Service.UpdateProfileDetails, formData);
      if (res.data) {
        localStorage.setItem(Service.StoreUser, JSON.stringify(res.data));
        setUser(res.data);

        toast("Your profile record has been updated", {
          type: "success",
        });
      }
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col pt-3 md:pt-8 w-full md:w-2/3"
      >
        <div className="text-neutral-400 dark:text-[#ffffff] text-base font-semibold capitalize">
          Update Profile
        </div>

        <div className="pt-4">
          <Input
            {...register("identification")}
            errors={errors.identification}
            required
            type="file"
            label="Identification"
            // onChange={(e: any) => {
            //   const file: File = e.target.files[0];
            //   // Read file as Base64
            //   const reader = new FileReader();
            //   reader.onload = () => {
            //     setIdentification({
            //       fileName: file.name,
            //       mimeType: file.type,
            //       base64String: reader.result as string,
            //     });
            //     setValue("identification", e.target.files);
            //   };
            //   reader.onerror = (error) => {
            //     console.error("Error reading file:", error);
            //   };
            //   // Start reading the file as a data URL
            //   reader.readAsDataURL(file);
            // }}
          />
        </div>

        <div className="pt-4">
          <Input
            {...register("utilityBill")}
            errors={errors.utilityBill}
            required
            type="file"
            label="Utility Bill"
            // onChange={(e: any) => {
            //   const file: File = e.target.files[0];
            //   // Read file as Base64
            //   const reader = new FileReader();
            //   reader.onload = () => {
            //     setUtilityBill({
            //       fileName: file.name,
            //       mimeType: file.type,
            //       base64String: reader.result as string,
            //     });
            //     setValue("utilityBill", e.target.files);
            //   };
            //   reader.onerror = (error) => {
            //     console.error("Error reading file:", error);
            //   };
            //   // Start reading the file as a data URL
            //   reader.readAsDataURL(file);
            // }}
          />
        </div>

        <div className="pt-4">
          <Input
            {...register("signature")}
            errors={errors.signature}
            required
            type="file"
            label="Signature"
            // onChange={(e: any) => {
            //   const file: File = e.target.files[0];
            //   // Read file as Base64
            //   const reader = new FileReader();
            //   reader.onload = () => {
            //     setSignature({
            //       fileName: file.name,
            //       mimeType: file.type,
            //       base64String: reader.result as string,
            //     });
            //     setValue("signature", e.target.files);
            //   };
            //   reader.onerror = (error) => {
            //     console.error("Error reading file:", error);
            //   };
            //   // Start reading the file as a data URL
            //   reader.readAsDataURL(file);
            // }}
          />
        </div>

        {user?.partnerType === "INDIVIDUAL" && (
          <>
            <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
              <SearchSelect
                {...register("religion")}
                errors={errors.religion}
                label="Religion"
                defaultValue={getValues("religion")}
                required
                options={[
                  {
                    name: "Christianity",
                    value: "Christianity",
                  },
                  { name: "Islam", value: "Islam" },
                  { name: "Others", value: "Others" },
                ]}
                onChanging={(r) => {
                  setValue("religion", r.toString());
                }}
              />
            </div>

            <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
              <SearchSelect
                defaultValue={getValues("nationality")}
                errors={errors.nationality}
                label="Nationaltiy"
                required
                options={countriesList.map((item: any) => {
                  return { name: item.name, value: item.name };
                })}
                onChanging={(r: any) => {
                  setValue("nationality", r.toString());
                }}
              />
            </div>
          </>
        )}

        <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
          <SearchSelect
            {...register("overallInvstObj")}
            errors={errors.overallInvstObj}
            required
            defaultValue={getValues("overallInvstObj")}
            label="Overall investment objective"
            options={[
              {
                value: "CAPITAL_PRESERVATION",
                name: "Capital Preservation",
              },
              {
                value: "RETIREMENT_INCOME",
                name: "Retirement Income",
              },
              {
                value: "AGGRESSIVE_GROWTH",
                name: "Aggressive Growth",
              },
            ]}
            onChanging={(r) => {
              setValue("overallInvstObj", r.toString());
            }}
          />

          <SearchSelect
            {...register("riskTolerance")}
            errors={errors.riskTolerance}
            required
            defaultValue={getValues("riskTolerance")}
            label="Risk Tolerance"
            options={[
              {
                value: "CONSERVATIVE",
                name: "Conservative",
              },
              {
                value: "MODERATELY_CONSERVATIVE",
                name: "Moderately Conservative",
              },
              {
                value: "MODERATE",
                name: "Moderate",
              },
              {
                value: "MODERATELY_AGGRESSIVE",
                name: "Moderately Aggressive",
              },
              {
                value: "AGGRESSIVE",
                name: "Aggressive",
              },
            ]}
            onChanging={(r) => {
              setValue("riskTolerance", r.toString());
            }}
          />
        </div>

        <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
          <SearchSelect
            {...register("sourceOfWealth")}
            errors={errors.sourceOfWealth}
            required
            defaultValue={getValues("sourceOfWealth")}
            label="Source Of Wealth"
            options={[
              {
                value: "GIFTS",
                name: "Gifts",
              },
              {
                value: "INVESTMENT",
                name: "Investment",
              },
              {
                value: "BUSINESS_OWNER",
                name: "Business Owner",
              },
              {
                value: "INHERITANCE",
                name: "Inheritance",
              },
              {
                value: "SALARY",
                name: "Salary",
              },
              {
                value: "OTfHER",
                name: "Other",
              },
            ]}
            onChanging={(r) => {
              setValue("sourceOfWealth", r.toString());
            }}
          />

          <SearchSelect
            {...register("invstKnowledge")}
            errors={errors.invstKnowledge}
            defaultValue={getValues("invstKnowledge")}
            required
            label="Investment Knowledge"
            options={[
              {
                value: "NOVICE",
                name: "Novice",
              },
              {
                value: "EXPERIENCED",
                name: "Experienced",
              },
              {
                value: "EXPERT",
                name: "Expert",
              },
            ]}
            onChanging={(r) => {
              setValue("invstKnowledge", r.toString());
            }}
          />
        </div>

        <div className="pt-4  grid lg:grid-cols-2  grid-cols-1 gap-5">
          <Input
            {...register("estimatedNetWorth")}
            errors={errors.estimatedNetWorth}
            required
            label="Estimated Net Worth"
          />

          <Input
            {...register("estimatedAnnualIncome")}
            errors={errors.estimatedAnnualIncome}
            required
            label="Estimated Annual Income"
          />
        </div>

        <div className="py-10">
          <Button
            className="w-full"
            disabled={loading}
            isLoading={loading}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
