import React from "react";
import { IconProps } from "@/lib/types/icon";

const SettingProfileSVG: React.FC<IconProps> = ({
  size = "24",
  color = "#292D32",
  ...attributes
}) => {
  return (
    <svg
      {...attributes}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.49 2.22957L5.5 4.10957C4.35 4.53957 3.41 5.89957 3.41 7.11957V14.5496C3.41 15.7296 4.19 17.2796 5.14 17.9896L9.44 21.1996C10.85 22.2596 13.17 22.2596 14.58 21.1996L18.88 17.9896C19.83 17.2796 20.61 15.7296 20.61 14.5496V7.11957C20.61 5.88957 19.67 4.52957 18.52 4.09957L13.53 2.22957C12.68 1.91957 11.32 1.91957 10.49 2.22957Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.5C13.1046 12.5 14 11.6046 14 10.5C14 9.39543 13.1046 8.5 12 8.5C10.8954 8.5 10 9.39543 10 10.5C10 11.6046 10.8954 12.5 12 12.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.5V15.5"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingProfileSVG;
