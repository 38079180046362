import PageNavBar from "../layout/PageNavBar";
import DateRange from "@/lib/common/components/date/DateRange";
import { useCallback, useEffect, useState } from "react";
import { formatDate, formatNum } from "@/lib/library";
import React from "react";
import NoContent from "@/lib/common/components/table/NoContent";
import CustomPagination from "@/lib/common/components/table/Pagination";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  Spinner,
  TableRow,
  TableCell,
  SortDescriptor,
} from "@nextui-org/react";
import Constant from "@/lib/constant";
import TableCss from "@/lib/utils/table-css";
import useCashStatementSWR from "@/lib/hook/useCashStatementSWR";
import useSWR from "swr";
import Service from "@/lib/services/request";
import Select from "@/lib/common/components/select";
import { Account } from "@/lib/services/class/account";
import SearchForm from "../layout/component/SearchForm";
import { cashStatementColumns } from "@/lib/utils/tableColumns";
import { Contract } from "@/lib/services/class/contract";
import fileDownload from "js-file-download";
import Button from "@/lib/common/components/button";

function CashContract() {
  const accounts = useSWR(Service.FinAccount);
  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(1);
  const hasSearchFilter = Boolean(filterValue);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "transactionDate",
    direction: "descending",
  });
  const [isLoaded, setIsLoaded] = useState(true);

  const {
    data,
    handleChangeDate,
    handleChangeAccount,
    account,
    dateRange,
    isLoading,
  } = useCashStatementSWR();

  useEffect(() => {
    if (isLoaded) {
      if (
        accounts.data != undefined &&
        accounts.data.data &&
        accounts.data.data.result.length > 1
      ) {
        handleChangeAccount(accounts.data.data.result[0].id);
      }
      setIsLoaded(false);
    }
  }, [accounts, handleChangeAccount]);

  const renderCell = useCallback((order: Contract, columnKey: React.Key) => {
    const cellValue = order[columnKey as keyof Contract];

    switch (columnKey) {
      case "debitAmount":
        return formatNum(cellValue);
      case "creditAmount":
        return formatNum(cellValue);
      case "balance":
        return formatNum(cellValue);
      case "transactionDate":
        return formatDate(cellValue);
      case "valueDate":
        return formatDate(cellValue);

      default:
        return cellValue;
    }
  }, []);

  const onSearchChange = React.useCallback((value: string | undefined) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const filteredItems = React.useMemo(() => {
    const result =
      data != undefined && data.data.result ? data.data.result : [];
    let filteredUsers = [...result];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.description.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.name.toLowerCase().includes(filterValue)
      );
    }

    return filteredUsers;
  }, [data, filterValue, hasSearchFilter]);
  ("");

  const pages = Math.ceil(filteredItems.length / Constant.RowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * Constant.RowsPerPage;
    const end = start + Constant.RowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof Contract];
      const second = b[sortDescriptor.column as keyof Contract];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const loadingState =
    isLoading 
      ? "loading"
      : "idle";

  const classNames = React.useMemo(() => TableCss, []);

  return (
    <>
      <PageNavBar
        title="Cash Statement"
        primary="Statement"
        showTradeNowButton={true}
      />

      <div className=" mx-4 mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
        <div className="p-8">
          <div className="mb-8">
            <SearchForm
              onChange={(value) => {
                onSearchChange(value);
              }}
            />
          </div>

          <div className="flex justify-between items-start">
            <Select
              className="w-48"
              value={account}
              onChange={(event) => {
                handleChangeAccount(parseInt(event.target.value));
              }}
              label="Select account"
              placeholder="Select Starting Point">
              {accounts.data != undefined &&
                accounts.data.data && accounts.data.data.result &&
                accounts.data.data.result.map((item: Account) => {
                  return item.id === account ? (
                    <option key={item.id} value={item.id} selected>
                      {item.label}
                    </option>
                  ) : (
                    <option key={item.id} value={item.id ?? ""}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>

            <div className="flex justify-center items-end gap-4">
              <DateRange
                label="Date Range"
                value={dateRange}
                onChange={(values) => {
                  handleChangeDate(values);
                }}
              />

           
              <Button
                onClick={() => {
                  Service.get(
                    `${Service.CashStatement}${account}?start_date=${formatDate(
                      dateRange?.startDate,
                      "YYYY-MM-DD"
                    )}&end_date=${formatDate(
                      dateRange?.endDate,
                      "YYYY-MM-DD"
                    )}`,
                    {
                      responseType: "blob",
                    }
                  ).then((response) => {
                    fileDownload(response.data, "file.pdf");
                  });
                }}
                variant="primary-outline">
                Download
              </Button>
            </div>
          </div>
        </div>

        <div className="py-5">
          <Table
            isCompact
            classNames={classNames}
            sortDescriptor={sortDescriptor}
            onSortChange={setSortDescriptor}
            bottomContent={
              pages > 0 ? (
                <div className="flex w-full justify-center">
                  <CustomPagination
                    isCompact
                    showControls
                    showShadow
                    page={page}
                    total={pages}
                    onChange={(page: React.SetStateAction<number>) =>
                      setPage(page)
                    }
                  />
                </div>
              ) : null
            }>
            <TableHeader columns={cashStatementColumns}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                  allowsSorting={column.sortable}>
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody<Contract>
              isLoading={isLoading}
              emptyContent={<NoContent />}
              items={sortedItems}
              loadingContent={<Spinner />}
              loadingState={loadingState}>
              {(item: Contract) => {
                return (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                );
              }}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default CashContract;
