import individualAccount from "@/assets/images/individual_account.svg";
import businessAccount from "@/assets/images/business_account.svg";
import clsx from "clsx";

interface Props {
  onClick: (arg0: string) => void;  
  accountType: string;
}

import greenChecked from "@/assets/images/green_checked.svg";

export default function AccountTypeWidget({
  onClick, accountType
}: Props) {

  const accountTypes = [
    {
      image: individualAccount,
      name: "Individual Account",
      type: "INDIVIDUAL",
    },
    {
      image: businessAccount,
      name: "Corporate Account",
      type: "ORGANIZATION",
    },
  ];
  return (
    <div className="flex justify-start items-start gap-8">
      {accountTypes.map(({ name, image, type }, index) => {
        return (
          <div
            key={index}
            onClick={() => onClick(type)}
            className={clsx(
              `cursor-pointer w-[200px] h-[200px] px-4 rounded-md flex-col justify-center items-center gap-4 inline-flex relative`,
              {
                "border-2 border-green-900": type === accountType,
              },
              {
                "border border-stone-950 border-opacity-10 ":
                  type !== accountType,
              }
            )}>
            <img src={image} />

            <div className=" text-stone-950 dark:text-[#f3f3f3] text-opacity-30 text-sm font-medium capitalize">
              {name}
            </div>

            {type === accountType && (
              <img className="absolute top-3 right-3" src={greenChecked} />
            )}
          </div>
        );
      })}
    </div>
  );
}
