import NoContent from "@/lib/common/components/table/NoContent";
import { ModalContext } from "@/lib/contexts/modal-context";
import { useContext } from "react";

export default function NotificationModal() {
  const { notification } = useContext(ModalContext);

  return (
    <>
      {notification && (
        <div className="absolute right-0 top-16 mt-2 w-[453px] bg-stone-50 rounded-[10px] shadow">
          <div className="p-4 text-center  text-stone-950 dark:text-[#f3f3f3] text-xl font-semibold">
            Notification
          </div>

          <div className="w-fill h-[0px] border border-black border-opacity-5"></div>

          <div className="flex justify-center items-center">
            <NoContent content="" />
          </div>
        </div>
      )}
    </>
  );
}
