import { capitalizeFirstLetter } from "@/lib/library";
import { News } from "@/lib/services/class/news";

interface Props {
    news: News;
}

export default function NewsBody({news }: Props) {
  return (
    <div className=" py-4 ">

       <a target="_blank" href={news.url} className=" text-stone-950 dark:text-[#f3f3f3] text-base font-semibold leading-normal">
        {capitalizeFirstLetter(news.headLine)}
      </a>
      <p className="py-4 text-neutral-700 text-sm font-normal leading-normal">
        {capitalizeFirstLetter(news.body)}
      </p>
      <a target="_blank" href={news.url}>
        <div className="w-[156px] h-[17px] pr-2.5 justify-start items-center gap-2.5 inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <div className="text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold">
              Source:
            </div>
            <div className="text-green-900 text-sm font-medium">
              {capitalizeFirstLetter(news.source)}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
