import React from "react";
import { IconProps } from "@/lib/types/icon";

const StatementActiveSVG: React.FC<IconProps> = ({
  size = "24",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      {...attributes}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6778 19.957C12.9525 20.0209 12.9777 20.3807 12.7101 20.4699L11.1301 20.9899C7.1601 22.2699 5.0701 21.1999 3.7801 17.2299L2.5001 13.2799C1.2201 9.30992 2.2801 7.20992 6.2501 5.92992L6.77409 5.75639C7.17696 5.62297 7.56902 6.02703 7.45463 6.43571C7.39793 6.63828 7.34338 6.84968 7.2901 7.06992L6.3101 11.2599C5.2101 15.9699 6.8201 18.5699 11.5301 19.6899L12.6778 19.957Z"
        fill={color}
      />
      <path
        d="M17.1699 3.2105L15.4999 2.8205C12.1599 2.0305 10.1699 2.6805 8.99994 5.1005C8.69994 5.7105 8.45994 6.4505 8.25994 7.3005L7.27994 11.4905C6.29994 15.6705 7.58994 17.7305 11.7599 18.7205L13.4399 19.1205C14.0199 19.2605 14.5599 19.3505 15.0599 19.3905C18.1799 19.6905 19.8399 18.2305 20.6799 14.6205L21.6599 10.4405C22.6399 6.2605 21.3599 4.1905 17.1699 3.2105ZM15.2899 13.3305C15.1999 13.6705 14.8999 13.8905 14.5599 13.8905C14.4999 13.8905 14.4399 13.8805 14.3699 13.8705L11.4599 13.1305C11.0599 13.0305 10.8199 12.6205 10.9199 12.2205C11.0199 11.8205 11.4299 11.5805 11.8299 11.6805L14.7399 12.4205C15.1499 12.5205 15.3899 12.9305 15.2899 13.3305ZM18.2199 9.9505C18.1299 10.2905 17.8299 10.5105 17.4899 10.5105C17.4299 10.5105 17.3699 10.5005 17.2999 10.4905L12.4499 9.2605C12.0499 9.1605 11.8099 8.7505 11.9099 8.3505C12.0099 7.9505 12.4199 7.7105 12.8199 7.8105L17.6699 9.0405C18.0799 9.1305 18.3199 9.5405 18.2199 9.9505Z"
        fill={color}
      />
    </svg>
  );
};

export default StatementActiveSVG;
