// import Service from "@/lib/services/request";
// import useSWR from "swr";
// import CardTemplate from "./index";
// import TransactionIcon from "@/assets/images/icons/transaction_icon.svg";

function TransactionCard() {

  // const { data } = useSWR(Service.TransactionHistory);
  // console.log("Ddd")
  return (
    <div></div>
    // <CardTemplate title="Recent Transactions" showBorder={true}>
    //   <div className="p-5">
    //     <div className="pb-5 justify-between items-center gap-2 flex">
    //       <div className="justify-start items-center gap-4 flex">
    //         <img src={TransactionIcon} />
    //         <div className="flex-col justify-center items-start gap-1 inline-flex">
    //           <div className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold">
    //             Deposit
    //           </div>
    //           <div className="text-neutral-700 text-[10px] font-medium">
    //             01 Jun. 2023
    //           </div>
    //         </div>
    //       </div>
    //       <div className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold">
    //         +N15,000.00
    //       </div>
    //     </div>
    //     <div className="justify-between items-center gap-2 flex">
    //       <div className="justify-start items-center gap-4 flex">
    //         <img src={TransactionIcon} className="rotate-180" />
    //         <div className="flex-col justify-center items-start gap-1 inline-flex">
    //           <div className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold">
    //             Withdraw
    //           </div>
    //           <div className="text-neutral-700 text-[10px] font-medium">
    //             01 Jun. 20234
    //           </div>
    //         </div>
    //       </div>
    //       <div className=" text-stone-950 dark:text-[#f3f3f3] text-sm font-semibold">
    //         +N15,000.00
    //       </div>
    //     </div>
    //   </div>
    // </CardTemplate>
  );
}

export default TransactionCard;
