import DashboardSVG from "@/lib/common/icons/menus/dashbaord";
import DashboardActiveSVG from "@/lib/common/icons/menus/dashbaord_active";
import PortfolioSVG from "../common/icons/menus/portfolio";
import PortfolioActiveSVG from "../common/icons/menus/portfolio_active";
import FundSVG from "../common/icons/menus/fund";
import FundActiveSVG from "../common/icons/menus/fund_active";
import OrderSVG from "../common/icons/menus/order";
import OrderActiveSVG from "../common/icons/menus/order_active";
import StatementSVG from "../common/icons/menus/statement";
import ResearchSVG from "../common/icons/menus/research";
import ResearchActiveSVG from "../common/icons/menus/research_active";
import ReferralSVG from "../common/icons/menus/referral";
import ReferralActiveSVG from "../common/icons/menus/referral_active";
import SettingSVG from "../common/icons/menus/setting";
import SettingActiveSVG from "../common/icons/menus/setting_active";
import StatementActiveSVG from "../common/icons/menus/statement_active";
const data = [
  {
    title: "Dashboard",
    link: "/dashboard",
    Image: DashboardSVG,
    ImageActive: DashboardActiveSVG,
  },
  {
    title: "Portfolio",
    link: "/dashboard/portfolio",
    Image: PortfolioSVG,
    ImageActive: PortfolioActiveSVG,
    items: [
      {
        title: "Portfolio",
        link: "/dashboard/portfolio",
      },
      {
        title: "Asset Allocation",
        link: "/dashboard/portfolio/assets",
      },
      {
        title: "Account Summary",
        link: "/dashboard/portfolio/account-summary",
      },
      {
        title: "Watchlist",
        link: "/dashboard/portfolio/watchlist",
      },
    ],
  },
  {
    title: "Orders",
    link: "/dashboard/orders",
    Image: OrderSVG,
    ImageActive: OrderActiveSVG,
    items: [
      {
        title: "Orders",
        link: "/dashboard/orders",
      },
      {
        title: "Submit an order",
        link: "/dashboard/orders/create",
      },
    ],
  },
  {
    title: "Statement",
    link: "/dashboard/statement",
    Image: StatementSVG,
    ImageActive: StatementActiveSVG,
    items: [
      {
        title: "Purchase Contract",
        link: "/dashboard/statement-purchase",
      },
      {
        title: "Sell Contract",
        link: "/dashboard/statement-sell",
      },
      {
        title: "Cash Contract",
        link: "/dashboard/statement-cash",
      },
    ],
  },
  {
    title: "Fund Management",
    link: "/dashboard/fund",
    Image: FundSVG,
    ImageActive: FundActiveSVG,
    items: [
      {
        title: "Deposit Funds",
        link: "/dashboard/deposit",
      },
      {
        title: "Withdraw Funds",
        link: "/dashboard/withdrawal",
      },
    ],
  },
  {
    title: "Referral",
    link: "/dashboard/referral",
    Image: ReferralSVG,
    ImageActive: ReferralActiveSVG,
  },
  {
    title: "Research",
    link: "/dashboard/research",
    Image: ResearchSVG,
    ImageActive: ResearchActiveSVG,
    items: [
      {
        title: "Research",
        link: "/dashboard/research",
      },
      {
        title: "Market Data",
        link: "/dashboard/market-data",
      },
    ],
  },
  {
    title: "Settings",
    link: "/dashboard/setting",
    Image: SettingSVG,
    ImageActive: SettingActiveSVG,
  },
];

export default data;
