import LargeLogo from "@/assets/images/logo_large.svg";

function RegisterSidebar() {
    return <div className="hidden col-span-2 sticky md:top-0 md:h-screen  p-4 lg:flex flex-col justify-between items-start bg-zinc-100 bg-opacity-60 gap-24 ">
    <div className="flex flex-col justify-start items-start gap-12">
      <a href="https://www.meritrade.com">
        <img src={LargeLogo} alt="Logo" />
      </a>
    </div>

    <div className=" p-6 bg-white rounded-[10px] flex-col justify-center items-start gap-4 inline-flex">
      <div className=" text-stone-950 dark:text-[#f3f3f3] text-lg font-bold font-inter">
        Disclaimer
      </div>
      <div className=" text-neutral-700 text-sm font-normal font-inter leading-normal">
        Carefully consider your investment objectives, the risks, charges
        and expenses involved in Investing in the Nigerian stock market.
        It’s important to note that the same way profits can be made in
        the market, losses can also be made as well. This therefore
        implies that a good knowledge of the market is required before
        actively investing in the stock market. Kindly check through the
        resources available on the MeriTrade website which will educate
        you on how to trade profitably in the stock market”. Market
        volatility, trading volumes, system performance and other factors
        may delay system access and response times. Execution price, speed
        and liquidity are affected by many factors, including market
        volatility, size and type of order.
      </div>
    </div>
  </div>
}
export default RegisterSidebar;
