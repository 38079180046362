import { ReactNode, useState } from "react";

interface Props{
    message: string;
    children: ReactNode;
}
function Tooltip({message, children}: Props) {
    const [hover, setHover] = useState(false);
  return (
    <div className="cursor-pointer relative flex items-center justify-center"
    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}
    >
      {children}
      {
        hover && <div className="relative">
        <div
          className="
                transition ease-in-out delay-150 -translate-y-1 scale-110
                absolute bottom-0  inline-block w-64 px-4 py-3 mb-10 -ml-32
                 text-white bg-[#335a3c] rounded-lg">
          <span className="inline-block text-sm leading-tight">
            {message}
          </span>
          <span className="absolute bottom-0 right-1/2 w-5 h-5 -mb-1 -mr-1 transform rotate-45 bg-[#335a3c]"></span>
        </div>
      </div>
        
      }

      {/* </div> */}
    </div>
  );
}

export default Tooltip;
