import { RootState } from "../../index";

const getPortfolio = (state: RootState) => state.portfolio.data;
const getIsLoading = (state: RootState) => state.portfolio.isLoading;
const getError = (state: RootState) => state.portfolio.error;

export default {
  getPortfolio,
  getIsLoading,
  getError,
};
