import { AppContext } from "@/lib/contexts/App.context";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useContext } from "react";

export default function Tawk() {
  const { user } = useContext(AppContext);

  return (
    <TawkMessengerReact
      propertyId="57c5d2bbe90bf561a34ff564"
      widgetId="default"
      setAttributes={{
        name: user && user.label ? user.label : "",
        store: user && user.label ? user.emailAddress1 : "",
      }}
    />
  );
}
