import React from "react";
import { IconProps } from "@/lib/types/icon";

const CompareStockSVG: React.FC<IconProps> = ({
  size = "24",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      {...attributes}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96973 22H14.9697C19.9697 22 21.9697 20 21.9697 15V9C21.9697 4 19.9697 2 14.9697 2H8.96973C3.96973 2 1.96973 4 1.96973 9V15C1.96973 20 3.96973 22 8.96973 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 8.25625L19.5037 8.24437C18.5667 8.24437 17.5172 8.58307 17.1674 8.99901L15.7431 10.7103C15.4183 11.0966 14.906 11.0966 14.5812 10.7103L11.7201 7.25799C11.4453 6.92523 10.933 6.91335 10.5832 7.22828L9.28386 8.39886C8.89655 8.74944 7.89705 9.03466 7.07246 9.03466H2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 13.2563L19.5037 13.2444C18.5667 13.2444 17.5172 13.5831 17.1674 13.999L15.7431 15.7103C15.4183 16.0966 14.906 16.0966 14.5812 15.7103L11.7201 12.258C11.4453 11.9252 10.933 11.9133 10.5832 12.2283L9.28386 13.3989C8.89655 13.7494 7.89705 14.0347 7.07246 14.0347H2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompareStockSVG;
