import React from "react";
import { IconProps } from "@/lib/types/icon";

const ReferralActiveSVG: React.FC<IconProps> = ({
  size = "24",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      {...attributes}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12V18C20 20.21 18.21 22 16 22H8C5.79 22 4 20.21 4 18V12C4 11.45 4.45 11 5 11H6.97C7.52 11 7.97 11.45 7.97 12V15.14C7.97 15.88 8.38 16.56 9.03 16.91C9.32 17.07 9.64 17.15 9.97 17.15C10.35 17.15 10.73 17.04 11.06 16.82L12.01 16.2L12.89 16.79C13.5 17.2 14.28 17.25 14.93 16.9C15.59 16.55 16 15.88 16 15.13V12C16 11.45 16.45 11 17 11H19C19.55 11 20 11.45 20 12Z"
        fill={color}
      />
      <path
        d="M21.5 7V8C21.5 9.1 20.97 10 19.5 10H4.5C2.97 10 2.5 9.1 2.5 8V7C2.5 5.9 2.97 5 4.5 5H19.5C20.97 5 21.5 5.9 21.5 7Z"
        fill={color}
      />
      <path
        d="M11.64 5.00043H6.12003C5.78003 4.63043 5.79003 4.06043 6.15003 3.70043L7.57003 2.28043C7.94003 1.91043 8.55003 1.91043 8.92003 2.28043L11.64 5.00043Z"
        fill={color}
      />
      <path
        d="M17.8701 5.00043H12.3501L15.0701 2.28043C15.4401 1.91043 16.0501 1.91043 16.4201 2.28043L17.8401 3.70043C18.2001 4.06043 18.2101 4.63043 17.8701 5.00043Z"
        fill={color}
      />
      <path
        d="M13.9699 11C14.5199 11 14.9699 11.45 14.9699 12V15.13C14.9699 15.93 14.0799 16.41 13.4199 15.96L12.5199 15.36C12.1899 15.14 11.7599 15.14 11.4199 15.36L10.4799 15.98C9.81994 16.42 8.93994 15.94 8.93994 15.15V12C8.93994 11.45 9.38994 11 9.93994 11H13.9699Z"
        fill={color}
      />
    </svg>
  );
};

export default ReferralActiveSVG;
