import cancelButton from "@/assets/images/icons/cancel.svg";
import arrowSwapHorizontal from "@/assets/images/icons/arrow-swap-horizontal.svg";
import cancel2 from "@/assets/images/icons/cancel_2.svg";

import { useEffect, useRef, useState } from "react";
import Search from "@/lib/common/icons/search";
import Button from "@/lib/common/components/button";
import { SecContent } from "@/lib/types/global";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import { Security } from "@/lib/services/class/security";
import securityOperations from "@/lib/store/features/security/securityOperations";
import securitySelector from "@/lib/store/features/security/securitySelector";
import { ThunkDispatch } from "@reduxjs/toolkit";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setChange: (arg0: SecContent[]) => void;
}
export default function AddOrRemoveSecModal({ setChange, setIsOpen }: Props) {

  const [securities, setSecurities] = useState<SecContent[]>([]);
  const [filters, setFilters] = useState<SecContent[]>([]);
  const [selected, setSelected] = useState<SecContent[]>([]);
  const [searchSelected, setSearchSelected] = useState<SecContent[]>([]);
  const formRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const securityData = useSelector<RootState, Security[]>(
    securitySelector.getSecurities
  );

  useEffect(() => {
    dispatch(securityOperations.fetchSecurity());
  }, [dispatch]);

  useEffect(() => {
    getGroupedData();
  }, [securityData]);

  const generateColor = () => {
    let randomColorString = "#";
    const arrayOfColorFunctions = "0123456789abcdef";
    for (let x = 0; x < 6; x++) {
      let index = Math.floor(Math.random() * 16);
      let value = arrayOfColorFunctions[index];
  
      randomColorString += value;
    }
    return randomColorString;
  };

  const getGroupedData = () => {

      const res: SecContent[] = [];
      for (let i = 0; i < securityData.length; i++) {
        const item = securityData[i];
        if (item.type !== "GO") {
         
          res.push({ name: item.name, color: generateColor() });
        }
      }
      setFilters(res);
      setSecurities(res);
  };

  const myArray = filters.filter(
    (ar) => !selected.find((rm) => rm.name === ar.name)
  );

  return (
    <div
      className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
      style={{ zIndex: 100 }}>
      <div className="my-10 flex h-full md:h-auto items-cellnter justify-center  text-centder ">
        <div
          className="
   
             bg-stone-50 rounded-[10px] flex-col justify-dcenter items-start gap-6 inline-flex
            w-full 
            md:w-[565px] h-[500px] mx-auto  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
          <div className="p-6 w-full mtd-20">
            <div className="pb-4 w-full justify-between items-center inline-flex">
              <div className="text-stone-950  dark:text-[#f3f3f3] text-xl font-semibold font-['Inter']">
                Add or Remove
              </div>

              <div
                className="cursor-pointer w-10 h-10 relative bg-black bg-opacity-5 rounded"
                onClick={() => setIsOpen(false)}>
                <img src={cancelButton} />
              </div>
            </div>

            <div className=" flex justify-between items-start relative">
              <div className="w-[210px]">
                <div className="pb-2 text-neutral-500 text-sm font-medium leading-normal">
                  Select Symbol
                </div>
                <div>
                  <div className=" bg-white w-full h-12 p-2 rounded-lg border border-stone-950 border-opacity-5 justify-between items-center gap-2 inline-flex">
                    <input
                      placeholder="Search"
                      onChange={(e) => {
                        const ddd = securities.filter((row) => {
                          return row.name
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase());
                        });

                        setFilters(ddd);
                      }}
                      className="
        w-full appearance-none focus:outline-none focus:shadow-outline text-neutral-500 text-sm font-normal leading-normal"
                    />

                    <Search />
                  </div>

                  <ul className="overflow-y-auto h-64">
                    {myArray &&
                      myArray.map((row: SecContent, index: number) => {
                        return (
                          <li
                            onClick={() => {
                              const temp = [...selected];
                              temp.push(row);
                              setSelected(temp);
                              setSearchSelected(temp);

                              if (formRef.current != undefined) {
                                formRef.current.value = "";
                              }
                            }}
                            key={index}
                            className="cursor-pointer w-full border border-stone-950 border-opacity-5 flex justify-start items-center">
                            <div className="text-black text-sm font-normal leading-[41px]">
                              {row.name}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <img
                src={arrowSwapHorizontal}
                className="right-1/2 top-36 left-1/2 absolute -ml-4"
              />
              <div className="w-[210px]">
                <div className="pb-2 text-neutral-500 text-sm font-medium leading-normal">
                  Selected Securities
                </div>
                <div>
                  <div className=" bg-white w-full h-12 p-2 rounded-lg border border-stone-950 border-opacity-5 justify-between items-center gap-2 inline-flex">
                    <input
                      ref={formRef}
                      placeholder="Search"
                      onChange={(e) => {
                        const ddd = selected.filter((row) => {
                          return row.name
                            .toUpperCase()
                            .includes(e.target.value.toUpperCase());
                        });

                        setSearchSelected(ddd);
                      }}
                      className="
        w-full appearance-none focus:outline-none focus:shadow-outline text-neutral-500 text-sm font-normal leading-normal"
                    />

                    <Search />
                  </div>

                  <ul className="overflow-y-auto h-64">
                    {searchSelected &&
                      searchSelected.map((row: SecContent, index: number) => {
                        return (
                          <li
                            key={index}
                            className="py-2 border-b border-black border-opacity-5 flex justify-between items-center gap-2">
                            <div className="w-full justify-start items-center gap-2 flex">
                              <div className="w-full h-10 px-4 py-2 bg-green-900 bg-opacity-5 rounded border justify-start items-center gap-[244px] inline-flex">
                                <div className="text-stone-950  dark:text-[#f3f3f3] text-sm font-medium font-['Inter'] leading-normal">
                                  {row.name}
                                </div>
                              </div>
                            </div>
                            <img
                              onClick={() => {
                                const temp = [...selected];
                                const ind = temp.findIndex(
                                  (r) => r.name == row.name
                                );
                                temp.splice(ind, 1);
                                setSelected(temp);

                                const temp2 = [...searchSelected];

                                temp2.splice(index, 1);
                                setSearchSelected(temp2);
                              }}
                              src={cancel2}
                            />
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>

            <div>
              <div className="pt-3 flex justify-center items-center">
                <Button
                  onClick={() => {
                    setChange(selected);
                    setIsOpen(false);
                  }}>
                  Compare
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
