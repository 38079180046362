import StockUpdateSVG from "@/lib/common/icons/menus/stock_update";
import NewsSVG from "@/lib/common/icons/menus/news";
import CompareStockSVG from "@/lib/common/icons/menus/compare_stock";

const data = [
  {
    title: "Stock Update",
    link: "/dashboard/research",
    Image: StockUpdateSVG,
  },
  {
    title: "Compare Stocks",
    link: "/dashboard/research/compare-stocks",
    Image: CompareStockSVG,
  },
  {
    title: "News",
    link: "/dashboard/research/news",
    Image: NewsSVG,
  },
];

export default data;
