import { AppContext } from "@/lib/contexts/App.context";
import { useNavigate } from "@tanstack/react-router";
import { useContext } from "react";

function IncompleteKYC() {
  const { user } = useContext(AppContext);
  const navigate = useNavigate();

  return (
    <>
      {(!user?.kycStatus && user?.kycStatus != "APPROVED_DOCUMENTS") && (
        <div
          onClick={() => {
            navigate({
              to: "/dashboard/setting",
            });
          }}
          className="cursor-pointer mb-5 p-5 bg-red-600 bg-opacity-10 rounded text-red-600 text-sm font-normal">
          You will be required to update your profile with all KYC documents before withdrawals will be processed
        </div>
      )}
    </>
  );
}
export default IncompleteKYC;
