import useSWR from "swr";
import Service from "../services/request";
import { useState } from "react";

export default function useNewsSWR() {
  const [page, setPage] = useState<number>(1);
  const handleChange = (p: number) => {
    setPage(p)
  };

  const { data, error, isLoading } = useSWR(Service.SecurityNews+`?page=${page-1}`);
  return {
    data,
    page,
    handleChange,
    setPage,
    error,
    isLoading,
  };
}
