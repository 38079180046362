import { Security } from "./security.ts";

export const defaultSecurity: Security = {
  id: "",
  exchangeId: "",
  marketId: "",
  marketSegmentId: "",
  secId: "",
  symbol: "",
  isin: "",
  secDesc: "",
  secText: "",
  secType: "",
  secSubType: "",
  underLyingSecId: "",
  lastPx: 0,
  close: 0,
  high: 0,
  tradeDate: 0,
  volTraded: 0,
  high52: 0,
  low52: 0,
  valTraded: 0,
  open: 0,
  low: 0,
  delta: 0,
  netChgPrevDayPerc: 0,
  netChgPrevDay: 0,
  openQty: 0,
  offerDepth: 0,
  marketCap: 0,
  avg10dayVolume: 0,
  bestOfferQty: 0,
  bidDepth: 0,
  bestBidPx: 0,
  bestOfferPx: 0,
  lastQty: 0,
  bidSize: 0,
  bestBidQty: 0,
  numOfOrders: 0,
  trades: [],
  offers: [],
  bids: [],
  chartData: [],
  type: "",
  name: "",
  label: "",
  price: 0,
};
