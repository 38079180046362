import Switch from "react-switch";
import { AppContext } from "@/lib/contexts/App.context";

import { useContext, useState } from "react";
import Service from "@/lib/services/request";

export default function Configuration() {
  const { user, setUser } = useContext(AppContext);
  const [marketData, setMarketData] = useState<boolean>(
    user?.marketdataCountdown ?? false
  );

  return (
    <>
      <form className="flex flex-col w-full">
        <label
          className={`dark:text-[#f4f4f4] transition-all duration-300 block text-xs leading-tight px-1.5 pb-2`}>
          Stop displaying the modal for trading opening hours?
        </label>

        <div className=" mt-1.5">
          <Switch
            checked={marketData}
            onChange={(checked) => {
              setMarketData(checked);
              if (user) {
                user["marketdataCountdown"] = checked;
              }

              localStorage.setItem(Service.StoreUser, JSON.stringify(user));
              setUser(user);

              Service.post(Service.UpdateProfileSetting, {
                marketdataCountdown: checked,
              });
            }}
            onColor="#285131"
            onHandleColor="#285131"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
        </div>
      </form>
    </>
  );
}
