import Button from "@/lib/common/components/button";
import Input from "@/lib/common/components/input";
import SearchSelect from "@/lib/common/components/select/SearchSelect";
import { AppContext } from "@/lib/contexts/App.context";
import Service from "@/lib/services/request";
import { nextOfKinSchema } from "@/lib/services/validators/profile-validator";
import { ErrorProps } from "@/lib/types/error";
import { yupResolver } from "@hookform/resolvers/yup";

import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface FormProps {
  nexofKin: string;
  nexofKinEmailAddress: string;
  nextofKinPhone: string;
  nextofKinAddress: string;
  nextofKinRelationship: string;
}

export default function SettingNextOfKin() {
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(nextOfKinSchema),
    defaultValues: {
      nexofKin: user?.nexofKin ?? "",
      nexofKinEmailAddress: user?.nexofKinEmailAddress ?? "",
      nextofKinPhone: user?.nextofKinPhone ?? "",
      nextofKinAddress: user?.nextofKinAddress ?? "",
      nextofKinRelationship: user?.nextofKinRelationship ?? "",
    },
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {
      const res = await Service.post(Service.UpdateNextDetails, da);
      if (res.data) {
        localStorage.setItem(Service.StoreUser, JSON.stringify(res.data));
        setUser(res.data);
      }
      toast("Your profile account has been updated");
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <div className="pt-4 md:w-2/3">
          <Input
            {...register("nexofKin")}
            errors={errors.nexofKin}
            required
            type="text"
            label="Next of Kin"
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
        </div>

        <div className="pt-4 md:w-2/3">
          <Input
            {...register("nexofKinEmailAddress")}
            required
            type="text"
            label="Next of Kin Email"
            errors={errors.nexofKinEmailAddress}
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
        </div>

        <div className="pt-4 md:w-2/3">
          <Input
            {...register("nextofKinPhone")}
            required
            type="text"
            label="Next of Kin Phone"
            errors={errors.nextofKinPhone}
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
        </div>

        <div className="pt-4 md:w-2/3">
          <Input
            {...register("nextofKinAddress")}
            required
            type="text"
            label="Next of Kin Address"
            errors={errors.nextofKinAddress}
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
        </div>

        <div className="pt-4 md:w-2/3">
          <SearchSelect
            {...register("nextofKinRelationship")}
            errors={errors.nextofKinRelationship}
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
            defaultValue={getValues("nextofKinRelationship")}
            label="Next of Kin Relationship"
            required
            options={[
              { name: "Spouse", value: "Spouse" },
              { name: "Child", value: "Child" },
              { name: "Parent", value: "Parent" },
              { name: "Sibling", value: "Sibling" },
              { name: "Grand Child", value: "Grand Child" },
              { name: "Grand Parent", value: "Grand Parent" },
              { name: "Niece", value: "Niece" },
              { name: "Nephew", value: "Nephew" },
              { name: "Aunt", value: "Aunt" },
              { name: "Uncle", value: "Uncle" },
              { name: "Great Grand Child", value: "Great Grand Child" },
              { name: "Great Grand Parent", value: "Great Grand Parent" },
              { name: "Great Niece", value: "Great Niece" },
              { name: "Great Nephew", value: "Great Nephew" },
              { name: "First Cousin", value: "First Cousin" },
            ]}
            onChanging={(r) => {
              setValue("nextofKinRelationship", r.toString());
            }}
          />
        </div>

        <div className="w-full h-[0px] border border-stone-950 border-opacity-5 my-5"></div>

        <div className="flex justify-end items-center">
          <div className="md:w-[375px] justify-center items-center gap-6 inline-flex">
            <div className="text-right text-stone-950 dark:text-[#f3f3f3] text-xs font-normal font-['Inter'] leading-tight">
              Please make sure your information is correct <br />
              or it may affect your trading experience.
            </div>
            <Button disabled={loading} isLoading={loading} type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
