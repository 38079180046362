export default class DataCache {
  public static getItem(key: string) {
    const data = localStorage.getItem(key);
    let result = data ? JSON.parse(data) : null;

    if (result) {
      if (result.expireTime && result.expireTime <= Date.now()) {
        window.localStorage.removeItem(key);
        return null;
      }
      return result.data;
    }
    return null;
  }

  public static setItem(key: string, value: string, maxAge = 3 * 60 * 1000) {
    let result = {
      data: value,
      expireTime: maxAge ? Date.now() + maxAge : null,
    };
    window.localStorage.setItem(key, JSON.stringify(result));
  }

  public static removeItem(key: string) {
    window.localStorage.removeItem(key);
  }

  public static clear() {
    window.localStorage.clear();
  }
}
