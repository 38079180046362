import DataCache from "@/lib/DataCache";
import { Security } from "@/lib/services/class/security";
import Service from "@/lib/services/request";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchSecurityDetails = createAsyncThunk<Security[], any>(
  "security/details",
  async ({symbol, useCache}: any) => {
    try {
      if(useCache){
      setTimeout(function() {
        //your code to be executed after 1 second
      }, 1000);
    }
     const store = await DataCache.getItem(symbol);
      if( store){
        return store;
      }


      const { data } = await Service.get(`securityOverview/${symbol}`);
      if (symbol) {
        DataCache.setItem(symbol, data);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchSecurity = createAsyncThunk<Security[]>(
  "security/list",
  async () => {
    try {
      const { data } = await Service.get(Service.Securitydata);

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchTopPercentGainer = createAsyncThunk<Security[]>(
  "marketData/TOP_PERCENT_GAINER",
  async () => {
    try {
      const { data } = await Service.get(
        `security/market_mover/TOP_PERCENT_GAINER`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchTopPercentLooser = createAsyncThunk<Security[]>(
  "marketData/TOP_PERCENT_LOOSER",
  async () => {
    try {
      const { data } = await Service.get(
        `security/market_mover/TOP_PERCENT_LOOSER`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchTopValueGainer = createAsyncThunk<Security[]>(
  "marketData/TOP_VALUE_GAINER",
  async () => {
    try {
      const { data } = await Service.get(
        `security/market_mover/TOP_VALUE_GAINER`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchTopValueLooser = createAsyncThunk<Security[]>(
  "marketData/TOP_VALUE_LOOSER",
  async () => {
    try {
      const { data } = await Service.get(
        `security/market_mover/TOP_VALUE_LOOSER`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
);

const fetchMostActive = createAsyncThunk<Security[]>(
  "marketData/MOST_ACTIVE",
  async () => {
    try {
      const { data } = await Service.get(
        `security/market_mover/MOST_ACTIVE`
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
);


export default {
  fetchSecurityDetails,
  fetchSecurity,
  fetchTopPercentGainer,
  fetchTopPercentLooser,
  fetchTopValueGainer,
  fetchTopValueLooser,
  fetchMostActive,
};
