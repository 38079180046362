import { formatMoney } from "@/lib/library";

interface Props{
  consideration: number,
  commission: number,
  fees: number,
  total: number,
}
function EquityOrderCal({consideration, commission, fees, total} : Props) {
  return (
    <div className="flex  gap-4 flex-col justify-between  mb-6 p-6 bg-white rounded-lg border border-stone-950 border-opacity-10  text-stone-950 dark:text-[#f3f3f3] text-sm font-normal leading-tight">
      <div className="py-3 border-b border-stone-950 border-opacity-5 flex justify-between item-center">
        <div>Consideration</div>
        <div className="font-bold">{formatMoney(consideration)}</div>
      </div>
      <div className="pb-3 border-b border-stone-950 border-opacity-5 flex justify-between item-center">
        <div>Commission</div>
        <div className="font-bold">{formatMoney(commission)}</div>
      </div>
      <div className="pb-3 border-b border-stone-950 border-opacity-5 flex justify-between item-center">
        <div>Fees</div>
        <div className="font-bold">{formatMoney(fees)}</div>
      </div>
      <div className="pb-3  flex justify-between item-center">
        <div>Estimated Total</div>
        <div className="font-bold">{formatMoney(total)}</div>
      </div>
    </div>
  );
}

export default EquityOrderCal;
