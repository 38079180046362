import { AppContext } from "@/lib/contexts/App.context";
import { capitalizeSentence } from "@/lib/library";
import clsx from "clsx";
import { FC, useContext, useEffect, useState } from "react";
import SmallLogo from "@/assets/images/logo_small.svg";
import profileMenu from "@/lib/utils/profile-menu";

import ArrowDown from "@/assets/images/icons/arrow-down.svg";
import { useNavigate } from "@tanstack/react-router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IconProps } from "@/lib/types/icon";

interface MenuProps {
  title: string;
  Image: FC<IconProps>;
  items: { title: string; link: string }[];
}
export default function SettingMenu() {
  const { user } = useContext(AppContext);
  const [menu, setMenu] = useState<MenuProps[]>([]);
  const [click, setClick] = useState<number>();
  const navigate = useNavigate();

  useEffect(() => {
    // if (user && !user.profileCompleted) {
    //   setMenu(incompleteProfileMenu);
    // } else {
      setMenu(profileMenu);
    // }
  }, [user]);

  const [activeLink, setActiveLink] = useState("");
  const isExist = () => {
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].items) {
        for (let j = 0; j < menu[i].items.length; j++) {
          if (menu[i].items[j].link == window.location.pathname) return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (isExist()) {
      setActiveLink(window.location.pathname);
    }
  }, [menu]);
  return (
    <>
      <div className="md:sticky md:top-28 md:h-max mb-4 col-span-2 pt-6 pb-20 bg-white rounded-lg border border-stone-950 border-opacity-10 flex-col justify-start items-center gap-6 inline-flex">
        <LazyLoadImage
          alt="profile"
          className="w-[215px] h-[215px]  rounded-full"
          src={
            user?.picture
              ? `data:${user?.pictureMimeType};base64,${user?.picture}`
              : SmallLogo
          }
        />

        <div className="text-center pt-4">
          <div className="pb-2 text-stone-950 dark:text-[#f3f3f3]  text-xl font-semibold">
            {capitalizeSentence(user?.label)}
          </div>
          <div className=" text-neutral-500 text-sm font-medium leading-tight">
            {user?.name}
          </div>
        </div>

        <div className="px-3">
          {menu.map(({ title, Image, items }, index) => (
            <div key={index} className="pb-1">
              <div
                key={index}
                className="w-full h-10 px-4 py-2 justify-between items-center flex">
                <div className="flex gap-4">
                  <Image />
                  <div className=" text-stone-950 dark:text-[#f3f3f3] text-base font-semibold  capitalize">
                    {title}
                  </div>
                </div>
                <LazyLoadImage
                  onClick={() => {
                    if (index == click) {
                      setClick(undefined);
                    } else {
                      setClick(index);
                    }
                  }}
                  src={ArrowDown}
                  className={clsx(
                    "cursor-pointer",
                    {
                      "rotate-180 ": index == click,
                    },
                    {
                      "rotate-0 ": index != click,
                    }
                  )}
                />
              </div>

              <div
                className={clsx("", {
                  "hidden ": click == index,
                })}>
                {items.map(({ link, title }, index) => (
                  <div
                    onClick={() => {
                      setActiveLink(link);
                      navigate({ to: link });
                    }}
                    key={index}
                    className={clsx(
                      "cursor-pointer w-full h-12 px-14 py-3.5 rounded justify-start items-start gap-2.5 inline-flex",
                      {
                        "bg-green-900 bg-opacity-10 rounded ":
                          activeLink == link,
                      }
                    )}>
                    <div className="justify-start items-center gap-4 flex">
                      <div
                        className={clsx(
                          "text-sm font-normal leading-tight",
                          {
                            " text-green-900 font-semibolds":
                              activeLink == link,
                          },
                          {
                            " text-stone-950 dark:text-[#f3f3f3]":
                              activeLink != link,
                          }
                        )}>
                        {title}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
