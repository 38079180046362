import clsx from "clsx";
import { useState } from "react";

interface Props {
  onChange: (arg0?: string) => void;
  types: {
    name: string;
    value: string;
  }[];
}

function TabDataType({ types, onChange }: Props) {
  const [selected, setSelected] = useState(types[0].value);
  return (
    <div className="w-full md:w-auto rounded-lg md:justify-center items-center gap-2 flex overflow-auto ">
      {types.map(({ name, value }, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              setSelected(value);
              onChange(value);
            }}
            className={clsx(
              "p-2 justify-center items-center gap-2.5 flex cursor-pointer",

              {
                " bg-green-900 bg-opacity-10 rounded flex-col items-center gap-2.5":
                  selected === value,
              }
            )}>
            <div
              className={clsx(
                "text-xs md:text-sm font-medium capitalize text-center leading-[14.86px] md:leading-none",
                {
                  "text-neutral-500": selected !== "",
                },
                {
                  "text-green-900": selected === value,
                }
              )}>
              {name}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TabDataType;
