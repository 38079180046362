import * as Yup from "yup";
import {
  validatePassword,
} from "./validatesInput.ts";


export const forgotPasswordSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
});

export const loginSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
    .test(
      "one-uppercase character special character and a number",
      "Password must contain at least one uppercase letter, one special character and one number",
      (value: string) => validatePassword(value)
    ),
  page: Yup.string(),
  remember: Yup.string(),
});





export const newOrderSchema = Yup.object().shape({
  portfolio: Yup.string().required("Portfolio is required"),
  security: Yup.string().required("Security is required"),
  priceType: Yup.string().required("Price type is required"),
  limitPrice: Yup.number()
  .typeError("must be numeric").when("priceType", (priceType, field) =>
    priceType[0] == "LIMIT"
      ? field.required("Limit Price is required")
      : field
  ),
  orderType: Yup.string().required("Order type is required"),
  quantity: Yup.number()
  .moreThan(0)
  .typeError("must be numeric").integer()
  .positive()
  .required("Quantity is required"),
  orderTerm: Yup.string().required("Order term is required"),
  instrumentType: Yup.string().required("Instrument type is required"),
});

// portfolio: string;
// security: string;
// priceType: string;
// limitPrice?: number;
// orderType: string;
// quantity: number;
// orderTerm: string;
// instrumentType: string;
// export const registerConfirmSchema = Yup.object().shape({
//   token: Yup.string()
//     .min(6, "OTP must be 6 characters")
//     .required("OTP is required"),
// });

// export const completeforgotPasswordSchema = Yup.object().shape({
//   password: Yup.string()
//     .min(6, "Password must be at least 6 characters")
//     .required("Password is required")
//     .test(
//       "one-uppercase character special character and a number",
//       "Password must contain at least one uppercase letter, one special character and one number",
//       (value: string) => validatePassword(value)
//     ),
//   confirmPassword: Yup.string().oneOf(
//     [Yup.ref("password")],
//     "Passwords must match"
//   ),
//   token: Yup.string()
//     .min(6, "OTP must be 6 characters")
//     .required("OTP is required"),
// });

// export const changePasswordSchema = Yup.object().shape({
//   oldPassword: Yup.string()
//     .min(6, "Password must be at least 6 characters")
//     .required("Password is required")
//     .test(
//       "one-uppercase character special character and a number",
//       "Password must contain at least one uppercase letter, one special character and one number",
//       (value: string) => validatePassword(value)
//     ),
//   newPassword: Yup.string()
//     .min(6, "Password must be at least 6 characters")
//     .required("Password is required")
//     .test(
//       "one-uppercase character special character and a number",
//       "Password must contain at least one uppercase letter, one special character and one number",
//       (value: string) => validatePassword(value)
//     ),
//   confirm_newPassword: Yup.string().oneOf(
//     [Yup.ref("password")],
//     "Passwords must match"
//   ),
// });

// export const registerSchema = Yup.object().shape({
//   referrer: Yup.string(),
//   account_type: Yup.string(),
//   first_name: Yup.string()
//     .min(0)
//     .required("First name is required")
//     .test(
//       "Only letters",
//       "Only letters and big than 3 letter",
//       (value: string) => validateOnlyLetters(value)
//     ),
//   last_name: Yup.string()
//     .required("Last name is required")
//     .test(
//       "Only letters",
//       "Only letters and big than 3 letter",
//       (value: string) => validateOnlyLetters(value)
//     ),
//   date_of_birth: Yup.date()
//     .typeError("Date of Birth is required")
//     .max(new Date(Date.now() - 567648000000), "You must be at least 18 years"),

//   phone: Yup.string().required("Phone is required"),

//   email: Yup.string()
//     .email("Email is invalid")
//     .required("Email is required")
//     .test("Email", "accents not allowed", (value: string) =>
//       validateInputEmail(value)
//     ),
//   password: Yup.string()
//     .min(6, "Password must be at least 6 characters")
//     .required("Password is required")
//     .test(
//       "one-uppercase character special character and a number",
//       "Password must contain at least one uppercase letter, one special character and one number",
//       (value: string) => validatePassword(value)
//     ),
//   confirmPassword: Yup.string().oneOf(
//     [Yup.ref("password")],
//     "Passwords must match"
//   ),
// });

// export const profile1Schema = Yup.object().shape({
//   first_name: Yup.string()
//     .min(0)
//     .required("First name is required")
//     .test(
//       "Only letters",
//       "Only letters and big than 3 letter",
//       (value: string) => validateOnlyLetters(value)
//     ),
//   surname: Yup.string()
//     .min(0)
//     .required("First name is required")
//     .test(
//       "Only letters",
//       "Only letters and big than 3 letter",
//       (value: string) => validateOnlyLetters(value)
//     ),

//   otherNames: Yup.string(),
//   titleCode: Yup.string().required("Title is required"),
//   gender: Yup.string().required("Gender is required"),
//   address: Yup.string().required("Address is required"),
//   city: Yup.string().required("City is required"),
//   state: Yup.string().required("State is required"),
//   country: Yup.string().required("Country is required"),
//   zipcode: Yup.string(),
//   maritalStatus: Yup.string().required("Marital status is required"),
//   religion: Yup.string().required("Marital status is required"),
//   phone: Yup.string().required("Phone is required")
//   .matches(phoneRegExp, 'Phone number is not valid'),
//   placeOfBirth: Yup.string(),
//   mothersMaidenName: Yup.string().required("Mother's maiden name is required"),
//   nationality: Yup.string().required("Nationality is required"),
//   dateOfBirth: Yup.date()
//     .typeError("Date of Birth is required")
//     .max(new Date(Date.now() - 567648000000), "You must be at least 18 years"),

//   clearingHouseNo: Yup.string(),
//   stateOfOrigin: Yup.string().required("State of origin is required"),
//   homeTown: Yup.string().required("Hometown is required"),
// });

// export const profile2Schema = Yup.object().shape({
//   nin: Yup.string().required("Annal Income is required"),
//   annualIncome: Yup.string().required("Annal Income is required"),
//   investmentExperience: Yup.string().required(
//     "Investment Experience is required"
//   ),
//   investmentObjectives: Yup.string().required("Investment is required"),
//   networthLiquid: Yup.string().required("Net worth liquid is required"),
//   networthTotal: Yup.string().required("Net worth Total is required"),
//   riskTolerance: Yup.string().required("Rist Tolerance is required"),
//   emp_status: Yup.string().required("Employment status is required"),

// emp_position: Yup.string().when("account_type", (account_type, field) =>
//   account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
//     ? field.required("Employment position is required")
//     : field
// ),
  // emp_state: Yup.string().when("account_type", (account_type, field) =>
  //   account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
  //     ? field.required("Employment state is required")
  //     : field
  // ),
//   emp_address: Yup.string().when("account_type", (account_type, field) =>
//     account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
//       ? field.required("Employment address is required")
//       : field
//   ),
//   emp_type: Yup.string().when("account_type", (account_type, field) =>
//     account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
//       ? field.required("Employment type is required")
//       : field
//   ),
//   emp_zip: Yup.string().when("account_type", (account_type, field) =>
//     account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
//       ? field.required("Employment zip code is required")
//       : field
//   ),

//   emp_country: Yup.string().when("account_type", (account_type, field) =>
//     account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
//       ? field.required("Employment country is required")
//       : field
//   ),
//   emp_city: Yup.string().when("account_type", (account_type, field) =>
//     account_type[0] == "EMPLOYED" || account_type[0] == "SELF_EMPLOYED"
//       ? field.required("Employment city is required")
//       : field
//   ),

//   nextOfKinName: Yup.string().required("Next of kin name is required"),
//   nextOfKinRelationship: Yup.string().required(
//     "Next of kin  realtionship is required"
//   ),
//   nextOfKinPhone: Yup.string().required("Next of kin phone is required")
//   .matches(phoneRegExp, 'Phone number is not valid'),
//   nextOfKinAddress: Yup.string().required("Next of kin address is required"),
//   bankCode: Yup.string().required("Bank name is required"),
//   bvn: Yup.string()
//     .required("BVN is required")
//     .test("len", "Must be exactly 11 characters", (val) => val.length === 11),
//   accountNo: Yup.string()
//     .required("Account no is required")
//     .test("len", "Must be exactly 10 characters", (val) => val.length === 10),
  // bankDate: Yup.date()
  //   .typeError("Bank opening date is required")
  //   .max(new Date(Date.now() - 15770000000), "You must be at least 6 months"),
// });

// export const profile3Schema = Yup.object().shape({
//   identificationType: Yup.string().required("Identification Type is required"),
//   identificationNumber: Yup.string().required(
//     "Identification Number is required"
//   ),
//   identificationExpiry: Yup.date().required(
//     "Identification Expiry date is required"
//   ),
  // passport: Yup.mixed<FileList>()
  //   .test(
  //     "required",
  //     "Your passport is required",
  //     (files) => !!(files && files.length > 0)
  //   )
  //   .test(
  //     "fileSize",
  //     "Only documents up to 2MB are permitted.",
  //     (files) =>
  //       !files ||
  //       files.length === 0 ||
  //       Array.from(files).every((file) => file.size <= 1048576)
  //   )
  //   .test(
  //     "type",
  //     "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc",
  //     (files) => {
  //       return (
  //         !files ||
  //         files.length === 0 ||
  //         Array.from(files).every(
  //           (file) =>
  //             file.type === "image/jpeg" ||
  //             file.type === "image/bmp" ||
  //             file.type === "image/png" ||
  //             file.type === "application/pdf" ||
  //             file.type === "application/msword"
  //         )
  //       );
  //     }
  //   ),
//   signature: Yup.mixed<FileList>()
//     .test(
//       "required",
//       "Your signature is required",
//       (files) => !!(files && files.length > 0)
//     )
//     .test(
//       "fileSize",
//       "Only documents up to 2MB are permitted.",
//       (files) =>
//         !files ||
//         files.length === 0 ||
//         Array.from(files).every((file) => file.size <= 1048576)
//     )
//     .test(
//       "type",
//       "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc",
//       (files) => {
//         return (
//           !files ||
//           files.length === 0 ||
//           Array.from(files).every(
//             (file) =>
//               file.type === "image/jpeg" ||
//               file.type === "image/bmp" ||
//               file.type === "image/png" ||
//               file.type === "application/pdf" ||
//               file.type === "application/msword"
//           )
//         );
//       }
//     ),
//   meansOfIdentification: Yup.mixed<FileList>()
//     .test(
//       "required",
//       "Your means of identification is required",
//       (files) => !!(files && files.length > 0)
//     )
//     .test(
//       "fileSize",
//       "Only documents up to 2MB are permitted.",
//       (files) =>
//         !files ||
//         files.length === 0 ||
//         Array.from(files).every((file) => file.size <= 1048576)
//     )
//     .test(
//       "type",
//       "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc",
//       (files) => {
//         return (
//           !files ||
//           files.length === 0 ||
//           Array.from(files).every(
//             (file) =>
//               file.type === "image/jpeg" ||
//               file.type === "image/bmp" ||
//               file.type === "image/png" ||
//               file.type === "application/pdf" ||
//               file.type === "application/msword"
//           )
//         );
//       }
//     ),

//   utilityBill: Yup.mixed<FileList>()
//     .test(
//       "required",
//       "Your utility bill is required",
//       (files) => !!(files && files.length > 0)
//     )
//     .test(
//       "fileSize",
//       "Only documents up to 2MB are permitted.",
//       (files) =>
//         !files ||
//         files.length === 0 ||
//         Array.from(files).every((file) => file.size <= 1048576)
//     )
//     .test(
//       "type",
//       "Only the following formats are accepted: .jpeg, .jpg, .bmp, .pdf and .doc",
//       (files) => {
//         return (
//           !files ||
//           files.length === 0 ||
//           Array.from(files).every(
//             (file) =>
//               file.type === "image/jpeg" ||
//               file.type === "image/bmp" ||
//               file.type === "image/png" ||
//               file.type === "application/pdf" ||
//               file.type === "application/msword"
//           )
//         );
//       }
//     ),
// });
