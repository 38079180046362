import Button from "@/lib/common/components/button";
import Input from "@/lib/common/components/input";
import { AppContext } from "@/lib/contexts/App.context";
import Service from "@/lib/services/request";
import { chnagePasswordSchema } from "@/lib/services/validators/profile-validator";
import { ErrorProps } from "@/lib/types/error";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearch, useNavigate } from "@tanstack/react-router";
import clsx from "clsx";

import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

interface FormProps {
  oldPassword: string;
  newPassword: string;
  confirm_newPassword: string;
}

type SearchField = {
  isRequired: string;
};

export default function ChangePassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setUser } = useContext(AppContext);
  const [isForce, setIsForce] = useState(false);
  const [password, setPassword] = useState("");

  const { isRequired }: SearchField = useSearch({
    strict: false,
  });

  useEffect(() => {
    setIsForce(isRequired === "YES")
  }, [isRequired]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormProps>({
    resolver: yupResolver(chnagePasswordSchema),
  });

  const onSubmit = async (da: FormProps) => {
    setLoading(true);

    try {
      const res = await Service.post(Service.ChangePassword, da);
      if (res.data) {
        localStorage.setItem(Service.StoreUser, JSON.stringify(res.data));
        setUser(res.data);

        toast("Your password has been updated successfully", {
          type: "success",
        });
        reset();

        return navigate({ to: "/dashboard" });
      }
    } catch (error: unknown) {
      if (error) {
        toast((error as ErrorProps).message, {
          type: "error",
        });
      }
    }

    setLoading(false);
    return null;
  };

  const isMoreThanSeven = () => {
    return password && password.length > 7 ? true : false;
  };

  const hasNumber = () => {
    return /\d/.test(password);
  };

  const hasUpperCase = () => {
    return /\p{Lu}/u.test(password);
  };

  const hasLowerCase = () => {
    return /[a-z]/g.test(password);
  };

  const hasSpecialCharacter = () => {
    return /[@#$%!]+/.test(password);
  };

  return (
    <>
      {isForce && (
        <div className=" p-5 bg-red-600 bg-opacity-10 rounded text-red-600 text-sm font-normal">
          Unlock the full range of features by resetting your password. Once you've reset it, you can dive into a seamless experience and enjoy all that our platform has to offer.
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
        <div className="pt-4 md:w-2/3">
          <Input
            {...register("oldPassword")}
            errors={errors.oldPassword}
            required
            type="password"
            label="Current Password"
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
        </div>

        <div className="pt-4 md:w-2/3">
          <Input
            {...register("newPassword")}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
            type="password"
            label="New Password"
            errors={errors.newPassword}
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
          <p className="py-2">
            <span className="font-bold">* At least:</span>{" "}
            <span
              className={clsx(``, {
                " text-green-900 font-bold": isMoreThanSeven(),
                "text-grey-600": !isMoreThanSeven(),
              })}>
              8 characters
            </span>
            ,{" "}
            <span
              className={clsx(``, {
                " text-green-900 font-bold": hasNumber(),
                "text-grey-600": !hasNumber(),
              })}>
              1 numbers
            </span>
            ,{" "}
            <span
              className={clsx(``, {
                " text-green-900 font-bold": hasUpperCase(),
                "text-grey-600": !hasUpperCase(),
              })}>
              1 upper
            </span>
            ,{" "}
            <span
              className={clsx(``, {
                " text-green-900 font-bold": hasLowerCase(),
                "text-grey-600": !hasLowerCase(),
              })}>
              1 lower
            </span>
            ,{" "}
            <span
              className={clsx(``, {
                " text-green-900 font-bold": hasSpecialCharacter(),
                "text-grey-600": !hasSpecialCharacter(),
              })}>
              1 special characters - #@$!
            </span>
          </p>
        </div>

        <div className="pt-4 md:w-2/3">
          <Input
            {...register("confirm_newPassword")}
            required
            type="password"
            label="Confirm New Password"
            errors={errors.confirm_newPassword}
            className="text-stone-950 dark:text-[#f3f3f3] text-sm font-medium capitalize"
          />
        </div>

        <div className="w-full h-[0px] border border-stone-950 border-opacity-5 my-5"></div>

        <div className="flex justify-end items-center">
          <div className="md:w-[375px] justify-center items-center gap-6 inline-flex">
            <div className="text-right text-stone-950 dark:text-[#f3f3f3] text-xs font-normal font-['Inter'] leading-tight">
              Please make sure your information is correct <br />
              or it may affect your trading experience.
            </div>
            <Button disabled={loading} isLoading={loading} type="submit">
              Submit
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
