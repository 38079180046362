import PageNavBar from "../layout/PageNavBar";
import { Outlet } from "@tanstack/react-router";
import SettingMenu from "./components/SettingMenu";

export default  function SettingPage() {
  return (
    <>
      <PageNavBar title="Settings" primary="Settings" showTradeNowButton={true} />

      <div
        className=" mx-4 mb-6 
      grid lg:grid-cols-7 md:grid-cols-2 grid-cols-1 lg:gap-8 md:gap-12 gap-0">
        <SettingMenu />

        <div className="h-max col-span-5 p-6 bg-white rounded-lg border border-stone-950 border-opacity-10 flex-col justify-start items-start gap-6 inline-flex">
          <Outlet />
        </div>
      </div>
    </>
  );
}
