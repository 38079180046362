import { SWRConfig } from "swr";
import { ReactNode } from "react";
import apiRequest from "../services/api.ts";

type Props = {
  children: ReactNode;
};

export function SWRProvider({ children }: Props) {
  function localStorageProvider() {
    if (typeof window === "undefined") {
      return new Map();
    }

    const map = new Map<string, any>(JSON.parse(localStorage.getItem('app-cache') || '[]'));

    window.addEventListener('beforeunload', () => {
      const appCache = JSON.stringify(Array.from(map.entries()));
      localStorage.setItem('app-cache', appCache);
    });
  
    return {
      get(key: string) {
        return map.get(key);
      },
      set(key: string, value: any) {
        map.set(key, value);
      },
      delete(key: string) {
        map.delete(key);
      },
      keys() {
        return map.keys();
      },
    };
  }

  return (
    <SWRConfig
      value={{
        // dedupingInterval: 10000,
        fetcher: (url: string) => apiRequest.get(url).then((r: unknown) => r),
        provider: localStorageProvider,
      }}
    >
      {children}
    </SWRConfig>
  );
}
