import { Outlet } from "@tanstack/react-router";
import PageNavBar from "../layout/PageNavBar";
import ResearchMenu from "./components/menu";

function Research() {
  return (
    <>
      <PageNavBar
        title="Research"
        primary="Research"
        showTradeNowButton={true}
      />

      <div className="mb-6 mx-4">
        <ResearchMenu />

        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Research;
