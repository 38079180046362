import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { Account } from '@/lib/services/class/account';
import accountOperations from './accountOperations';

interface AccountState {
  data: Account[];
  isLoading: boolean;
  isEditing: boolean;
  error: string | null;
}

const initialState: AccountState = {
  data: [],
  isLoading: false,
  isEditing: false,
  error: null,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AccountState>) => {
    builder
      .addCase(accountOperations.fetchAccounts.pending, (state: AccountState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        accountOperations.fetchAccounts.fulfilled,
        (state: AccountState, action: any) => {
          state.data = action.payload ? action.payload['result'] : [];
          state.isLoading = false;
        },
      )
      .addCase(
        accountOperations.fetchAccounts.rejected,
        (state: AccountState) => {
          state.isLoading = false;
        },
      )
  },
});

export const  accountReducer = accountSlice.reducer;