import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker';

interface Props {
  onChange : (value: DateValueType) => void,
  value: DateValueType,
  label?: string
}
function DateRange( { label, onChange, value }: Props ) {
  return (
    <div>
       {label && (
            <label
              className={"transition-all duration-300 block text-xs leading-tight px-1.5 pb-1.5"}
              >
              {label}
            </label>
          )}
      <Datepicker
        primaryColor={"teal"} 
        value={value}
        popoverDirection="down" 
        onChange={onChange}
        inputClassName="w-[300px]
        appearance-none px-3 py-3.5 bg-none rounded-md border border-stone-95  rounded-lg text-gray-700 leading-tight focus:outline-none focus:shadow-outline
"
      />


    </div>
  );
}

export default DateRange;
