import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import MessageModal from "./modal/MessageModal.tsx";
import Button from "@/lib/common/components/button/index.tsx";
import { onLogout } from "@/lib/library.ts";
import DataCache from "@/lib/DataCache.ts";
import Constant from "@/lib/constant.ts";
import Service from "@/lib/services/request.ts";

const promptBeforeIdle = 400000;

export default function SessionHandler() {
  const [remaining, setRemaining] = useState<number>(Constant.websiteTimeout);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onIdle = () => {
    setIsOpen(false);
    onLogout();
  };

  const onActive = () => {
    setIsOpen(false);
  };

  const onPrompt = () => {
    setIsOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: Constant.websiteTimeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
    const token = DataCache.getItem(Service.StoreToken);
    if(token){
      DataCache.setItem(Service.StoreToken, token, Constant.websiteTimeout);
    }
  };

  return (
    isOpen && (
      <MessageModal
      onClick={handleStillHere}
        title="Session Timeout"
        description={`Logging out in ${remaining} Seconds.`}
        actions={
          <div className="flex justify-center items-center">
            <Button onClick={handleStillHere} className="w-full">
              I'm available
            </Button>
          </div>
        }
      />
    )
  );
}
