import cancelButton from "@/assets/images/icons/cancel.svg";
import {TradeOrder} from "@/lib/services/class/tradeOrder";
import MarketInfoBox from "./MarketInfoBox";
import {formatDate,formatMoney,formatNum} from "@/lib/library";
import Button from "@/lib/common/components/button";
import {useState} from "react";
import Service from "@/lib/services/request";
import {toast} from "react-toastify";
import {ErrorProps} from "@/lib/types/error";
import {EventBus} from "@/lib/EventBus";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tradeOrder?: TradeOrder;
}
export default function OrderDetailsModal({tradeOrder,setIsOpen}: Props) {
  const [isLoading,setIsLoaded]=useState(false);
  const onCancel=async () => {
    setIsLoaded(true);

    try {
      await Service.post(`/cancelTradeOrder`,{
        orderId: tradeOrder?.id
      });
      toast("Your request to delete the trade order has been submitted",{
        type: "error",
      });

    } catch(error: unknown) {
      if(error) {
        toast((error as ErrorProps).message,{
          type: "error",
        });
      }
    }

    EventBus.getInstance().dispatch<string>('tradeOrder','reload');
    setIsOpen(false);

    setIsLoaded(false);
  };
  return (
    <>
      <div
        className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
        style={{zIndex: 100}}>
        <div className="my-10 flex h-full md:h-auto items-center justify-center  text-center ">
          <div
            className="
             bg-stone-50 rounded-[10px] flex-col justify-center items-start gap-6 inline-flex
            w-full 
            md:w-[611px] mx-auto  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
            <div className="w-full bg-stone-50 rounded-[10px] relative">
              <div
                className="cursor-pointer w-10 h-10 right-5 top-5 absolute bg-black bg-opacity-5 rounded"
                onClick={() => setIsOpen(false)}>
                <img src={cancelButton} />
              </div>

              <div className=" pt-8">
                <div className="pl-4 pb-6 text-start  text-stone-950 dark:text-[#f3f3f3] text-2xl font-semibold capitalize">
                  Trade Order Details
                </div>
                <div className="pb-12 divide-x divide-stone-950 divide-opacity-10 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 lg:gap-4 md:gap-3 gap-0">
                  <MarketInfoBox
                    title="Name"
                    value={
                      tradeOrder&&tradeOrder.name? tradeOrder.name:"--"
                    }
                  />
                  <MarketInfoBox
                    title="Instrument Type"
                    value={
                      tradeOrder&&tradeOrder.instrumentType
                        ? tradeOrder.instrumentType
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Fix Message"
                    value={
                      tradeOrder&&tradeOrder.fixMessage
                        ? tradeOrder.fixMessage
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Symbol"
                    value={
                      tradeOrder&&tradeOrder.securityName
                        ? tradeOrder.securityName
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Portfolio"
                    value={
                      tradeOrder&&tradeOrder.portfolioLabel
                        ? tradeOrder.portfolioLabel
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Order Type"
                    value={
                      tradeOrder&&tradeOrder.orderType
                        ? tradeOrder.orderType
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Price Type"
                    value={
                      tradeOrder&&tradeOrder.priceType
                        ? tradeOrder.priceType
                        :"--"
                    }
                  />

                  {
                    tradeOrder&&tradeOrder.priceType==='LIMIT'&&<MarketInfoBox
                      title="Limit Price"
                      value={
                        tradeOrder&&tradeOrder.limitPrice
                          ? formatNum(tradeOrder.limitPrice)
                          :"--"
                      }
                    />
                  }

                  <MarketInfoBox
                    title="Order Date"
                    value={
                      tradeOrder&&tradeOrder.orderDate
                        ? formatDate(tradeOrder.orderDate)
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Quantity"
                    value={
                      tradeOrder!=null
                        ? formatNum(tradeOrder.quantityRequested)
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Order Term"
                    value={
                      tradeOrder&&tradeOrder.orderTermLabel
                        ? tradeOrder.orderTermLabel
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Qty Filled/Pending"
                    value={
                      tradeOrder!=null
                        ? formatNum(tradeOrder.quantityFilled,0)+
                        "/"+
                        formatNum(tradeOrder.quantityPending,0)
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Order Status"
                    value={
                      tradeOrder&&tradeOrder.orderStatus
                        ? tradeOrder.orderStatus
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Fix Order Status"
                    value={
                      tradeOrder&&tradeOrder.fixOrderStatus
                        ? tradeOrder.fixOrderStatus
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Order Status"
                    value={
                      tradeOrder&&tradeOrder.orderValue
                        ? tradeOrder.orderValue
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Order Total"
                    value={
                      tradeOrder&&tradeOrder.orderTotal
                        ? formatMoney(tradeOrder.orderTotal)
                        :"--"
                    }
                  />

                  <MarketInfoBox
                    title="Order Base"
                    value={
                      tradeOrder&&tradeOrder.orderBase
                        ? tradeOrder.orderBase
                        :"--"
                    }
                  />
                </div>
                <div className="pt-3 m-4">
                  {tradeOrder?.orderStatus==="EXECUTING"&&(
                    <Button
                      className="w-full"
                      onClick={() => onCancel()}
                      isLoading={isLoading}
                      disabled={isLoading}>
                      Cancel Trade Order
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
