import PageNavBar from "../layout/PageNavBar";
import Button from "@/lib/common/components/button";
import { useCallback, useEffect, useState } from "react";
import { formatDate, formatNum, getSymbolImage } from "@/lib/library";
import React from "react";
import NoContent from "@/lib/common/components/table/NoContent";
import CustomPagination from "@/lib/common/components/table/Pagination";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  Spinner,
  TableRow,
  TableCell,
  SortDescriptor,
} from "@nextui-org/react";
import Constant from "@/lib/constant";
import TableCss from "@/lib/utils/table-css";
import SearchForm from "../layout/component/SearchForm";
import { watchlistColumns } from "@/lib/utils/tableColumns";
import SecurityInfo from "../order/components/SecurityInfo";
import WatchlistModal from "./components/watchlist/WatchllistModal";
import { Watchlist } from "@/lib/services/class/watchlist";
import Plus from "@/lib/common/icons/plus";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/lib/store";
import watchlistOperations from "@/lib/store/features/watchlist/watchlistOperations";
import watchlistSelector from "@/lib/store/features/watchlist/watchlistSelector";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { EventBus } from "@/lib/EventBus";

export default function WatchlistPage() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();

  const data = useSelector<RootState, Watchlist[]>(
    watchlistSelector.getWathclists
  );
  const isLoading = useSelector<RootState, boolean>(
    watchlistSelector.getIsLoading
  );

  useEffect(() => {
    EventBus.getInstance().register("watchlist", () => {
      dispatch(watchlistOperations.fetchWatchlists());
    });
  });
  useEffect(() => {
    dispatch(watchlistOperations.fetchWatchlists());
  }, [dispatch]);


  const [filterValue, setFilterValue] = useState("");
  const [page, setPage] = useState(1);
  const hasSearchFilter = Boolean(filterValue);
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "orderDate",
    direction: "descending",
  });


  const onSearchChange = React.useCallback((value: string | undefined) => {
    if (value) {
      setFilterValue(value);
      setPage(1);
    } else {
      setFilterValue("");
    }
  }, []);

  const filteredItems = React.useMemo(() => {
    const result = data != undefined ? data : [];
    let filteredUsers = [...result];

    if (hasSearchFilter) {
      filteredUsers = filteredUsers.filter(
        (user: Watchlist) =>
          user.symbol.toLowerCase().includes(filterValue.toLowerCase()) ||
          user.companyName.toLowerCase().includes(filterValue)
      );
    }

    return filteredUsers;
  }, [data, filterValue, hasSearchFilter]);
  ("");

  const pages = Math.ceil(filteredItems.length / Constant.RowsPerPage);

  const items = React.useMemo(() => {
    const start = (page - 1) * Constant.RowsPerPage;
    const end = start + Constant.RowsPerPage;

    return filteredItems.slice(start, end);
  }, [page, filteredItems]);

  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column as keyof Watchlist];
      const second = b[sortDescriptor.column as keyof Watchlist];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const renderCell = useCallback((order: Watchlist, columnKey: React.Key) => {
    const cellValue = order[columnKey as keyof Watchlist];

    switch (columnKey) {
      case "symbol":
        return (
          <SecurityInfo symbol={order.symbol}>
            <div className="justify-start items-center gap-2 flex">
              {order.symbol && (
                <LazyLoadImage
                  className="w-[25px] h-[25px] rounded-full"
                  src={getSymbolImage(order.symbol)}
                />
              )}

              <div>{cellValue}</div>
            </div>
          </SecurityInfo>
        );
      case "created_at":
        return formatDate(cellValue, "YYYY-MM-DD");
      case "trigger_price":
        return formatNum(cellValue);
      case "current_price":
        return formatNum(cellValue);
      case "actions":
        return (
          <>
            <Button
              onClick={async () => {
                await dispatch(watchlistOperations.deleteWatchlist(order.id));
                toast("Stock has been deleted", {
                  type: "error",
                });
              }}
              variant="primary-outline">
              Delete
            </Button>
          </>
        );
      default:
        return cellValue;
    }
  }, []);

  const loadingState = isLoading ? "loading" : "idle";

  const classNames = React.useMemo(() => TableCss, []);

  return (
    <>
      {isOpen && <WatchlistModal setIsOpen={setIsOpen} />}

      <PageNavBar
        title="Watchlist"
        primary="Watchlist"
        showTradeNowButton={true}
      />

      <div className=" mx-4 mb-6 bg-white rounded-lg border border-stone-950 border-opacity-10">
        <div className="p-8">
          <div className="mb-8 flex flex-col md:flex-row gap-6 justify-between items-center">
            <SearchForm
              placeholder="Search order no or symbol"
              onChange={(value) => {
                onSearchChange(value);
              }}
            />

            <Button className="w-full md:w-auto" onClick={() => setIsOpen(true)}>
              <Plus />
              Add New
            </Button>
          </div>
        </div>

        <div className="py-5 w-full">
          <Table
            isCompact
            classNames={classNames}
            sortDescriptor={sortDescriptor}
            onSortChange={setSortDescriptor}
            bottomContent={
              pages > 0 ? (
                <div className="flex w-full justify-center">
                  <CustomPagination
                    isCompact
                    showControls
                    showShadow
                    page={page}
                    total={pages}
                    onChange={(page: React.SetStateAction<number>) =>
                      setPage(page)
                    }
                  />
                </div>
              ) : null
            }>
            <TableHeader columns={watchlistColumns}>
              {(column) => (
                <TableColumn
                  key={column.uid}
                  align={column.uid === "actions" ? "center" : "start"}
                  allowsSorting={column.sortable}>
                  {column.name}
                </TableColumn>
              )}
            </TableHeader>
            <TableBody<Watchlist>
              isLoading={isLoading}
              emptyContent={<NoContent />}
              items={sortedItems}
              loadingContent={<Spinner />}
              loadingState={loadingState}>
              {(item: Watchlist) => {
                return (
                  <TableRow key={item.id}>
                    {(columnKey) => (
                      <TableCell>{renderCell(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                );
              }}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
