import numbro from "numbro";
import Service from "./services/request.ts";
import { parsePhoneNumber } from "libphonenumber-js";
import { router } from "@/routes/index.tsx";
import DataCache from "./DataCache.ts";
import * as momentTim from "moment-timezone";
import moment from "moment";


export const getSymbolImage = (symbol: string) => {
  return `https://www.meritrade.com/securityImage/${symbol}`;
};

export const getMarketTime = () => {
  const weekday = moment().format("dddd"); // Monday ... Sunday
  const isWeekend = weekday === "Sunday" || weekday === "Saturday";
  const todayDate = moment().format("Y-M-D");
  const startTradeTime = "9:30";
  const endTradingTime = "14:30";

  const StartingDate = moment(todayDate + " " + startTradeTime);
  const ClosingDate = moment(todayDate + " " + endTradingTime);

  if (!isWeekend && moment().isBetween(StartingDate, ClosingDate)) {
    return {
      isActive: false,
    };
  } else if (!isWeekend && moment().isBefore(StartingDate)) {
    return {
      isActive: true,
      date: parseInt(StartingDate.format("x")),
    };
  } else if (!isWeekend && moment().isAfter(ClosingDate)) {
    const time = moment(
      moment().add(weekday == "Friday" ? 3 : 1, "day").format("Y-M-D") + " " + startTradeTime
    );

    return {
      isActive: true,
      date: parseInt(time.format("x")),
    };
  } else {
    let time;
    if (weekday === "Sunday") {
      time = moment().add(1, "day").format("Y-M-D");
    } else if (weekday === "Saturday") {
      time = moment().add(2, "day").format("Y-M-D");
    }
    const StartingDate = moment(time + " " + startTradeTime);

    return {
      isActive: true,
      date: parseInt(StartingDate.format("x")),
    };
  }
};

export const getFormatPhone = (phone?: string | undefined) => {
  try {
    const oarse = parsePhoneNumber(phone ?? "");
    return {
      countryCallingCode: "+" + oarse.countryCallingCode,
      nationalNumber: oarse.nationalNumber,
    };
  } catch (error) {
    return {
      countryCallingCode: "+234",
      nationalNumber: phone,
    };
  }
};

export const capitalizeSentence = (str: string | null | undefined) => {
  if (str) {
    const words = str.split(" ");
    const res  = words.map((word) => { 
      return capitalizeFirstLetter(word); 
    }).join(" ");
    return res;
  }
  return "";
};

export const capitalizeFirstLetter = (str: string | null | undefined) => {
  if (str) {
    
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return "";
};

// export const formatDate = (value: unknown, format?: string) => {
//   if (value) {
//     return moment(value).format(format ?? "Do-MMM-YYYY");
//   }
//   return "";
// };

export const formatDate = (value: unknown, format?: string, type?: string) => {

  if (value) {
    if (type) {
      return momentTim
        .tz(String(value), type, 'Africa/Lagos')
        .format(format ?? "DD-MMM-YY");
    }
    return momentTim
      .tz(String(value), 'Africa/Lagos')
      .format(format ?? "DD-MMM-YY");
  }
  return "";
};

export const formatMoney = (number: number) => {
  return `₦${numbro(number).format({
    thousandSeparated: true,
    mantissa: 2,
  })}`;
};

export const isLogin = async () => {
  const token = await DataCache.getItem(Service.StoreToken);
  if (token) {
    return true;
  }
  return false;
};

export const formatNum = (number: unknown, format?: number) => {
  if (!number) return 0.0;

  return numbro(number).format({
    thousandSeparated: true,
    mantissa: format ?? 2,
  });
};

export const onLogout = () => {
  DataCache.clear();
  localStorage.setItem(Service.StoreToken, "");
  window.location.href = "/?redirect="+router.state.location.href;
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = (reader.result as string).replace(/^data:image\/\w+;base64,/, "");
      resolve(base64String);
    };
    reader.onerror = (error) => reject(error);
  });
};