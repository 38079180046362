import { useState } from "react";
import { DateValueType } from "react-tailwindcss-datepicker";
import useSWR from "swr";
import { formatDate } from "../library";

export default function useAssetAllocationSWR() {
  // Initialize date range with default values
  const [dateRange, setDateRange] = useState<DateValueType>(() => {
    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);

    return {
      startDate: lastMonth, // Use Date object here
      endDate: today, // Use Date object here
    };
  });

  // Handle date range changes
  const handleChangeDate = (e?: DateValueType) => {
    if (e?.startDate && e.endDate) {
      setDateRange(e);
    }
  };

  // Build the SWR key dynamically
  const swrKey = `portfolio/chart/more?start_date=${encodeURIComponent(
    formatDate(dateRange?.startDate, "YYYY-MM-DD")
  )}&end_date=${encodeURIComponent(
    formatDate(dateRange?.endDate, "YYYY-MM-DD")
  )}`;

  const { data, error, isLoading } = useSWR(swrKey);

  return {
    data,
    handleChangeDate,
    dateRange,
    setDateRange,
    error,
    isLoading,
  };
}


// import { useState } from "react";
// import { DateValueType } from "react-tailwindcss-datepicker";
// import useSWR from "swr";
// import { formatDate } from "../library";

// export default function useAssetAllocationSWR() {

//   const [dateRange, setDateRange] = useState<DateValueType>(() => {
//     const d = new Date();
//     return {
//       startDate: "2011-01-01",// moment().subtract(1, "months").toDate(),
//       endDate: d,
//     };
//   });

//   const handleChangeDate = (e?: DateValueType) => {
//     if (e && e?.startDate != null && e.endDate != null) {
//       setDateRange(e);
//     }
//   };

//   const { data, error, isLoading } = useSWR(
//     `portfolio/chart/more?start_date=${formatDate(
//       dateRange?.startDate,
//       "YYYY-MM-DD"
//     )}&end_date=${formatDate(dateRange?.endDate, "YYYY-MM-DD")}`
//   );
//   return {
//     data,
//     handleChangeDate,
//     dateRange,
//     setDateRange,
//     error,
//     isLoading,
//   };
// }
