import SuccessfulAction from "@/assets/images/successful_action.svg";
import cancelButton from "@/assets/images/icons/cancel.svg";
import { ReactNode } from "react";

interface Props {
  title: string;
  description: string;
  actions: ReactNode;
  onClick?: () => void;
}
export default function MessageModal({
  title,
  description,
  actions,
  onClick,
}: Props) {
  return (
    <>
      <div
        className="
     overflow-x-hidden overflow-y-auto fixed h-modal md:h-full top-0 left-0 right-0 md:inset-0 z-50 justify-center items-center
     inset-0  bg-black bg-opacity-25  animated fadeIn faster"
        style={{ zIndex: 100 }}>
        <div className="my-10 flex h-full md:h-auto items-center justify-center  text-center ">
          <div
            className="
             bg-stone-50 rounded-[10px] flex-col justify-center items-start gap-6 inline-flex
            w-full 
            md:w-[611px] mx-auto  border-[1px] border-gray-200 shadow hover:shadow-lg transition-all duration-150 ease-linear">
            <div className="w-full h-[410px] bg-stone-50 rounded-[10px] relative">
              {onClick && (
                <div
                  className="cursor-pointer w-10 h-10 right-5 top-5 absolute bg-black bg-opacity-5 rounded"
                  onClick={() => onClick()}>
                  <img src={cancelButton} />
                </div>
              )}
              <div className="py-10 bg-green-900 bg-opacity-10 flex justify-center items-center">
                <img src={SuccessfulAction} />
              </div>
              <div className="flex-col flex justify-center items-center gap-7 pt-8">
                <div className="text-center  text-stone-950 dark:text-[#f3f3f3] text-2xl font-semibold  capitalize">
                  {title}
                </div>
                <div dangerouslySetInnerHTML={{ __html: description }} className="text-center text-neutral-700 text-sm font-normal  leading-tight">
           
                </div>

                {actions}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
